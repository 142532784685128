import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot,  state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean>{
    //fix methods of getting the values of authenticated user
    const isAuthenticated = sessionStorage.getItem('isAuthenticated') ? sessionStorage.getItem('isAuthenticated').toLowerCase() == 'true' : false;
    if(!isAuthenticated){
      this.router.navigate(['/sign/sign-up']);
    }
    
    return isAuthenticated;
  }
}
