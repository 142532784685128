import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GetUserItemGQL, GetAllUserDetailPersonalDetailsGQL, UserCreateInput, CreateUserGQL, GetAllUserGQL, UpdateUserGQL, UpdatePersonalDetailGQL } from '../../../../generated/graphql';
import { NbToastrService } from '@nebular/theme';
import { pluck } from 'rxjs/operators';
import * as bcrypt from 'bcryptjs';
@Injectable({
  providedIn: 'root'
})
export class UserItemService {
  private LoggedUser: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private documents = {};
  constructor(private GetUserItemGQL: GetUserItemGQL,
    private CreateUserGQL: CreateUserGQL,
    private toast: NbToastrService,
    private GetAllUserGQL: GetAllUserGQL,
    private updateUser: UpdateUserGQL,
    private updatePersonalDetail: UpdatePersonalDetailGQL,
    private usersDetail: GetAllUserDetailPersonalDetailsGQL
  ) { }


  fetch(id: string): void {

    if (this.documents[id]) {

      this.documents[id].loading.next(true)

      this.GetUserItemGQL.fetch({ userId: id })
        .subscribe(result => {
          console.log("fetch-doc", result)
          if (result && result.data && result.data.user) {
            this.documents[id].loading.next(false);
            this.documents[id].user.next(result.data.user);

          }

        })
    }
  }

  get(id: string): BehaviorSubject<any> {
    if (!this.documents[id]) {
      this.documents[id] = {
        loading: new BehaviorSubject<Boolean>(true),
        user: new BehaviorSubject<any>({ "name": id }),
      }
      this.fetch(id);
    };

    return this.documents[id].user;
  }

  name(id: string): BehaviorSubject<any> {
    if (!this.documents[id]) {
      this.documents[id] = {
        loading: new BehaviorSubject<Boolean>(true),
        user: new BehaviorSubject<any>({ "name": id }),
      }
      this.fetch(id);
    };

    return this.documents[id].user.pipe(pluck('name'));
  }


  picture(id: string): BehaviorSubject<any> {

    return this.documents[id].user.pipe(pluck('picture'));
  }

  createdAt(id: string): BehaviorSubject<any> {

    return this.documents[id].user.pipe(pluck('createdAt'));
  }


  status(id: string): BehaviorSubject<Boolean> {
    return this.documents[id].loading
  }

  addUser(user: UserCreateInput): BehaviorSubject<any> {
    var isSaved: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    this.CreateUserGQL.mutate({ user: user }).subscribe(results => {
      if (results && results.data != null) {
        this.updatePersonalDetail.mutate({
          where: { id: results.data.createUser.detail.id },
          data: {
            user: {
              connect: {
                id: results.data.createUser.id
              }
            }
          }
        })
        .subscribe(result=>{
          if(result){
            // console.log("Created",result)
          
        }
      }
        )
        // return;
        sessionStorage.setItem('isAuthenticated', 'true');
        isSaved.next(true);
        var currentUser = {
          name: results.data.createUser.detail.name,
          lastName: results.data.createUser.detail.lastName,
          id: results.data.createUser.id,
          profilePic: results.data.createUser.picture
        }

        sessionStorage.setItem("User", JSON.stringify(currentUser));
        this.toast.show('User Created', '', { status: "success" });
        
      }
    },
      error => {
        console.log("Error HereL:", error);
        this.toast.show('Could not create user ', error, { status: "danger" });
        isSaved.next(false);
      }
    );
    return isSaved;
  }

  findUser(userIdentity: string, password: string): BehaviorSubject<boolean> {
    var userFound: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);

    this.GetAllUserGQL.fetch().subscribe(results => {
      if (results) {
        for (let user of results.data.users) {
          
          if (user.detail.email.toLowerCase() == userIdentity.toLowerCase() || user.detail.cellphone == userIdentity) {
            
            if (bcrypt.compareSync(password, user.password)) {
              var currentUser = {
                name: user.detail.name,
                lastName: user.detail.lastName,
                id: user.id,
                profilePic: user.picture
              }

              sessionStorage.setItem("User", JSON.stringify(currentUser))
              sessionStorage.setItem('isAuthenticated', 'true');
              userFound.next(true);
              this.LoggedUser.next(currentUser);
              break;
            }
          }
        }
      }
    });

    return userFound;
  }

  getUser() {
    console.log("USERNAme", this.LoggedUser.value)
    return this.LoggedUser;
  }

  update(name, stokvelId, userId, inviteId) {
    console.log("Whaaat", name, stokvelId, userId)
    return this.updateUser.mutate({
      data: {
        memberOf: {
          create: [{
            name: name,
            stokvel: { connect: { id: stokvelId } },
            invite: { connect: { id: inviteId } },
            joinedDate: new Date()
          }]
        },

      },
      where: { id: userId }
    })
  }

  usertoMember(name, stokvelId, userId) {
    console.log("What is my name and id", name, stokvelId, userId)
    return this.updateUser.mutate({
      data: {
        memberOf: {
          create: [{
            name: name,
            stokvel: { connect: { id: stokvelId } },
            joinedDate: new Date()
          }]
        },

      },
      where: { id: userId }
    })
  }

  checkUserMembership(userIdentity: string): BehaviorSubject<boolean>{
    var isFound:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    this.usersDetail.fetch().subscribe(results => {
      if(results){
        for(let user of results.data.personalDetails){
          if(userIdentity == user.cellphone || user.email.toLowerCase() == userIdentity.toLowerCase()){
            if(user && user.user != null){
              isFound.next(true);
              break;
            }
            
          }
        }
      }
    });

    return isFound
  }
}