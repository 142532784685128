import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NB_WINDOW } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import {  Router } from "@angular/router";
import { UserData } from '../../../@core/data/users';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserItemService } from "../../../models/user/services/user-item.service";


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  sessionStorage = sessionStorage;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];
  authMenu = [{ title: 'Log In' }, { title: 'Register' }]
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userItemService: UserItemService,
              private breakpointService: NbMediaBreakpointsService,
              private router: Router) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    if(sessionStorage.getItem('User')){
      this.user = JSON.parse(sessionStorage.getItem('User'));
      this.user.profilePic=this.user.profilePic.substring(0, this.user.profilePic.indexOf('profilePicture'))
                    +"file/"+
                    this.user.profilePic.substring(this.user.profilePic.indexOf('profilePicture'));
    }
  
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

      this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(
        title => {
          switch (title.toLowerCase()) {
            case 'log in':
              this.router.navigate(['/sign/sign-up']);
              break;
            case 'register':
              this.router.navigate(['/sign/register']);
              break;
            case 'profile':
              //
              break;
            case 'log out':
              this.logOut();
               break;      
            default:
              break;
          }
        } );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
logOut(){
  sessionStorage.removeItem('isAuthenticated');
  sessionStorage.removeItem('User');
  this.router.navigate(['/sign/sign-up']);
}
  
}
