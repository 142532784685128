import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: any,
  /** 
 * The `Long` scalar type represents non-fractional signed whole numeric values.
   * Long can represent values between -(2^63) and 2^63 - 1.
 **/
  Long: any,
};



export type Address = Node & {
   __typename?: 'Address',
  id: Scalars['ID'],
  line1: Scalars['String'],
  line2?: Maybe<Scalars['String']>,
  line3?: Maybe<Scalars['String']>,
  city: Scalars['String'],
  code: Scalars['String'],
};

/** A connection to a list of items. */
export type AddressConnection = {
   __typename?: 'AddressConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<AddressEdge>>,
  aggregate: AggregateAddress,
};

export type AddressCreateInput = {
  id?: Maybe<Scalars['ID']>,
  line1: Scalars['String'],
  line2?: Maybe<Scalars['String']>,
  line3?: Maybe<Scalars['String']>,
  city: Scalars['String'],
  code: Scalars['String'],
};

export type AddressCreateOneInput = {
  create?: Maybe<AddressCreateInput>,
  connect?: Maybe<AddressWhereUniqueInput>,
};

/** An edge in a connection. */
export type AddressEdge = {
   __typename?: 'AddressEdge',
  /** The item at the end of the edge. */
  node: Address,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum AddressOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  Line1Asc = 'line1_ASC',
  Line1Desc = 'line1_DESC',
  Line2Asc = 'line2_ASC',
  Line2Desc = 'line2_DESC',
  Line3Asc = 'line3_ASC',
  Line3Desc = 'line3_DESC',
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC'
}

export type AddressPreviousValues = {
   __typename?: 'AddressPreviousValues',
  id: Scalars['ID'],
  line1: Scalars['String'],
  line2?: Maybe<Scalars['String']>,
  line3?: Maybe<Scalars['String']>,
  city: Scalars['String'],
  code: Scalars['String'],
};

export type AddressSubscriptionPayload = {
   __typename?: 'AddressSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<Address>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<AddressPreviousValues>,
};

export type AddressSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<AddressSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<AddressSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<AddressSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<AddressWhereInput>,
};

export type AddressUpdateDataInput = {
  line1?: Maybe<Scalars['String']>,
  line2?: Maybe<Scalars['String']>,
  line3?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
};

export type AddressUpdateInput = {
  line1?: Maybe<Scalars['String']>,
  line2?: Maybe<Scalars['String']>,
  line3?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
};

export type AddressUpdateManyMutationInput = {
  line1?: Maybe<Scalars['String']>,
  line2?: Maybe<Scalars['String']>,
  line3?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
};

export type AddressUpdateOneInput = {
  create?: Maybe<AddressCreateInput>,
  connect?: Maybe<AddressWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<AddressUpdateDataInput>,
  upsert?: Maybe<AddressUpsertNestedInput>,
};

export type AddressUpsertNestedInput = {
  update: AddressUpdateDataInput,
  create: AddressCreateInput,
};

export type AddressWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<AddressWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<AddressWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<AddressWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  line1?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  line1_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  line1_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  line1_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  line1_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  line1_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  line1_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  line1_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  line1_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  line1_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  line1_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  line1_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  line1_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  line1_not_ends_with?: Maybe<Scalars['String']>,
  line2?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  line2_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  line2_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  line2_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  line2_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  line2_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  line2_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  line2_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  line2_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  line2_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  line2_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  line2_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  line2_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  line2_not_ends_with?: Maybe<Scalars['String']>,
  line3?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  line3_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  line3_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  line3_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  line3_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  line3_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  line3_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  line3_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  line3_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  line3_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  line3_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  line3_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  line3_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  line3_not_ends_with?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  city_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  city_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  city_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  city_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  city_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  city_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  city_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  city_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  city_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  city_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  city_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  city_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  city_not_ends_with?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  code_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  code_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  code_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  code_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  code_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  code_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  code_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  code_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  code_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  code_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  code_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  code_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  code_not_ends_with?: Maybe<Scalars['String']>,
};

export type AddressWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type AggregateAddress = {
   __typename?: 'AggregateAddress',
  count: Scalars['Int'],
};

export type AggregateBankAccount = {
   __typename?: 'AggregateBankAccount',
  count: Scalars['Int'],
};

export type AggregateConstitution = {
   __typename?: 'AggregateConstitution',
  count: Scalars['Int'],
};

export type AggregateContribution = {
   __typename?: 'AggregateContribution',
  count: Scalars['Int'],
};

export type AggregateEvent = {
   __typename?: 'AggregateEvent',
  count: Scalars['Int'],
};

export type AggregateInvite = {
   __typename?: 'AggregateInvite',
  count: Scalars['Int'],
};

export type AggregateMember = {
   __typename?: 'AggregateMember',
  count: Scalars['Int'],
};

export type AggregateMessage = {
   __typename?: 'AggregateMessage',
  count: Scalars['Int'],
};

export type AggregatePayment = {
   __typename?: 'AggregatePayment',
  count: Scalars['Int'],
};

export type AggregatePersonalDetail = {
   __typename?: 'AggregatePersonalDetail',
  count: Scalars['Int'],
};

export type AggregatePersonalDetailIndex = {
   __typename?: 'AggregatePersonalDetailIndex',
  count: Scalars['Int'],
};

export type AggregateStokvel = {
   __typename?: 'AggregateStokvel',
  count: Scalars['Int'],
};

export type AggregateUser = {
   __typename?: 'AggregateUser',
  count: Scalars['Int'],
};

export type BankAccount = Node & {
   __typename?: 'BankAccount',
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  accountNo: Scalars['String'],
  branchCode: Scalars['String'],
  stokvel?: Maybe<Stokvel>,
  status?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

/** A connection to a list of items. */
export type BankAccountConnection = {
   __typename?: 'BankAccountConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<BankAccountEdge>>,
  aggregate: AggregateBankAccount,
};

export type BankAccountCreateInput = {
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  accountNo: Scalars['String'],
  branchCode: Scalars['String'],
  status?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
  stokvel?: Maybe<StokvelCreateOneWithoutBankAccountInput>,
};

export type BankAccountCreateManyWithoutStokvelInput = {
  create?: Maybe<Array<BankAccountCreateWithoutStokvelInput>>,
  connect?: Maybe<Array<BankAccountWhereUniqueInput>>,
};

export type BankAccountCreateWithoutStokvelInput = {
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  accountNo: Scalars['String'],
  branchCode: Scalars['String'],
  status?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
};

/** An edge in a connection. */
export type BankAccountEdge = {
   __typename?: 'BankAccountEdge',
  /** The item at the end of the edge. */
  node: BankAccount,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum BankAccountOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  AccountNoAsc = 'accountNo_ASC',
  AccountNoDesc = 'accountNo_DESC',
  BranchCodeAsc = 'branchCode_ASC',
  BranchCodeDesc = 'branchCode_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  ReasonAsc = 'reason_ASC',
  ReasonDesc = 'reason_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type BankAccountPreviousValues = {
   __typename?: 'BankAccountPreviousValues',
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  accountNo: Scalars['String'],
  branchCode: Scalars['String'],
  status?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type BankAccountScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BankAccountScalarWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BankAccountScalarWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BankAccountScalarWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  accountNo?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  accountNo_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  accountNo_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  accountNo_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  accountNo_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  accountNo_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  accountNo_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  accountNo_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  accountNo_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  accountNo_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  accountNo_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  accountNo_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  accountNo_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  accountNo_not_ends_with?: Maybe<Scalars['String']>,
  branchCode?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  branchCode_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  branchCode_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  branchCode_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  branchCode_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  branchCode_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  branchCode_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  branchCode_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  branchCode_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  branchCode_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  branchCode_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  branchCode_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  branchCode_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  branchCode_not_ends_with?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['Boolean']>,
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  reason_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  reason_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  reason_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  reason_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  reason_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  reason_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  reason_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  reason_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  reason_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  reason_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  reason_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  reason_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  reason_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type BankAccountSubscriptionPayload = {
   __typename?: 'BankAccountSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<BankAccount>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<BankAccountPreviousValues>,
};

export type BankAccountSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BankAccountSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BankAccountSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BankAccountSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<BankAccountWhereInput>,
};

export type BankAccountUpdateInput = {
  name?: Maybe<Scalars['String']>,
  accountNo?: Maybe<Scalars['String']>,
  branchCode?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
  stokvel?: Maybe<StokvelUpdateOneWithoutBankAccountInput>,
};

export type BankAccountUpdateManyDataInput = {
  name?: Maybe<Scalars['String']>,
  accountNo?: Maybe<Scalars['String']>,
  branchCode?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
};

export type BankAccountUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>,
  accountNo?: Maybe<Scalars['String']>,
  branchCode?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
};

export type BankAccountUpdateManyWithoutStokvelInput = {
  create?: Maybe<Array<BankAccountCreateWithoutStokvelInput>>,
  connect?: Maybe<Array<BankAccountWhereUniqueInput>>,
  set?: Maybe<Array<BankAccountWhereUniqueInput>>,
  disconnect?: Maybe<Array<BankAccountWhereUniqueInput>>,
  delete?: Maybe<Array<BankAccountWhereUniqueInput>>,
  update?: Maybe<Array<BankAccountUpdateWithWhereUniqueWithoutStokvelInput>>,
  updateMany?: Maybe<Array<BankAccountUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<BankAccountScalarWhereInput>>,
  upsert?: Maybe<Array<BankAccountUpsertWithWhereUniqueWithoutStokvelInput>>,
};

export type BankAccountUpdateManyWithWhereNestedInput = {
  where: BankAccountScalarWhereInput,
  data: BankAccountUpdateManyDataInput,
};

export type BankAccountUpdateWithoutStokvelDataInput = {
  name?: Maybe<Scalars['String']>,
  accountNo?: Maybe<Scalars['String']>,
  branchCode?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
};

export type BankAccountUpdateWithWhereUniqueWithoutStokvelInput = {
  where: BankAccountWhereUniqueInput,
  data: BankAccountUpdateWithoutStokvelDataInput,
};

export type BankAccountUpsertWithWhereUniqueWithoutStokvelInput = {
  where: BankAccountWhereUniqueInput,
  update: BankAccountUpdateWithoutStokvelDataInput,
  create: BankAccountCreateWithoutStokvelInput,
};

export type BankAccountWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BankAccountWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BankAccountWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BankAccountWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  accountNo?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  accountNo_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  accountNo_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  accountNo_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  accountNo_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  accountNo_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  accountNo_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  accountNo_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  accountNo_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  accountNo_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  accountNo_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  accountNo_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  accountNo_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  accountNo_not_ends_with?: Maybe<Scalars['String']>,
  branchCode?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  branchCode_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  branchCode_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  branchCode_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  branchCode_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  branchCode_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  branchCode_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  branchCode_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  branchCode_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  branchCode_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  branchCode_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  branchCode_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  branchCode_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  branchCode_not_ends_with?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['Boolean']>,
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  reason_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  reason_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  reason_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  reason_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  reason_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  reason_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  reason_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  reason_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  reason_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  reason_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  reason_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  reason_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  reason_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  stokvel?: Maybe<StokvelWhereInput>,
};

export type BankAccountWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type BatchPayload = {
   __typename?: 'BatchPayload',
  /** The number of nodes that have been affected by the Batch operation. */
  count: Scalars['Long'],
};

export type Constitution = Node & {
   __typename?: 'Constitution',
  id: Scalars['ID'],
  maximumNumberOfMembers: Scalars['Int'],
  stokvelType: Scalars['String'],
  secondaryStokvelType?: Maybe<Scalars['String']>,
  startingAge: Scalars['Int'],
  isPublic: Scalars['Boolean'],
  proofOfRegistration: Scalars['String'],
  effectiveDate: Scalars['DateTime'],
  venue: Scalars['String'],
  meetingFrequency: Scalars['String'],
  feeForNotAttending: Scalars['Float'],
  feeForNotWearingUniform: Scalars['Float'],
  joiningFee: Scalars['Float'],
  contribtutionAmount: Scalars['Float'],
  contributionFrequency: Scalars['String'],
  mainMemberPayoutAmount: Scalars['Float'],
  spousePayoutAmount: Scalars['Float'],
  childrenPayoutAmount: Scalars['Float'],
  dependentsPayoutAmount: Scalars['Float'],
  endDateOnCommitee: Scalars['DateTime'],
  stokvel?: Maybe<Stokvel>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

/** A connection to a list of items. */
export type ConstitutionConnection = {
   __typename?: 'ConstitutionConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<ConstitutionEdge>>,
  aggregate: AggregateConstitution,
};

export type ConstitutionCreateInput = {
  id?: Maybe<Scalars['ID']>,
  maximumNumberOfMembers: Scalars['Int'],
  stokvelType: Scalars['String'],
  secondaryStokvelType?: Maybe<Scalars['String']>,
  startingAge: Scalars['Int'],
  isPublic: Scalars['Boolean'],
  proofOfRegistration: Scalars['String'],
  effectiveDate: Scalars['DateTime'],
  venue: Scalars['String'],
  meetingFrequency: Scalars['String'],
  feeForNotAttending: Scalars['Float'],
  feeForNotWearingUniform: Scalars['Float'],
  joiningFee: Scalars['Float'],
  contribtutionAmount: Scalars['Float'],
  contributionFrequency: Scalars['String'],
  mainMemberPayoutAmount: Scalars['Float'],
  spousePayoutAmount: Scalars['Float'],
  childrenPayoutAmount: Scalars['Float'],
  dependentsPayoutAmount: Scalars['Float'],
  endDateOnCommitee: Scalars['DateTime'],
  stokvel?: Maybe<StokvelCreateOneWithoutConstitutionInput>,
};

export type ConstitutionCreateOneWithoutStokvelInput = {
  create?: Maybe<ConstitutionCreateWithoutStokvelInput>,
  connect?: Maybe<ConstitutionWhereUniqueInput>,
};

export type ConstitutionCreateWithoutStokvelInput = {
  id?: Maybe<Scalars['ID']>,
  maximumNumberOfMembers: Scalars['Int'],
  stokvelType: Scalars['String'],
  secondaryStokvelType?: Maybe<Scalars['String']>,
  startingAge: Scalars['Int'],
  isPublic: Scalars['Boolean'],
  proofOfRegistration: Scalars['String'],
  effectiveDate: Scalars['DateTime'],
  venue: Scalars['String'],
  meetingFrequency: Scalars['String'],
  feeForNotAttending: Scalars['Float'],
  feeForNotWearingUniform: Scalars['Float'],
  joiningFee: Scalars['Float'],
  contribtutionAmount: Scalars['Float'],
  contributionFrequency: Scalars['String'],
  mainMemberPayoutAmount: Scalars['Float'],
  spousePayoutAmount: Scalars['Float'],
  childrenPayoutAmount: Scalars['Float'],
  dependentsPayoutAmount: Scalars['Float'],
  endDateOnCommitee: Scalars['DateTime'],
};

/** An edge in a connection. */
export type ConstitutionEdge = {
   __typename?: 'ConstitutionEdge',
  /** The item at the end of the edge. */
  node: Constitution,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum ConstitutionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MaximumNumberOfMembersAsc = 'maximumNumberOfMembers_ASC',
  MaximumNumberOfMembersDesc = 'maximumNumberOfMembers_DESC',
  StokvelTypeAsc = 'stokvelType_ASC',
  StokvelTypeDesc = 'stokvelType_DESC',
  SecondaryStokvelTypeAsc = 'secondaryStokvelType_ASC',
  SecondaryStokvelTypeDesc = 'secondaryStokvelType_DESC',
  StartingAgeAsc = 'startingAge_ASC',
  StartingAgeDesc = 'startingAge_DESC',
  IsPublicAsc = 'isPublic_ASC',
  IsPublicDesc = 'isPublic_DESC',
  ProofOfRegistrationAsc = 'proofOfRegistration_ASC',
  ProofOfRegistrationDesc = 'proofOfRegistration_DESC',
  EffectiveDateAsc = 'effectiveDate_ASC',
  EffectiveDateDesc = 'effectiveDate_DESC',
  VenueAsc = 'venue_ASC',
  VenueDesc = 'venue_DESC',
  MeetingFrequencyAsc = 'meetingFrequency_ASC',
  MeetingFrequencyDesc = 'meetingFrequency_DESC',
  FeeForNotAttendingAsc = 'feeForNotAttending_ASC',
  FeeForNotAttendingDesc = 'feeForNotAttending_DESC',
  FeeForNotWearingUniformAsc = 'feeForNotWearingUniform_ASC',
  FeeForNotWearingUniformDesc = 'feeForNotWearingUniform_DESC',
  JoiningFeeAsc = 'joiningFee_ASC',
  JoiningFeeDesc = 'joiningFee_DESC',
  ContribtutionAmountAsc = 'contribtutionAmount_ASC',
  ContribtutionAmountDesc = 'contribtutionAmount_DESC',
  ContributionFrequencyAsc = 'contributionFrequency_ASC',
  ContributionFrequencyDesc = 'contributionFrequency_DESC',
  MainMemberPayoutAmountAsc = 'mainMemberPayoutAmount_ASC',
  MainMemberPayoutAmountDesc = 'mainMemberPayoutAmount_DESC',
  SpousePayoutAmountAsc = 'spousePayoutAmount_ASC',
  SpousePayoutAmountDesc = 'spousePayoutAmount_DESC',
  ChildrenPayoutAmountAsc = 'childrenPayoutAmount_ASC',
  ChildrenPayoutAmountDesc = 'childrenPayoutAmount_DESC',
  DependentsPayoutAmountAsc = 'dependentsPayoutAmount_ASC',
  DependentsPayoutAmountDesc = 'dependentsPayoutAmount_DESC',
  EndDateOnCommiteeAsc = 'endDateOnCommitee_ASC',
  EndDateOnCommiteeDesc = 'endDateOnCommitee_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ConstitutionPreviousValues = {
   __typename?: 'ConstitutionPreviousValues',
  id: Scalars['ID'],
  maximumNumberOfMembers: Scalars['Int'],
  stokvelType: Scalars['String'],
  secondaryStokvelType?: Maybe<Scalars['String']>,
  startingAge: Scalars['Int'],
  isPublic: Scalars['Boolean'],
  proofOfRegistration: Scalars['String'],
  effectiveDate: Scalars['DateTime'],
  venue: Scalars['String'],
  meetingFrequency: Scalars['String'],
  feeForNotAttending: Scalars['Float'],
  feeForNotWearingUniform: Scalars['Float'],
  joiningFee: Scalars['Float'],
  contribtutionAmount: Scalars['Float'],
  contributionFrequency: Scalars['String'],
  mainMemberPayoutAmount: Scalars['Float'],
  spousePayoutAmount: Scalars['Float'],
  childrenPayoutAmount: Scalars['Float'],
  dependentsPayoutAmount: Scalars['Float'],
  endDateOnCommitee: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type ConstitutionSubscriptionPayload = {
   __typename?: 'ConstitutionSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<Constitution>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<ConstitutionPreviousValues>,
};

export type ConstitutionSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ConstitutionSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ConstitutionSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ConstitutionSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<ConstitutionWhereInput>,
};

export type ConstitutionUpdateInput = {
  maximumNumberOfMembers?: Maybe<Scalars['Int']>,
  stokvelType?: Maybe<Scalars['String']>,
  secondaryStokvelType?: Maybe<Scalars['String']>,
  startingAge?: Maybe<Scalars['Int']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  proofOfRegistration?: Maybe<Scalars['String']>,
  effectiveDate?: Maybe<Scalars['DateTime']>,
  venue?: Maybe<Scalars['String']>,
  meetingFrequency?: Maybe<Scalars['String']>,
  feeForNotAttending?: Maybe<Scalars['Float']>,
  feeForNotWearingUniform?: Maybe<Scalars['Float']>,
  joiningFee?: Maybe<Scalars['Float']>,
  contribtutionAmount?: Maybe<Scalars['Float']>,
  contributionFrequency?: Maybe<Scalars['String']>,
  mainMemberPayoutAmount?: Maybe<Scalars['Float']>,
  spousePayoutAmount?: Maybe<Scalars['Float']>,
  childrenPayoutAmount?: Maybe<Scalars['Float']>,
  dependentsPayoutAmount?: Maybe<Scalars['Float']>,
  endDateOnCommitee?: Maybe<Scalars['DateTime']>,
  stokvel?: Maybe<StokvelUpdateOneWithoutConstitutionInput>,
};

export type ConstitutionUpdateManyMutationInput = {
  maximumNumberOfMembers?: Maybe<Scalars['Int']>,
  stokvelType?: Maybe<Scalars['String']>,
  secondaryStokvelType?: Maybe<Scalars['String']>,
  startingAge?: Maybe<Scalars['Int']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  proofOfRegistration?: Maybe<Scalars['String']>,
  effectiveDate?: Maybe<Scalars['DateTime']>,
  venue?: Maybe<Scalars['String']>,
  meetingFrequency?: Maybe<Scalars['String']>,
  feeForNotAttending?: Maybe<Scalars['Float']>,
  feeForNotWearingUniform?: Maybe<Scalars['Float']>,
  joiningFee?: Maybe<Scalars['Float']>,
  contribtutionAmount?: Maybe<Scalars['Float']>,
  contributionFrequency?: Maybe<Scalars['String']>,
  mainMemberPayoutAmount?: Maybe<Scalars['Float']>,
  spousePayoutAmount?: Maybe<Scalars['Float']>,
  childrenPayoutAmount?: Maybe<Scalars['Float']>,
  dependentsPayoutAmount?: Maybe<Scalars['Float']>,
  endDateOnCommitee?: Maybe<Scalars['DateTime']>,
};

export type ConstitutionUpdateOneWithoutStokvelInput = {
  create?: Maybe<ConstitutionCreateWithoutStokvelInput>,
  connect?: Maybe<ConstitutionWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<ConstitutionUpdateWithoutStokvelDataInput>,
  upsert?: Maybe<ConstitutionUpsertWithoutStokvelInput>,
};

export type ConstitutionUpdateWithoutStokvelDataInput = {
  maximumNumberOfMembers?: Maybe<Scalars['Int']>,
  stokvelType?: Maybe<Scalars['String']>,
  secondaryStokvelType?: Maybe<Scalars['String']>,
  startingAge?: Maybe<Scalars['Int']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  proofOfRegistration?: Maybe<Scalars['String']>,
  effectiveDate?: Maybe<Scalars['DateTime']>,
  venue?: Maybe<Scalars['String']>,
  meetingFrequency?: Maybe<Scalars['String']>,
  feeForNotAttending?: Maybe<Scalars['Float']>,
  feeForNotWearingUniform?: Maybe<Scalars['Float']>,
  joiningFee?: Maybe<Scalars['Float']>,
  contribtutionAmount?: Maybe<Scalars['Float']>,
  contributionFrequency?: Maybe<Scalars['String']>,
  mainMemberPayoutAmount?: Maybe<Scalars['Float']>,
  spousePayoutAmount?: Maybe<Scalars['Float']>,
  childrenPayoutAmount?: Maybe<Scalars['Float']>,
  dependentsPayoutAmount?: Maybe<Scalars['Float']>,
  endDateOnCommitee?: Maybe<Scalars['DateTime']>,
};

export type ConstitutionUpsertWithoutStokvelInput = {
  update: ConstitutionUpdateWithoutStokvelDataInput,
  create: ConstitutionCreateWithoutStokvelInput,
};

export type ConstitutionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ConstitutionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ConstitutionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ConstitutionWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  maximumNumberOfMembers?: Maybe<Scalars['Int']>,
  /** All values that are not equal to given value. */
  maximumNumberOfMembers_not?: Maybe<Scalars['Int']>,
  /** All values that are contained in given list. */
  maximumNumberOfMembers_in?: Maybe<Array<Scalars['Int']>>,
  /** All values that are not contained in given list. */
  maximumNumberOfMembers_not_in?: Maybe<Array<Scalars['Int']>>,
  /** All values less than the given value. */
  maximumNumberOfMembers_lt?: Maybe<Scalars['Int']>,
  /** All values less than or equal the given value. */
  maximumNumberOfMembers_lte?: Maybe<Scalars['Int']>,
  /** All values greater than the given value. */
  maximumNumberOfMembers_gt?: Maybe<Scalars['Int']>,
  /** All values greater than or equal the given value. */
  maximumNumberOfMembers_gte?: Maybe<Scalars['Int']>,
  stokvelType?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  stokvelType_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  stokvelType_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  stokvelType_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  stokvelType_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  stokvelType_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  stokvelType_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  stokvelType_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  stokvelType_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  stokvelType_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  stokvelType_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  stokvelType_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  stokvelType_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  stokvelType_not_ends_with?: Maybe<Scalars['String']>,
  secondaryStokvelType?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  secondaryStokvelType_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  secondaryStokvelType_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  secondaryStokvelType_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  secondaryStokvelType_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  secondaryStokvelType_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  secondaryStokvelType_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  secondaryStokvelType_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  secondaryStokvelType_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  secondaryStokvelType_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  secondaryStokvelType_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  secondaryStokvelType_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  secondaryStokvelType_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  secondaryStokvelType_not_ends_with?: Maybe<Scalars['String']>,
  startingAge?: Maybe<Scalars['Int']>,
  /** All values that are not equal to given value. */
  startingAge_not?: Maybe<Scalars['Int']>,
  /** All values that are contained in given list. */
  startingAge_in?: Maybe<Array<Scalars['Int']>>,
  /** All values that are not contained in given list. */
  startingAge_not_in?: Maybe<Array<Scalars['Int']>>,
  /** All values less than the given value. */
  startingAge_lt?: Maybe<Scalars['Int']>,
  /** All values less than or equal the given value. */
  startingAge_lte?: Maybe<Scalars['Int']>,
  /** All values greater than the given value. */
  startingAge_gt?: Maybe<Scalars['Int']>,
  /** All values greater than or equal the given value. */
  startingAge_gte?: Maybe<Scalars['Int']>,
  isPublic?: Maybe<Scalars['Boolean']>,
  /** All values that are not equal to given value. */
  isPublic_not?: Maybe<Scalars['Boolean']>,
  proofOfRegistration?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  proofOfRegistration_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  proofOfRegistration_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  proofOfRegistration_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  proofOfRegistration_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  proofOfRegistration_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  proofOfRegistration_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  proofOfRegistration_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  proofOfRegistration_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  proofOfRegistration_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  proofOfRegistration_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  proofOfRegistration_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  proofOfRegistration_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  proofOfRegistration_not_ends_with?: Maybe<Scalars['String']>,
  effectiveDate?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  effectiveDate_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  effectiveDate_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  effectiveDate_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  effectiveDate_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  effectiveDate_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  effectiveDate_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  effectiveDate_gte?: Maybe<Scalars['DateTime']>,
  venue?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  venue_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  venue_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  venue_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  venue_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  venue_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  venue_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  venue_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  venue_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  venue_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  venue_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  venue_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  venue_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  venue_not_ends_with?: Maybe<Scalars['String']>,
  meetingFrequency?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  meetingFrequency_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  meetingFrequency_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  meetingFrequency_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  meetingFrequency_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  meetingFrequency_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  meetingFrequency_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  meetingFrequency_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  meetingFrequency_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  meetingFrequency_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  meetingFrequency_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  meetingFrequency_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  meetingFrequency_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  meetingFrequency_not_ends_with?: Maybe<Scalars['String']>,
  feeForNotAttending?: Maybe<Scalars['Float']>,
  /** All values that are not equal to given value. */
  feeForNotAttending_not?: Maybe<Scalars['Float']>,
  /** All values that are contained in given list. */
  feeForNotAttending_in?: Maybe<Array<Scalars['Float']>>,
  /** All values that are not contained in given list. */
  feeForNotAttending_not_in?: Maybe<Array<Scalars['Float']>>,
  /** All values less than the given value. */
  feeForNotAttending_lt?: Maybe<Scalars['Float']>,
  /** All values less than or equal the given value. */
  feeForNotAttending_lte?: Maybe<Scalars['Float']>,
  /** All values greater than the given value. */
  feeForNotAttending_gt?: Maybe<Scalars['Float']>,
  /** All values greater than or equal the given value. */
  feeForNotAttending_gte?: Maybe<Scalars['Float']>,
  feeForNotWearingUniform?: Maybe<Scalars['Float']>,
  /** All values that are not equal to given value. */
  feeForNotWearingUniform_not?: Maybe<Scalars['Float']>,
  /** All values that are contained in given list. */
  feeForNotWearingUniform_in?: Maybe<Array<Scalars['Float']>>,
  /** All values that are not contained in given list. */
  feeForNotWearingUniform_not_in?: Maybe<Array<Scalars['Float']>>,
  /** All values less than the given value. */
  feeForNotWearingUniform_lt?: Maybe<Scalars['Float']>,
  /** All values less than or equal the given value. */
  feeForNotWearingUniform_lte?: Maybe<Scalars['Float']>,
  /** All values greater than the given value. */
  feeForNotWearingUniform_gt?: Maybe<Scalars['Float']>,
  /** All values greater than or equal the given value. */
  feeForNotWearingUniform_gte?: Maybe<Scalars['Float']>,
  joiningFee?: Maybe<Scalars['Float']>,
  /** All values that are not equal to given value. */
  joiningFee_not?: Maybe<Scalars['Float']>,
  /** All values that are contained in given list. */
  joiningFee_in?: Maybe<Array<Scalars['Float']>>,
  /** All values that are not contained in given list. */
  joiningFee_not_in?: Maybe<Array<Scalars['Float']>>,
  /** All values less than the given value. */
  joiningFee_lt?: Maybe<Scalars['Float']>,
  /** All values less than or equal the given value. */
  joiningFee_lte?: Maybe<Scalars['Float']>,
  /** All values greater than the given value. */
  joiningFee_gt?: Maybe<Scalars['Float']>,
  /** All values greater than or equal the given value. */
  joiningFee_gte?: Maybe<Scalars['Float']>,
  contribtutionAmount?: Maybe<Scalars['Float']>,
  /** All values that are not equal to given value. */
  contribtutionAmount_not?: Maybe<Scalars['Float']>,
  /** All values that are contained in given list. */
  contribtutionAmount_in?: Maybe<Array<Scalars['Float']>>,
  /** All values that are not contained in given list. */
  contribtutionAmount_not_in?: Maybe<Array<Scalars['Float']>>,
  /** All values less than the given value. */
  contribtutionAmount_lt?: Maybe<Scalars['Float']>,
  /** All values less than or equal the given value. */
  contribtutionAmount_lte?: Maybe<Scalars['Float']>,
  /** All values greater than the given value. */
  contribtutionAmount_gt?: Maybe<Scalars['Float']>,
  /** All values greater than or equal the given value. */
  contribtutionAmount_gte?: Maybe<Scalars['Float']>,
  contributionFrequency?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  contributionFrequency_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  contributionFrequency_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  contributionFrequency_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  contributionFrequency_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  contributionFrequency_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  contributionFrequency_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  contributionFrequency_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  contributionFrequency_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  contributionFrequency_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  contributionFrequency_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  contributionFrequency_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  contributionFrequency_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  contributionFrequency_not_ends_with?: Maybe<Scalars['String']>,
  mainMemberPayoutAmount?: Maybe<Scalars['Float']>,
  /** All values that are not equal to given value. */
  mainMemberPayoutAmount_not?: Maybe<Scalars['Float']>,
  /** All values that are contained in given list. */
  mainMemberPayoutAmount_in?: Maybe<Array<Scalars['Float']>>,
  /** All values that are not contained in given list. */
  mainMemberPayoutAmount_not_in?: Maybe<Array<Scalars['Float']>>,
  /** All values less than the given value. */
  mainMemberPayoutAmount_lt?: Maybe<Scalars['Float']>,
  /** All values less than or equal the given value. */
  mainMemberPayoutAmount_lte?: Maybe<Scalars['Float']>,
  /** All values greater than the given value. */
  mainMemberPayoutAmount_gt?: Maybe<Scalars['Float']>,
  /** All values greater than or equal the given value. */
  mainMemberPayoutAmount_gte?: Maybe<Scalars['Float']>,
  spousePayoutAmount?: Maybe<Scalars['Float']>,
  /** All values that are not equal to given value. */
  spousePayoutAmount_not?: Maybe<Scalars['Float']>,
  /** All values that are contained in given list. */
  spousePayoutAmount_in?: Maybe<Array<Scalars['Float']>>,
  /** All values that are not contained in given list. */
  spousePayoutAmount_not_in?: Maybe<Array<Scalars['Float']>>,
  /** All values less than the given value. */
  spousePayoutAmount_lt?: Maybe<Scalars['Float']>,
  /** All values less than or equal the given value. */
  spousePayoutAmount_lte?: Maybe<Scalars['Float']>,
  /** All values greater than the given value. */
  spousePayoutAmount_gt?: Maybe<Scalars['Float']>,
  /** All values greater than or equal the given value. */
  spousePayoutAmount_gte?: Maybe<Scalars['Float']>,
  childrenPayoutAmount?: Maybe<Scalars['Float']>,
  /** All values that are not equal to given value. */
  childrenPayoutAmount_not?: Maybe<Scalars['Float']>,
  /** All values that are contained in given list. */
  childrenPayoutAmount_in?: Maybe<Array<Scalars['Float']>>,
  /** All values that are not contained in given list. */
  childrenPayoutAmount_not_in?: Maybe<Array<Scalars['Float']>>,
  /** All values less than the given value. */
  childrenPayoutAmount_lt?: Maybe<Scalars['Float']>,
  /** All values less than or equal the given value. */
  childrenPayoutAmount_lte?: Maybe<Scalars['Float']>,
  /** All values greater than the given value. */
  childrenPayoutAmount_gt?: Maybe<Scalars['Float']>,
  /** All values greater than or equal the given value. */
  childrenPayoutAmount_gte?: Maybe<Scalars['Float']>,
  dependentsPayoutAmount?: Maybe<Scalars['Float']>,
  /** All values that are not equal to given value. */
  dependentsPayoutAmount_not?: Maybe<Scalars['Float']>,
  /** All values that are contained in given list. */
  dependentsPayoutAmount_in?: Maybe<Array<Scalars['Float']>>,
  /** All values that are not contained in given list. */
  dependentsPayoutAmount_not_in?: Maybe<Array<Scalars['Float']>>,
  /** All values less than the given value. */
  dependentsPayoutAmount_lt?: Maybe<Scalars['Float']>,
  /** All values less than or equal the given value. */
  dependentsPayoutAmount_lte?: Maybe<Scalars['Float']>,
  /** All values greater than the given value. */
  dependentsPayoutAmount_gt?: Maybe<Scalars['Float']>,
  /** All values greater than or equal the given value. */
  dependentsPayoutAmount_gte?: Maybe<Scalars['Float']>,
  endDateOnCommitee?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  endDateOnCommitee_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  endDateOnCommitee_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  endDateOnCommitee_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  endDateOnCommitee_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  endDateOnCommitee_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  endDateOnCommitee_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  endDateOnCommitee_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  stokvel?: Maybe<StokvelWhereInput>,
};

export type ConstitutionWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type Contribution = Node & {
   __typename?: 'Contribution',
  id: Scalars['ID'],
  amount: Scalars['Float'],
  name?: Maybe<Scalars['String']>,
  transactionDate: Scalars['DateTime'],
  proofOfPayment: Scalars['String'],
  member: Member,
  stokvel: Stokvel,
  cancelled: Scalars['Boolean'],
  reason?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

/** A connection to a list of items. */
export type ContributionConnection = {
   __typename?: 'ContributionConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<ContributionEdge>>,
  aggregate: AggregateContribution,
};

export type ContributionCreateInput = {
  id?: Maybe<Scalars['ID']>,
  amount: Scalars['Float'],
  name?: Maybe<Scalars['String']>,
  transactionDate: Scalars['DateTime'],
  proofOfPayment: Scalars['String'],
  cancelled: Scalars['Boolean'],
  reason?: Maybe<Scalars['String']>,
  member: MemberCreateOneInput,
  stokvel: StokvelCreateOneWithoutContributionInput,
};

export type ContributionCreateManyWithoutStokvelInput = {
  create?: Maybe<Array<ContributionCreateWithoutStokvelInput>>,
  connect?: Maybe<Array<ContributionWhereUniqueInput>>,
};

export type ContributionCreateOneInput = {
  create?: Maybe<ContributionCreateInput>,
  connect?: Maybe<ContributionWhereUniqueInput>,
};

export type ContributionCreateWithoutStokvelInput = {
  id?: Maybe<Scalars['ID']>,
  amount: Scalars['Float'],
  name?: Maybe<Scalars['String']>,
  transactionDate: Scalars['DateTime'],
  proofOfPayment: Scalars['String'],
  cancelled: Scalars['Boolean'],
  reason?: Maybe<Scalars['String']>,
  member: MemberCreateOneInput,
};

/** An edge in a connection. */
export type ContributionEdge = {
   __typename?: 'ContributionEdge',
  /** The item at the end of the edge. */
  node: Contribution,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum ContributionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TransactionDateAsc = 'transactionDate_ASC',
  TransactionDateDesc = 'transactionDate_DESC',
  ProofOfPaymentAsc = 'proofOfPayment_ASC',
  ProofOfPaymentDesc = 'proofOfPayment_DESC',
  CancelledAsc = 'cancelled_ASC',
  CancelledDesc = 'cancelled_DESC',
  ReasonAsc = 'reason_ASC',
  ReasonDesc = 'reason_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ContributionPreviousValues = {
   __typename?: 'ContributionPreviousValues',
  id: Scalars['ID'],
  amount: Scalars['Float'],
  name?: Maybe<Scalars['String']>,
  transactionDate: Scalars['DateTime'],
  proofOfPayment: Scalars['String'],
  cancelled: Scalars['Boolean'],
  reason?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type ContributionScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ContributionScalarWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ContributionScalarWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ContributionScalarWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  amount?: Maybe<Scalars['Float']>,
  /** All values that are not equal to given value. */
  amount_not?: Maybe<Scalars['Float']>,
  /** All values that are contained in given list. */
  amount_in?: Maybe<Array<Scalars['Float']>>,
  /** All values that are not contained in given list. */
  amount_not_in?: Maybe<Array<Scalars['Float']>>,
  /** All values less than the given value. */
  amount_lt?: Maybe<Scalars['Float']>,
  /** All values less than or equal the given value. */
  amount_lte?: Maybe<Scalars['Float']>,
  /** All values greater than the given value. */
  amount_gt?: Maybe<Scalars['Float']>,
  /** All values greater than or equal the given value. */
  amount_gte?: Maybe<Scalars['Float']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  transactionDate?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  transactionDate_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  transactionDate_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  transactionDate_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  transactionDate_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  transactionDate_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  transactionDate_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  transactionDate_gte?: Maybe<Scalars['DateTime']>,
  proofOfPayment?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  proofOfPayment_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  proofOfPayment_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  proofOfPayment_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  proofOfPayment_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  proofOfPayment_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  proofOfPayment_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  proofOfPayment_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  proofOfPayment_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  proofOfPayment_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  proofOfPayment_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  proofOfPayment_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  proofOfPayment_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  proofOfPayment_not_ends_with?: Maybe<Scalars['String']>,
  cancelled?: Maybe<Scalars['Boolean']>,
  /** All values that are not equal to given value. */
  cancelled_not?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  reason_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  reason_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  reason_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  reason_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  reason_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  reason_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  reason_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  reason_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  reason_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  reason_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  reason_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  reason_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  reason_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type ContributionSubscriptionPayload = {
   __typename?: 'ContributionSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<Contribution>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<ContributionPreviousValues>,
};

export type ContributionSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ContributionSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ContributionSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ContributionSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<ContributionWhereInput>,
};

export type ContributionUpdateDataInput = {
  amount?: Maybe<Scalars['Float']>,
  name?: Maybe<Scalars['String']>,
  transactionDate?: Maybe<Scalars['DateTime']>,
  proofOfPayment?: Maybe<Scalars['String']>,
  cancelled?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
  member?: Maybe<MemberUpdateOneRequiredInput>,
  stokvel?: Maybe<StokvelUpdateOneRequiredWithoutContributionInput>,
};

export type ContributionUpdateInput = {
  amount?: Maybe<Scalars['Float']>,
  name?: Maybe<Scalars['String']>,
  transactionDate?: Maybe<Scalars['DateTime']>,
  proofOfPayment?: Maybe<Scalars['String']>,
  cancelled?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
  member?: Maybe<MemberUpdateOneRequiredInput>,
  stokvel?: Maybe<StokvelUpdateOneRequiredWithoutContributionInput>,
};

export type ContributionUpdateManyDataInput = {
  amount?: Maybe<Scalars['Float']>,
  name?: Maybe<Scalars['String']>,
  transactionDate?: Maybe<Scalars['DateTime']>,
  proofOfPayment?: Maybe<Scalars['String']>,
  cancelled?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
};

export type ContributionUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Float']>,
  name?: Maybe<Scalars['String']>,
  transactionDate?: Maybe<Scalars['DateTime']>,
  proofOfPayment?: Maybe<Scalars['String']>,
  cancelled?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
};

export type ContributionUpdateManyWithoutStokvelInput = {
  create?: Maybe<Array<ContributionCreateWithoutStokvelInput>>,
  connect?: Maybe<Array<ContributionWhereUniqueInput>>,
  set?: Maybe<Array<ContributionWhereUniqueInput>>,
  disconnect?: Maybe<Array<ContributionWhereUniqueInput>>,
  delete?: Maybe<Array<ContributionWhereUniqueInput>>,
  update?: Maybe<Array<ContributionUpdateWithWhereUniqueWithoutStokvelInput>>,
  updateMany?: Maybe<Array<ContributionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ContributionScalarWhereInput>>,
  upsert?: Maybe<Array<ContributionUpsertWithWhereUniqueWithoutStokvelInput>>,
};

export type ContributionUpdateManyWithWhereNestedInput = {
  where: ContributionScalarWhereInput,
  data: ContributionUpdateManyDataInput,
};

export type ContributionUpdateOneInput = {
  create?: Maybe<ContributionCreateInput>,
  connect?: Maybe<ContributionWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<ContributionUpdateDataInput>,
  upsert?: Maybe<ContributionUpsertNestedInput>,
};

export type ContributionUpdateWithoutStokvelDataInput = {
  amount?: Maybe<Scalars['Float']>,
  name?: Maybe<Scalars['String']>,
  transactionDate?: Maybe<Scalars['DateTime']>,
  proofOfPayment?: Maybe<Scalars['String']>,
  cancelled?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
  member?: Maybe<MemberUpdateOneRequiredInput>,
};

export type ContributionUpdateWithWhereUniqueWithoutStokvelInput = {
  where: ContributionWhereUniqueInput,
  data: ContributionUpdateWithoutStokvelDataInput,
};

export type ContributionUpsertNestedInput = {
  update: ContributionUpdateDataInput,
  create: ContributionCreateInput,
};

export type ContributionUpsertWithWhereUniqueWithoutStokvelInput = {
  where: ContributionWhereUniqueInput,
  update: ContributionUpdateWithoutStokvelDataInput,
  create: ContributionCreateWithoutStokvelInput,
};

export type ContributionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ContributionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ContributionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ContributionWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  amount?: Maybe<Scalars['Float']>,
  /** All values that are not equal to given value. */
  amount_not?: Maybe<Scalars['Float']>,
  /** All values that are contained in given list. */
  amount_in?: Maybe<Array<Scalars['Float']>>,
  /** All values that are not contained in given list. */
  amount_not_in?: Maybe<Array<Scalars['Float']>>,
  /** All values less than the given value. */
  amount_lt?: Maybe<Scalars['Float']>,
  /** All values less than or equal the given value. */
  amount_lte?: Maybe<Scalars['Float']>,
  /** All values greater than the given value. */
  amount_gt?: Maybe<Scalars['Float']>,
  /** All values greater than or equal the given value. */
  amount_gte?: Maybe<Scalars['Float']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  transactionDate?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  transactionDate_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  transactionDate_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  transactionDate_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  transactionDate_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  transactionDate_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  transactionDate_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  transactionDate_gte?: Maybe<Scalars['DateTime']>,
  proofOfPayment?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  proofOfPayment_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  proofOfPayment_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  proofOfPayment_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  proofOfPayment_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  proofOfPayment_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  proofOfPayment_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  proofOfPayment_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  proofOfPayment_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  proofOfPayment_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  proofOfPayment_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  proofOfPayment_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  proofOfPayment_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  proofOfPayment_not_ends_with?: Maybe<Scalars['String']>,
  cancelled?: Maybe<Scalars['Boolean']>,
  /** All values that are not equal to given value. */
  cancelled_not?: Maybe<Scalars['Boolean']>,
  reason?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  reason_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  reason_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  reason_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  reason_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  reason_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  reason_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  reason_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  reason_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  reason_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  reason_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  reason_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  reason_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  reason_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  member?: Maybe<MemberWhereInput>,
  stokvel?: Maybe<StokvelWhereInput>,
};

export type ContributionWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};


export type Event = Node & {
   __typename?: 'Event',
  id: Scalars['ID'],
  name: Scalars['String'],
  description: Scalars['String'],
  startDate: Scalars['DateTime'],
  stokvel?: Maybe<Stokvel>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

/** A connection to a list of items. */
export type EventConnection = {
   __typename?: 'EventConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<EventEdge>>,
  aggregate: AggregateEvent,
};

export type EventCreateInput = {
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  description: Scalars['String'],
  startDate: Scalars['DateTime'],
  stokvel?: Maybe<StokvelCreateOneWithoutEventInput>,
};

export type EventCreateManyWithoutStokvelInput = {
  create?: Maybe<Array<EventCreateWithoutStokvelInput>>,
  connect?: Maybe<Array<EventWhereUniqueInput>>,
};

export type EventCreateWithoutStokvelInput = {
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  description: Scalars['String'],
  startDate: Scalars['DateTime'],
};

/** An edge in a connection. */
export type EventEdge = {
   __typename?: 'EventEdge',
  /** The item at the end of the edge. */
  node: Event,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum EventOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type EventPreviousValues = {
   __typename?: 'EventPreviousValues',
  id: Scalars['ID'],
  name: Scalars['String'],
  description: Scalars['String'],
  startDate: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type EventScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<EventScalarWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<EventScalarWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<EventScalarWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  description_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  description_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  description_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  description_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  description_not_ends_with?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  startDate_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  startDate_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  startDate_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  startDate_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  startDate_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  startDate_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type EventSubscriptionPayload = {
   __typename?: 'EventSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<Event>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<EventPreviousValues>,
};

export type EventSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<EventSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<EventSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<EventSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<EventWhereInput>,
};

export type EventUpdateInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  stokvel?: Maybe<StokvelUpdateOneWithoutEventInput>,
};

export type EventUpdateManyDataInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
};

export type EventUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
};

export type EventUpdateManyWithoutStokvelInput = {
  create?: Maybe<Array<EventCreateWithoutStokvelInput>>,
  connect?: Maybe<Array<EventWhereUniqueInput>>,
  set?: Maybe<Array<EventWhereUniqueInput>>,
  disconnect?: Maybe<Array<EventWhereUniqueInput>>,
  delete?: Maybe<Array<EventWhereUniqueInput>>,
  update?: Maybe<Array<EventUpdateWithWhereUniqueWithoutStokvelInput>>,
  updateMany?: Maybe<Array<EventUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<EventScalarWhereInput>>,
  upsert?: Maybe<Array<EventUpsertWithWhereUniqueWithoutStokvelInput>>,
};

export type EventUpdateManyWithWhereNestedInput = {
  where: EventScalarWhereInput,
  data: EventUpdateManyDataInput,
};

export type EventUpdateWithoutStokvelDataInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
};

export type EventUpdateWithWhereUniqueWithoutStokvelInput = {
  where: EventWhereUniqueInput,
  data: EventUpdateWithoutStokvelDataInput,
};

export type EventUpsertWithWhereUniqueWithoutStokvelInput = {
  where: EventWhereUniqueInput,
  update: EventUpdateWithoutStokvelDataInput,
  create: EventCreateWithoutStokvelInput,
};

export type EventWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<EventWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<EventWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<EventWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  description_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  description_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  description_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  description_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  description_not_ends_with?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  startDate_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  startDate_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  startDate_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  startDate_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  startDate_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  startDate_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  stokvel?: Maybe<StokvelWhereInput>,
};

export type EventWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type Invite = Node & {
   __typename?: 'Invite',
  id: Scalars['ID'],
  invitedDate: Scalars['DateTime'],
  alreadyActive: Scalars['Boolean'],
  isSent: Scalars['Boolean'],
  detail: PersonalDetail,
  member?: Maybe<Member>,
  stokvel: Stokvel,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

/** A connection to a list of items. */
export type InviteConnection = {
   __typename?: 'InviteConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<InviteEdge>>,
  aggregate: AggregateInvite,
};

export type InviteCreateInput = {
  id?: Maybe<Scalars['ID']>,
  invitedDate: Scalars['DateTime'],
  alreadyActive: Scalars['Boolean'],
  isSent: Scalars['Boolean'],
  detail: PersonalDetailCreateOneInput,
  member?: Maybe<MemberCreateOneInput>,
  stokvel: StokvelCreateOneInput,
};

export type InviteCreateManyInput = {
  create?: Maybe<Array<InviteCreateInput>>,
  connect?: Maybe<Array<InviteWhereUniqueInput>>,
};

export type InviteCreateOneInput = {
  create?: Maybe<InviteCreateInput>,
  connect?: Maybe<InviteWhereUniqueInput>,
};

/** An edge in a connection. */
export type InviteEdge = {
   __typename?: 'InviteEdge',
  /** The item at the end of the edge. */
  node: Invite,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum InviteOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InvitedDateAsc = 'invitedDate_ASC',
  InvitedDateDesc = 'invitedDate_DESC',
  AlreadyActiveAsc = 'alreadyActive_ASC',
  AlreadyActiveDesc = 'alreadyActive_DESC',
  IsSentAsc = 'isSent_ASC',
  IsSentDesc = 'isSent_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type InvitePreviousValues = {
   __typename?: 'InvitePreviousValues',
  id: Scalars['ID'],
  invitedDate: Scalars['DateTime'],
  alreadyActive: Scalars['Boolean'],
  isSent: Scalars['Boolean'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type InviteScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<InviteScalarWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<InviteScalarWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<InviteScalarWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  invitedDate?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  invitedDate_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  invitedDate_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  invitedDate_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  invitedDate_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  invitedDate_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  invitedDate_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  invitedDate_gte?: Maybe<Scalars['DateTime']>,
  alreadyActive?: Maybe<Scalars['Boolean']>,
  /** All values that are not equal to given value. */
  alreadyActive_not?: Maybe<Scalars['Boolean']>,
  isSent?: Maybe<Scalars['Boolean']>,
  /** All values that are not equal to given value. */
  isSent_not?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type InviteSubscriptionPayload = {
   __typename?: 'InviteSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<Invite>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<InvitePreviousValues>,
};

export type InviteSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<InviteSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<InviteSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<InviteSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<InviteWhereInput>,
};

export type InviteUpdateDataInput = {
  invitedDate?: Maybe<Scalars['DateTime']>,
  alreadyActive?: Maybe<Scalars['Boolean']>,
  isSent?: Maybe<Scalars['Boolean']>,
  detail?: Maybe<PersonalDetailUpdateOneRequiredInput>,
  member?: Maybe<MemberUpdateOneInput>,
  stokvel?: Maybe<StokvelUpdateOneRequiredInput>,
};

export type InviteUpdateInput = {
  invitedDate?: Maybe<Scalars['DateTime']>,
  alreadyActive?: Maybe<Scalars['Boolean']>,
  isSent?: Maybe<Scalars['Boolean']>,
  detail?: Maybe<PersonalDetailUpdateOneRequiredInput>,
  member?: Maybe<MemberUpdateOneInput>,
  stokvel?: Maybe<StokvelUpdateOneRequiredInput>,
};

export type InviteUpdateManyDataInput = {
  invitedDate?: Maybe<Scalars['DateTime']>,
  alreadyActive?: Maybe<Scalars['Boolean']>,
  isSent?: Maybe<Scalars['Boolean']>,
};

export type InviteUpdateManyInput = {
  create?: Maybe<Array<InviteCreateInput>>,
  connect?: Maybe<Array<InviteWhereUniqueInput>>,
  set?: Maybe<Array<InviteWhereUniqueInput>>,
  disconnect?: Maybe<Array<InviteWhereUniqueInput>>,
  delete?: Maybe<Array<InviteWhereUniqueInput>>,
  update?: Maybe<Array<InviteUpdateWithWhereUniqueNestedInput>>,
  updateMany?: Maybe<Array<InviteUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<InviteScalarWhereInput>>,
  upsert?: Maybe<Array<InviteUpsertWithWhereUniqueNestedInput>>,
};

export type InviteUpdateManyMutationInput = {
  invitedDate?: Maybe<Scalars['DateTime']>,
  alreadyActive?: Maybe<Scalars['Boolean']>,
  isSent?: Maybe<Scalars['Boolean']>,
};

export type InviteUpdateManyWithWhereNestedInput = {
  where: InviteScalarWhereInput,
  data: InviteUpdateManyDataInput,
};

export type InviteUpdateOneInput = {
  create?: Maybe<InviteCreateInput>,
  connect?: Maybe<InviteWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<InviteUpdateDataInput>,
  upsert?: Maybe<InviteUpsertNestedInput>,
};

export type InviteUpdateWithWhereUniqueNestedInput = {
  where: InviteWhereUniqueInput,
  data: InviteUpdateDataInput,
};

export type InviteUpsertNestedInput = {
  update: InviteUpdateDataInput,
  create: InviteCreateInput,
};

export type InviteUpsertWithWhereUniqueNestedInput = {
  where: InviteWhereUniqueInput,
  update: InviteUpdateDataInput,
  create: InviteCreateInput,
};

export type InviteWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<InviteWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<InviteWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<InviteWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  invitedDate?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  invitedDate_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  invitedDate_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  invitedDate_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  invitedDate_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  invitedDate_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  invitedDate_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  invitedDate_gte?: Maybe<Scalars['DateTime']>,
  alreadyActive?: Maybe<Scalars['Boolean']>,
  /** All values that are not equal to given value. */
  alreadyActive_not?: Maybe<Scalars['Boolean']>,
  isSent?: Maybe<Scalars['Boolean']>,
  /** All values that are not equal to given value. */
  isSent_not?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  detail?: Maybe<PersonalDetailWhereInput>,
  member?: Maybe<MemberWhereInput>,
  stokvel?: Maybe<StokvelWhereInput>,
};

export type InviteWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};


export type Member = Node & {
   __typename?: 'Member',
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  user?: Maybe<User>,
  invite?: Maybe<Invite>,
  stokvel?: Maybe<Stokvel>,
  contribution?: Maybe<Contribution>,
  joinedDate: Scalars['DateTime'],
  manualAdd?: Maybe<Scalars['Boolean']>,
  proofOfAuthorization?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

/** A connection to a list of items. */
export type MemberConnection = {
   __typename?: 'MemberConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<MemberEdge>>,
  aggregate: AggregateMember,
};

export type MemberCreateInput = {
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  joinedDate: Scalars['DateTime'],
  manualAdd?: Maybe<Scalars['Boolean']>,
  proofOfAuthorization?: Maybe<Scalars['String']>,
  user?: Maybe<UserCreateOneWithoutMemberOfInput>,
  invite?: Maybe<InviteCreateOneInput>,
  stokvel?: Maybe<StokvelCreateOneWithoutMembersInput>,
  contribution?: Maybe<ContributionCreateOneInput>,
};

export type MemberCreateManyWithoutStokvelInput = {
  create?: Maybe<Array<MemberCreateWithoutStokvelInput>>,
  connect?: Maybe<Array<MemberWhereUniqueInput>>,
};

export type MemberCreateManyWithoutUserInput = {
  create?: Maybe<Array<MemberCreateWithoutUserInput>>,
  connect?: Maybe<Array<MemberWhereUniqueInput>>,
};

export type MemberCreateOneInput = {
  create?: Maybe<MemberCreateInput>,
  connect?: Maybe<MemberWhereUniqueInput>,
};

export type MemberCreateWithoutStokvelInput = {
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  joinedDate: Scalars['DateTime'],
  manualAdd?: Maybe<Scalars['Boolean']>,
  proofOfAuthorization?: Maybe<Scalars['String']>,
  user?: Maybe<UserCreateOneWithoutMemberOfInput>,
  invite?: Maybe<InviteCreateOneInput>,
  contribution?: Maybe<ContributionCreateOneInput>,
};

export type MemberCreateWithoutUserInput = {
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  joinedDate: Scalars['DateTime'],
  manualAdd?: Maybe<Scalars['Boolean']>,
  proofOfAuthorization?: Maybe<Scalars['String']>,
  invite?: Maybe<InviteCreateOneInput>,
  stokvel?: Maybe<StokvelCreateOneWithoutMembersInput>,
  contribution?: Maybe<ContributionCreateOneInput>,
};

/** An edge in a connection. */
export type MemberEdge = {
   __typename?: 'MemberEdge',
  /** The item at the end of the edge. */
  node: Member,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum MemberOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  JoinedDateAsc = 'joinedDate_ASC',
  JoinedDateDesc = 'joinedDate_DESC',
  ManualAddAsc = 'manualAdd_ASC',
  ManualAddDesc = 'manualAdd_DESC',
  ProofOfAuthorizationAsc = 'proofOfAuthorization_ASC',
  ProofOfAuthorizationDesc = 'proofOfAuthorization_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type MemberPreviousValues = {
   __typename?: 'MemberPreviousValues',
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  joinedDate: Scalars['DateTime'],
  manualAdd?: Maybe<Scalars['Boolean']>,
  proofOfAuthorization?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type MemberScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<MemberScalarWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<MemberScalarWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<MemberScalarWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  joinedDate?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  joinedDate_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  joinedDate_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  joinedDate_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  joinedDate_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  joinedDate_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  joinedDate_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  joinedDate_gte?: Maybe<Scalars['DateTime']>,
  manualAdd?: Maybe<Scalars['Boolean']>,
  /** All values that are not equal to given value. */
  manualAdd_not?: Maybe<Scalars['Boolean']>,
  proofOfAuthorization?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  proofOfAuthorization_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  proofOfAuthorization_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  proofOfAuthorization_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  proofOfAuthorization_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  proofOfAuthorization_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  proofOfAuthorization_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  proofOfAuthorization_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  proofOfAuthorization_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  proofOfAuthorization_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  proofOfAuthorization_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  proofOfAuthorization_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  proofOfAuthorization_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  proofOfAuthorization_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type MemberSubscriptionPayload = {
   __typename?: 'MemberSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<Member>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<MemberPreviousValues>,
};

export type MemberSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<MemberSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<MemberSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<MemberSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<MemberWhereInput>,
};

export type MemberUpdateDataInput = {
  name?: Maybe<Scalars['String']>,
  joinedDate?: Maybe<Scalars['DateTime']>,
  manualAdd?: Maybe<Scalars['Boolean']>,
  proofOfAuthorization?: Maybe<Scalars['String']>,
  user?: Maybe<UserUpdateOneWithoutMemberOfInput>,
  invite?: Maybe<InviteUpdateOneInput>,
  stokvel?: Maybe<StokvelUpdateOneWithoutMembersInput>,
  contribution?: Maybe<ContributionUpdateOneInput>,
};

export type MemberUpdateInput = {
  name?: Maybe<Scalars['String']>,
  joinedDate?: Maybe<Scalars['DateTime']>,
  manualAdd?: Maybe<Scalars['Boolean']>,
  proofOfAuthorization?: Maybe<Scalars['String']>,
  user?: Maybe<UserUpdateOneWithoutMemberOfInput>,
  invite?: Maybe<InviteUpdateOneInput>,
  stokvel?: Maybe<StokvelUpdateOneWithoutMembersInput>,
  contribution?: Maybe<ContributionUpdateOneInput>,
};

export type MemberUpdateManyDataInput = {
  name?: Maybe<Scalars['String']>,
  joinedDate?: Maybe<Scalars['DateTime']>,
  manualAdd?: Maybe<Scalars['Boolean']>,
  proofOfAuthorization?: Maybe<Scalars['String']>,
};

export type MemberUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>,
  joinedDate?: Maybe<Scalars['DateTime']>,
  manualAdd?: Maybe<Scalars['Boolean']>,
  proofOfAuthorization?: Maybe<Scalars['String']>,
};

export type MemberUpdateManyWithoutStokvelInput = {
  create?: Maybe<Array<MemberCreateWithoutStokvelInput>>,
  connect?: Maybe<Array<MemberWhereUniqueInput>>,
  set?: Maybe<Array<MemberWhereUniqueInput>>,
  disconnect?: Maybe<Array<MemberWhereUniqueInput>>,
  delete?: Maybe<Array<MemberWhereUniqueInput>>,
  update?: Maybe<Array<MemberUpdateWithWhereUniqueWithoutStokvelInput>>,
  updateMany?: Maybe<Array<MemberUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<MemberScalarWhereInput>>,
  upsert?: Maybe<Array<MemberUpsertWithWhereUniqueWithoutStokvelInput>>,
};

export type MemberUpdateManyWithoutUserInput = {
  create?: Maybe<Array<MemberCreateWithoutUserInput>>,
  connect?: Maybe<Array<MemberWhereUniqueInput>>,
  set?: Maybe<Array<MemberWhereUniqueInput>>,
  disconnect?: Maybe<Array<MemberWhereUniqueInput>>,
  delete?: Maybe<Array<MemberWhereUniqueInput>>,
  update?: Maybe<Array<MemberUpdateWithWhereUniqueWithoutUserInput>>,
  updateMany?: Maybe<Array<MemberUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<MemberScalarWhereInput>>,
  upsert?: Maybe<Array<MemberUpsertWithWhereUniqueWithoutUserInput>>,
};

export type MemberUpdateManyWithWhereNestedInput = {
  where: MemberScalarWhereInput,
  data: MemberUpdateManyDataInput,
};

export type MemberUpdateOneInput = {
  create?: Maybe<MemberCreateInput>,
  connect?: Maybe<MemberWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<MemberUpdateDataInput>,
  upsert?: Maybe<MemberUpsertNestedInput>,
};

export type MemberUpdateOneRequiredInput = {
  create?: Maybe<MemberCreateInput>,
  connect?: Maybe<MemberWhereUniqueInput>,
  update?: Maybe<MemberUpdateDataInput>,
  upsert?: Maybe<MemberUpsertNestedInput>,
};

export type MemberUpdateWithoutStokvelDataInput = {
  name?: Maybe<Scalars['String']>,
  joinedDate?: Maybe<Scalars['DateTime']>,
  manualAdd?: Maybe<Scalars['Boolean']>,
  proofOfAuthorization?: Maybe<Scalars['String']>,
  user?: Maybe<UserUpdateOneWithoutMemberOfInput>,
  invite?: Maybe<InviteUpdateOneInput>,
  contribution?: Maybe<ContributionUpdateOneInput>,
};

export type MemberUpdateWithoutUserDataInput = {
  name?: Maybe<Scalars['String']>,
  joinedDate?: Maybe<Scalars['DateTime']>,
  manualAdd?: Maybe<Scalars['Boolean']>,
  proofOfAuthorization?: Maybe<Scalars['String']>,
  invite?: Maybe<InviteUpdateOneInput>,
  stokvel?: Maybe<StokvelUpdateOneWithoutMembersInput>,
  contribution?: Maybe<ContributionUpdateOneInput>,
};

export type MemberUpdateWithWhereUniqueWithoutStokvelInput = {
  where: MemberWhereUniqueInput,
  data: MemberUpdateWithoutStokvelDataInput,
};

export type MemberUpdateWithWhereUniqueWithoutUserInput = {
  where: MemberWhereUniqueInput,
  data: MemberUpdateWithoutUserDataInput,
};

export type MemberUpsertNestedInput = {
  update: MemberUpdateDataInput,
  create: MemberCreateInput,
};

export type MemberUpsertWithWhereUniqueWithoutStokvelInput = {
  where: MemberWhereUniqueInput,
  update: MemberUpdateWithoutStokvelDataInput,
  create: MemberCreateWithoutStokvelInput,
};

export type MemberUpsertWithWhereUniqueWithoutUserInput = {
  where: MemberWhereUniqueInput,
  update: MemberUpdateWithoutUserDataInput,
  create: MemberCreateWithoutUserInput,
};

export type MemberWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<MemberWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<MemberWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<MemberWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  joinedDate?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  joinedDate_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  joinedDate_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  joinedDate_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  joinedDate_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  joinedDate_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  joinedDate_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  joinedDate_gte?: Maybe<Scalars['DateTime']>,
  manualAdd?: Maybe<Scalars['Boolean']>,
  /** All values that are not equal to given value. */
  manualAdd_not?: Maybe<Scalars['Boolean']>,
  proofOfAuthorization?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  proofOfAuthorization_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  proofOfAuthorization_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  proofOfAuthorization_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  proofOfAuthorization_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  proofOfAuthorization_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  proofOfAuthorization_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  proofOfAuthorization_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  proofOfAuthorization_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  proofOfAuthorization_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  proofOfAuthorization_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  proofOfAuthorization_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  proofOfAuthorization_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  proofOfAuthorization_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  user?: Maybe<UserWhereInput>,
  invite?: Maybe<InviteWhereInput>,
  stokvel?: Maybe<StokvelWhereInput>,
  contribution?: Maybe<ContributionWhereInput>,
};

export type MemberWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type Message = Node & {
   __typename?: 'Message',
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  from?: Maybe<User>,
  to?: Maybe<User>,
  stokvel?: Maybe<Stokvel>,
  text: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

/** A connection to a list of items. */
export type MessageConnection = {
   __typename?: 'MessageConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<MessageEdge>>,
  aggregate: AggregateMessage,
};

export type MessageCreateInput = {
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  text: Scalars['String'],
  from?: Maybe<UserCreateOneWithoutMessagesSentInput>,
  to?: Maybe<UserCreateOneWithoutMessagesReceivedInput>,
  stokvel?: Maybe<StokvelCreateOneWithoutMessagesInput>,
};

export type MessageCreateManyWithoutFromInput = {
  create?: Maybe<Array<MessageCreateWithoutFromInput>>,
  connect?: Maybe<Array<MessageWhereUniqueInput>>,
};

export type MessageCreateManyWithoutStokvelInput = {
  create?: Maybe<Array<MessageCreateWithoutStokvelInput>>,
  connect?: Maybe<Array<MessageWhereUniqueInput>>,
};

export type MessageCreateManyWithoutToInput = {
  create?: Maybe<Array<MessageCreateWithoutToInput>>,
  connect?: Maybe<Array<MessageWhereUniqueInput>>,
};

export type MessageCreateWithoutFromInput = {
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  text: Scalars['String'],
  to?: Maybe<UserCreateOneWithoutMessagesReceivedInput>,
  stokvel?: Maybe<StokvelCreateOneWithoutMessagesInput>,
};

export type MessageCreateWithoutStokvelInput = {
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  text: Scalars['String'],
  from?: Maybe<UserCreateOneWithoutMessagesSentInput>,
  to?: Maybe<UserCreateOneWithoutMessagesReceivedInput>,
};

export type MessageCreateWithoutToInput = {
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  text: Scalars['String'],
  from?: Maybe<UserCreateOneWithoutMessagesSentInput>,
  stokvel?: Maybe<StokvelCreateOneWithoutMessagesInput>,
};

/** An edge in a connection. */
export type MessageEdge = {
   __typename?: 'MessageEdge',
  /** The item at the end of the edge. */
  node: Message,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum MessageOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type MessagePreviousValues = {
   __typename?: 'MessagePreviousValues',
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  text: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type MessageScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<MessageScalarWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<MessageScalarWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<MessageScalarWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  text_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  text_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  text_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  text_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  text_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  text_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  text_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  text_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  text_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  text_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  text_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  text_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  text_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type MessageSubscriptionPayload = {
   __typename?: 'MessageSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<Message>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<MessagePreviousValues>,
};

export type MessageSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<MessageSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<MessageSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<MessageSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<MessageWhereInput>,
};

export type MessageUpdateInput = {
  name?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  from?: Maybe<UserUpdateOneWithoutMessagesSentInput>,
  to?: Maybe<UserUpdateOneWithoutMessagesReceivedInput>,
  stokvel?: Maybe<StokvelUpdateOneWithoutMessagesInput>,
};

export type MessageUpdateManyDataInput = {
  name?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
};

export type MessageUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
};

export type MessageUpdateManyWithoutFromInput = {
  create?: Maybe<Array<MessageCreateWithoutFromInput>>,
  connect?: Maybe<Array<MessageWhereUniqueInput>>,
  set?: Maybe<Array<MessageWhereUniqueInput>>,
  disconnect?: Maybe<Array<MessageWhereUniqueInput>>,
  delete?: Maybe<Array<MessageWhereUniqueInput>>,
  update?: Maybe<Array<MessageUpdateWithWhereUniqueWithoutFromInput>>,
  updateMany?: Maybe<Array<MessageUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<MessageScalarWhereInput>>,
  upsert?: Maybe<Array<MessageUpsertWithWhereUniqueWithoutFromInput>>,
};

export type MessageUpdateManyWithoutStokvelInput = {
  create?: Maybe<Array<MessageCreateWithoutStokvelInput>>,
  connect?: Maybe<Array<MessageWhereUniqueInput>>,
  set?: Maybe<Array<MessageWhereUniqueInput>>,
  disconnect?: Maybe<Array<MessageWhereUniqueInput>>,
  delete?: Maybe<Array<MessageWhereUniqueInput>>,
  update?: Maybe<Array<MessageUpdateWithWhereUniqueWithoutStokvelInput>>,
  updateMany?: Maybe<Array<MessageUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<MessageScalarWhereInput>>,
  upsert?: Maybe<Array<MessageUpsertWithWhereUniqueWithoutStokvelInput>>,
};

export type MessageUpdateManyWithoutToInput = {
  create?: Maybe<Array<MessageCreateWithoutToInput>>,
  connect?: Maybe<Array<MessageWhereUniqueInput>>,
  set?: Maybe<Array<MessageWhereUniqueInput>>,
  disconnect?: Maybe<Array<MessageWhereUniqueInput>>,
  delete?: Maybe<Array<MessageWhereUniqueInput>>,
  update?: Maybe<Array<MessageUpdateWithWhereUniqueWithoutToInput>>,
  updateMany?: Maybe<Array<MessageUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<MessageScalarWhereInput>>,
  upsert?: Maybe<Array<MessageUpsertWithWhereUniqueWithoutToInput>>,
};

export type MessageUpdateManyWithWhereNestedInput = {
  where: MessageScalarWhereInput,
  data: MessageUpdateManyDataInput,
};

export type MessageUpdateWithoutFromDataInput = {
  name?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  to?: Maybe<UserUpdateOneWithoutMessagesReceivedInput>,
  stokvel?: Maybe<StokvelUpdateOneWithoutMessagesInput>,
};

export type MessageUpdateWithoutStokvelDataInput = {
  name?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  from?: Maybe<UserUpdateOneWithoutMessagesSentInput>,
  to?: Maybe<UserUpdateOneWithoutMessagesReceivedInput>,
};

export type MessageUpdateWithoutToDataInput = {
  name?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  from?: Maybe<UserUpdateOneWithoutMessagesSentInput>,
  stokvel?: Maybe<StokvelUpdateOneWithoutMessagesInput>,
};

export type MessageUpdateWithWhereUniqueWithoutFromInput = {
  where: MessageWhereUniqueInput,
  data: MessageUpdateWithoutFromDataInput,
};

export type MessageUpdateWithWhereUniqueWithoutStokvelInput = {
  where: MessageWhereUniqueInput,
  data: MessageUpdateWithoutStokvelDataInput,
};

export type MessageUpdateWithWhereUniqueWithoutToInput = {
  where: MessageWhereUniqueInput,
  data: MessageUpdateWithoutToDataInput,
};

export type MessageUpsertWithWhereUniqueWithoutFromInput = {
  where: MessageWhereUniqueInput,
  update: MessageUpdateWithoutFromDataInput,
  create: MessageCreateWithoutFromInput,
};

export type MessageUpsertWithWhereUniqueWithoutStokvelInput = {
  where: MessageWhereUniqueInput,
  update: MessageUpdateWithoutStokvelDataInput,
  create: MessageCreateWithoutStokvelInput,
};

export type MessageUpsertWithWhereUniqueWithoutToInput = {
  where: MessageWhereUniqueInput,
  update: MessageUpdateWithoutToDataInput,
  create: MessageCreateWithoutToInput,
};

export type MessageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<MessageWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<MessageWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<MessageWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  text_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  text_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  text_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  text_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  text_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  text_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  text_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  text_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  text_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  text_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  text_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  text_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  text_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  from?: Maybe<UserWhereInput>,
  to?: Maybe<UserWhereInput>,
  stokvel?: Maybe<StokvelWhereInput>,
};

export type MessageWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type Mutation = {
   __typename?: 'Mutation',
  createPayment: Payment,
  createBankAccount: BankAccount,
  createMessage: Message,
  createConstitution: Constitution,
  createEvent: Event,
  createAddress: Address,
  createUser: User,
  createPersonalDetailIndex: PersonalDetailIndex,
  createContribution: Contribution,
  createStokvel: Stokvel,
  createInvite: Invite,
  createMember: Member,
  createPersonalDetail: PersonalDetail,
  updatePayment?: Maybe<Payment>,
  updateBankAccount?: Maybe<BankAccount>,
  updateMessage?: Maybe<Message>,
  updateConstitution?: Maybe<Constitution>,
  updateEvent?: Maybe<Event>,
  updateAddress?: Maybe<Address>,
  updateUser?: Maybe<User>,
  updatePersonalDetailIndex?: Maybe<PersonalDetailIndex>,
  updateContribution?: Maybe<Contribution>,
  updateStokvel?: Maybe<Stokvel>,
  updateInvite?: Maybe<Invite>,
  updateMember?: Maybe<Member>,
  updatePersonalDetail?: Maybe<PersonalDetail>,
  deletePayment?: Maybe<Payment>,
  deleteBankAccount?: Maybe<BankAccount>,
  deleteMessage?: Maybe<Message>,
  deleteConstitution?: Maybe<Constitution>,
  deleteEvent?: Maybe<Event>,
  deleteAddress?: Maybe<Address>,
  deleteUser?: Maybe<User>,
  deletePersonalDetailIndex?: Maybe<PersonalDetailIndex>,
  deleteContribution?: Maybe<Contribution>,
  deleteStokvel?: Maybe<Stokvel>,
  deleteInvite?: Maybe<Invite>,
  deleteMember?: Maybe<Member>,
  deletePersonalDetail?: Maybe<PersonalDetail>,
  upsertPayment: Payment,
  upsertBankAccount: BankAccount,
  upsertMessage: Message,
  upsertConstitution: Constitution,
  upsertEvent: Event,
  upsertAddress: Address,
  upsertUser: User,
  upsertPersonalDetailIndex: PersonalDetailIndex,
  upsertContribution: Contribution,
  upsertStokvel: Stokvel,
  upsertInvite: Invite,
  upsertMember: Member,
  upsertPersonalDetail: PersonalDetail,
  updateManyPayments: BatchPayload,
  updateManyBankAccounts: BatchPayload,
  updateManyMessages: BatchPayload,
  updateManyConstitutions: BatchPayload,
  updateManyEvents: BatchPayload,
  updateManyAddresses: BatchPayload,
  updateManyUsers: BatchPayload,
  updateManyPersonalDetailIndexes: BatchPayload,
  updateManyContributions: BatchPayload,
  updateManyStokvels: BatchPayload,
  updateManyInvites: BatchPayload,
  updateManyMembers: BatchPayload,
  updateManyPersonalDetails: BatchPayload,
  deleteManyPayments: BatchPayload,
  deleteManyBankAccounts: BatchPayload,
  deleteManyMessages: BatchPayload,
  deleteManyConstitutions: BatchPayload,
  deleteManyEvents: BatchPayload,
  deleteManyAddresses: BatchPayload,
  deleteManyUsers: BatchPayload,
  deleteManyPersonalDetailIndexes: BatchPayload,
  deleteManyContributions: BatchPayload,
  deleteManyStokvels: BatchPayload,
  deleteManyInvites: BatchPayload,
  deleteManyMembers: BatchPayload,
  deleteManyPersonalDetails: BatchPayload,
};


export type MutationCreatePaymentArgs = {
  data: PaymentCreateInput
};


export type MutationCreateBankAccountArgs = {
  data: BankAccountCreateInput
};


export type MutationCreateMessageArgs = {
  data: MessageCreateInput
};


export type MutationCreateConstitutionArgs = {
  data: ConstitutionCreateInput
};


export type MutationCreateEventArgs = {
  data: EventCreateInput
};


export type MutationCreateAddressArgs = {
  data: AddressCreateInput
};


export type MutationCreateUserArgs = {
  data: UserCreateInput
};


export type MutationCreatePersonalDetailIndexArgs = {
  data: PersonalDetailIndexCreateInput
};


export type MutationCreateContributionArgs = {
  data: ContributionCreateInput
};


export type MutationCreateStokvelArgs = {
  data: StokvelCreateInput
};


export type MutationCreateInviteArgs = {
  data: InviteCreateInput
};


export type MutationCreateMemberArgs = {
  data: MemberCreateInput
};


export type MutationCreatePersonalDetailArgs = {
  data: PersonalDetailCreateInput
};


export type MutationUpdatePaymentArgs = {
  data: PaymentUpdateInput,
  where: PaymentWhereUniqueInput
};


export type MutationUpdateBankAccountArgs = {
  data: BankAccountUpdateInput,
  where: BankAccountWhereUniqueInput
};


export type MutationUpdateMessageArgs = {
  data: MessageUpdateInput,
  where: MessageWhereUniqueInput
};


export type MutationUpdateConstitutionArgs = {
  data: ConstitutionUpdateInput,
  where: ConstitutionWhereUniqueInput
};


export type MutationUpdateEventArgs = {
  data: EventUpdateInput,
  where: EventWhereUniqueInput
};


export type MutationUpdateAddressArgs = {
  data: AddressUpdateInput,
  where: AddressWhereUniqueInput
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput,
  where: UserWhereUniqueInput
};


export type MutationUpdatePersonalDetailIndexArgs = {
  data: PersonalDetailIndexUpdateInput,
  where: PersonalDetailIndexWhereUniqueInput
};


export type MutationUpdateContributionArgs = {
  data: ContributionUpdateInput,
  where: ContributionWhereUniqueInput
};


export type MutationUpdateStokvelArgs = {
  data: StokvelUpdateInput,
  where: StokvelWhereUniqueInput
};


export type MutationUpdateInviteArgs = {
  data: InviteUpdateInput,
  where: InviteWhereUniqueInput
};


export type MutationUpdateMemberArgs = {
  data: MemberUpdateInput,
  where: MemberWhereUniqueInput
};


export type MutationUpdatePersonalDetailArgs = {
  data: PersonalDetailUpdateInput,
  where: PersonalDetailWhereUniqueInput
};


export type MutationDeletePaymentArgs = {
  where: PaymentWhereUniqueInput
};


export type MutationDeleteBankAccountArgs = {
  where: BankAccountWhereUniqueInput
};


export type MutationDeleteMessageArgs = {
  where: MessageWhereUniqueInput
};


export type MutationDeleteConstitutionArgs = {
  where: ConstitutionWhereUniqueInput
};


export type MutationDeleteEventArgs = {
  where: EventWhereUniqueInput
};


export type MutationDeleteAddressArgs = {
  where: AddressWhereUniqueInput
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput
};


export type MutationDeletePersonalDetailIndexArgs = {
  where: PersonalDetailIndexWhereUniqueInput
};


export type MutationDeleteContributionArgs = {
  where: ContributionWhereUniqueInput
};


export type MutationDeleteStokvelArgs = {
  where: StokvelWhereUniqueInput
};


export type MutationDeleteInviteArgs = {
  where: InviteWhereUniqueInput
};


export type MutationDeleteMemberArgs = {
  where: MemberWhereUniqueInput
};


export type MutationDeletePersonalDetailArgs = {
  where: PersonalDetailWhereUniqueInput
};


export type MutationUpsertPaymentArgs = {
  where: PaymentWhereUniqueInput,
  create: PaymentCreateInput,
  update: PaymentUpdateInput
};


export type MutationUpsertBankAccountArgs = {
  where: BankAccountWhereUniqueInput,
  create: BankAccountCreateInput,
  update: BankAccountUpdateInput
};


export type MutationUpsertMessageArgs = {
  where: MessageWhereUniqueInput,
  create: MessageCreateInput,
  update: MessageUpdateInput
};


export type MutationUpsertConstitutionArgs = {
  where: ConstitutionWhereUniqueInput,
  create: ConstitutionCreateInput,
  update: ConstitutionUpdateInput
};


export type MutationUpsertEventArgs = {
  where: EventWhereUniqueInput,
  create: EventCreateInput,
  update: EventUpdateInput
};


export type MutationUpsertAddressArgs = {
  where: AddressWhereUniqueInput,
  create: AddressCreateInput,
  update: AddressUpdateInput
};


export type MutationUpsertUserArgs = {
  where: UserWhereUniqueInput,
  create: UserCreateInput,
  update: UserUpdateInput
};


export type MutationUpsertPersonalDetailIndexArgs = {
  where: PersonalDetailIndexWhereUniqueInput,
  create: PersonalDetailIndexCreateInput,
  update: PersonalDetailIndexUpdateInput
};


export type MutationUpsertContributionArgs = {
  where: ContributionWhereUniqueInput,
  create: ContributionCreateInput,
  update: ContributionUpdateInput
};


export type MutationUpsertStokvelArgs = {
  where: StokvelWhereUniqueInput,
  create: StokvelCreateInput,
  update: StokvelUpdateInput
};


export type MutationUpsertInviteArgs = {
  where: InviteWhereUniqueInput,
  create: InviteCreateInput,
  update: InviteUpdateInput
};


export type MutationUpsertMemberArgs = {
  where: MemberWhereUniqueInput,
  create: MemberCreateInput,
  update: MemberUpdateInput
};


export type MutationUpsertPersonalDetailArgs = {
  where: PersonalDetailWhereUniqueInput,
  create: PersonalDetailCreateInput,
  update: PersonalDetailUpdateInput
};


export type MutationUpdateManyPaymentsArgs = {
  data: PaymentUpdateManyMutationInput,
  where?: Maybe<PaymentWhereInput>
};


export type MutationUpdateManyBankAccountsArgs = {
  data: BankAccountUpdateManyMutationInput,
  where?: Maybe<BankAccountWhereInput>
};


export type MutationUpdateManyMessagesArgs = {
  data: MessageUpdateManyMutationInput,
  where?: Maybe<MessageWhereInput>
};


export type MutationUpdateManyConstitutionsArgs = {
  data: ConstitutionUpdateManyMutationInput,
  where?: Maybe<ConstitutionWhereInput>
};


export type MutationUpdateManyEventsArgs = {
  data: EventUpdateManyMutationInput,
  where?: Maybe<EventWhereInput>
};


export type MutationUpdateManyAddressesArgs = {
  data: AddressUpdateManyMutationInput,
  where?: Maybe<AddressWhereInput>
};


export type MutationUpdateManyUsersArgs = {
  data: UserUpdateManyMutationInput,
  where?: Maybe<UserWhereInput>
};


export type MutationUpdateManyPersonalDetailIndexesArgs = {
  data: PersonalDetailIndexUpdateManyMutationInput,
  where?: Maybe<PersonalDetailIndexWhereInput>
};


export type MutationUpdateManyContributionsArgs = {
  data: ContributionUpdateManyMutationInput,
  where?: Maybe<ContributionWhereInput>
};


export type MutationUpdateManyStokvelsArgs = {
  data: StokvelUpdateManyMutationInput,
  where?: Maybe<StokvelWhereInput>
};


export type MutationUpdateManyInvitesArgs = {
  data: InviteUpdateManyMutationInput,
  where?: Maybe<InviteWhereInput>
};


export type MutationUpdateManyMembersArgs = {
  data: MemberUpdateManyMutationInput,
  where?: Maybe<MemberWhereInput>
};


export type MutationUpdateManyPersonalDetailsArgs = {
  data: PersonalDetailUpdateManyMutationInput,
  where?: Maybe<PersonalDetailWhereInput>
};


export type MutationDeleteManyPaymentsArgs = {
  where?: Maybe<PaymentWhereInput>
};


export type MutationDeleteManyBankAccountsArgs = {
  where?: Maybe<BankAccountWhereInput>
};


export type MutationDeleteManyMessagesArgs = {
  where?: Maybe<MessageWhereInput>
};


export type MutationDeleteManyConstitutionsArgs = {
  where?: Maybe<ConstitutionWhereInput>
};


export type MutationDeleteManyEventsArgs = {
  where?: Maybe<EventWhereInput>
};


export type MutationDeleteManyAddressesArgs = {
  where?: Maybe<AddressWhereInput>
};


export type MutationDeleteManyUsersArgs = {
  where?: Maybe<UserWhereInput>
};


export type MutationDeleteManyPersonalDetailIndexesArgs = {
  where?: Maybe<PersonalDetailIndexWhereInput>
};


export type MutationDeleteManyContributionsArgs = {
  where?: Maybe<ContributionWhereInput>
};


export type MutationDeleteManyStokvelsArgs = {
  where?: Maybe<StokvelWhereInput>
};


export type MutationDeleteManyInvitesArgs = {
  where?: Maybe<InviteWhereInput>
};


export type MutationDeleteManyMembersArgs = {
  where?: Maybe<MemberWhereInput>
};


export type MutationDeleteManyPersonalDetailsArgs = {
  where?: Maybe<PersonalDetailWhereInput>
};

export enum MutationType {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Deleted = 'DELETED'
}

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'],
};

/** Information about pagination in a connection. */
export type PageInfo = {
   __typename?: 'PageInfo',
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'],
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'],
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>,
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>,
};

export type Payment = Node & {
   __typename?: 'Payment',
  id: Scalars['ID'],
  amount: Scalars['Int'],
  proofOfPayment: Scalars['String'],
  transactionDate: Scalars['DateTime'],
  member: Member,
  stokvel: Stokvel,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

/** A connection to a list of items. */
export type PaymentConnection = {
   __typename?: 'PaymentConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<PaymentEdge>>,
  aggregate: AggregatePayment,
};

export type PaymentCreateInput = {
  id?: Maybe<Scalars['ID']>,
  amount: Scalars['Int'],
  proofOfPayment: Scalars['String'],
  transactionDate: Scalars['DateTime'],
  member: MemberCreateOneInput,
  stokvel: StokvelCreateOneWithoutPaymentInput,
};

export type PaymentCreateManyWithoutStokvelInput = {
  create?: Maybe<Array<PaymentCreateWithoutStokvelInput>>,
  connect?: Maybe<Array<PaymentWhereUniqueInput>>,
};

export type PaymentCreateWithoutStokvelInput = {
  id?: Maybe<Scalars['ID']>,
  amount: Scalars['Int'],
  proofOfPayment: Scalars['String'],
  transactionDate: Scalars['DateTime'],
  member: MemberCreateOneInput,
};

/** An edge in a connection. */
export type PaymentEdge = {
   __typename?: 'PaymentEdge',
  /** The item at the end of the edge. */
  node: Payment,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum PaymentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  ProofOfPaymentAsc = 'proofOfPayment_ASC',
  ProofOfPaymentDesc = 'proofOfPayment_DESC',
  TransactionDateAsc = 'transactionDate_ASC',
  TransactionDateDesc = 'transactionDate_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PaymentPreviousValues = {
   __typename?: 'PaymentPreviousValues',
  id: Scalars['ID'],
  amount: Scalars['Int'],
  proofOfPayment: Scalars['String'],
  transactionDate: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type PaymentScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PaymentScalarWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PaymentScalarWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PaymentScalarWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  amount?: Maybe<Scalars['Int']>,
  /** All values that are not equal to given value. */
  amount_not?: Maybe<Scalars['Int']>,
  /** All values that are contained in given list. */
  amount_in?: Maybe<Array<Scalars['Int']>>,
  /** All values that are not contained in given list. */
  amount_not_in?: Maybe<Array<Scalars['Int']>>,
  /** All values less than the given value. */
  amount_lt?: Maybe<Scalars['Int']>,
  /** All values less than or equal the given value. */
  amount_lte?: Maybe<Scalars['Int']>,
  /** All values greater than the given value. */
  amount_gt?: Maybe<Scalars['Int']>,
  /** All values greater than or equal the given value. */
  amount_gte?: Maybe<Scalars['Int']>,
  proofOfPayment?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  proofOfPayment_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  proofOfPayment_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  proofOfPayment_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  proofOfPayment_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  proofOfPayment_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  proofOfPayment_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  proofOfPayment_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  proofOfPayment_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  proofOfPayment_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  proofOfPayment_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  proofOfPayment_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  proofOfPayment_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  proofOfPayment_not_ends_with?: Maybe<Scalars['String']>,
  transactionDate?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  transactionDate_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  transactionDate_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  transactionDate_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  transactionDate_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  transactionDate_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  transactionDate_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  transactionDate_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type PaymentSubscriptionPayload = {
   __typename?: 'PaymentSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<Payment>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<PaymentPreviousValues>,
};

export type PaymentSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PaymentSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PaymentSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PaymentSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<PaymentWhereInput>,
};

export type PaymentUpdateInput = {
  amount?: Maybe<Scalars['Int']>,
  proofOfPayment?: Maybe<Scalars['String']>,
  transactionDate?: Maybe<Scalars['DateTime']>,
  member?: Maybe<MemberUpdateOneRequiredInput>,
  stokvel?: Maybe<StokvelUpdateOneRequiredWithoutPaymentInput>,
};

export type PaymentUpdateManyDataInput = {
  amount?: Maybe<Scalars['Int']>,
  proofOfPayment?: Maybe<Scalars['String']>,
  transactionDate?: Maybe<Scalars['DateTime']>,
};

export type PaymentUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Int']>,
  proofOfPayment?: Maybe<Scalars['String']>,
  transactionDate?: Maybe<Scalars['DateTime']>,
};

export type PaymentUpdateManyWithoutStokvelInput = {
  create?: Maybe<Array<PaymentCreateWithoutStokvelInput>>,
  connect?: Maybe<Array<PaymentWhereUniqueInput>>,
  set?: Maybe<Array<PaymentWhereUniqueInput>>,
  disconnect?: Maybe<Array<PaymentWhereUniqueInput>>,
  delete?: Maybe<Array<PaymentWhereUniqueInput>>,
  update?: Maybe<Array<PaymentUpdateWithWhereUniqueWithoutStokvelInput>>,
  updateMany?: Maybe<Array<PaymentUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<PaymentScalarWhereInput>>,
  upsert?: Maybe<Array<PaymentUpsertWithWhereUniqueWithoutStokvelInput>>,
};

export type PaymentUpdateManyWithWhereNestedInput = {
  where: PaymentScalarWhereInput,
  data: PaymentUpdateManyDataInput,
};

export type PaymentUpdateWithoutStokvelDataInput = {
  amount?: Maybe<Scalars['Int']>,
  proofOfPayment?: Maybe<Scalars['String']>,
  transactionDate?: Maybe<Scalars['DateTime']>,
  member?: Maybe<MemberUpdateOneRequiredInput>,
};

export type PaymentUpdateWithWhereUniqueWithoutStokvelInput = {
  where: PaymentWhereUniqueInput,
  data: PaymentUpdateWithoutStokvelDataInput,
};

export type PaymentUpsertWithWhereUniqueWithoutStokvelInput = {
  where: PaymentWhereUniqueInput,
  update: PaymentUpdateWithoutStokvelDataInput,
  create: PaymentCreateWithoutStokvelInput,
};

export type PaymentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PaymentWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PaymentWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PaymentWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  amount?: Maybe<Scalars['Int']>,
  /** All values that are not equal to given value. */
  amount_not?: Maybe<Scalars['Int']>,
  /** All values that are contained in given list. */
  amount_in?: Maybe<Array<Scalars['Int']>>,
  /** All values that are not contained in given list. */
  amount_not_in?: Maybe<Array<Scalars['Int']>>,
  /** All values less than the given value. */
  amount_lt?: Maybe<Scalars['Int']>,
  /** All values less than or equal the given value. */
  amount_lte?: Maybe<Scalars['Int']>,
  /** All values greater than the given value. */
  amount_gt?: Maybe<Scalars['Int']>,
  /** All values greater than or equal the given value. */
  amount_gte?: Maybe<Scalars['Int']>,
  proofOfPayment?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  proofOfPayment_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  proofOfPayment_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  proofOfPayment_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  proofOfPayment_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  proofOfPayment_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  proofOfPayment_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  proofOfPayment_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  proofOfPayment_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  proofOfPayment_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  proofOfPayment_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  proofOfPayment_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  proofOfPayment_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  proofOfPayment_not_ends_with?: Maybe<Scalars['String']>,
  transactionDate?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  transactionDate_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  transactionDate_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  transactionDate_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  transactionDate_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  transactionDate_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  transactionDate_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  transactionDate_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  member?: Maybe<MemberWhereInput>,
  stokvel?: Maybe<StokvelWhereInput>,
};

export type PaymentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type PersonalDetail = Node & {
   __typename?: 'PersonalDetail',
  id: Scalars['ID'],
  name: Scalars['String'],
  lastName: Scalars['String'],
  cellphone: Scalars['String'],
  email: Scalars['String'],
  address?: Maybe<Address>,
  index: PersonalDetailIndex,
  user?: Maybe<User>,
  invite?: Maybe<Array<Invite>>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};


export type PersonalDetailInviteArgs = {
  where?: Maybe<InviteWhereInput>,
  orderBy?: Maybe<InviteOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

/** A connection to a list of items. */
export type PersonalDetailConnection = {
   __typename?: 'PersonalDetailConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<PersonalDetailEdge>>,
  aggregate: AggregatePersonalDetail,
};

export type PersonalDetailCreateInput = {
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  lastName: Scalars['String'],
  cellphone: Scalars['String'],
  email: Scalars['String'],
  address?: Maybe<AddressCreateOneInput>,
  index: PersonalDetailIndexCreateOneInput,
  user?: Maybe<UserCreateOneInput>,
  invite?: Maybe<InviteCreateManyInput>,
};

export type PersonalDetailCreateOneInput = {
  create?: Maybe<PersonalDetailCreateInput>,
  connect?: Maybe<PersonalDetailWhereUniqueInput>,
};

/** An edge in a connection. */
export type PersonalDetailEdge = {
   __typename?: 'PersonalDetailEdge',
  /** The item at the end of the edge. */
  node: PersonalDetail,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export type PersonalDetailIndex = Node & {
   __typename?: 'PersonalDetailIndex',
  id: Scalars['ID'],
  name: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
};

/** A connection to a list of items. */
export type PersonalDetailIndexConnection = {
   __typename?: 'PersonalDetailIndexConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<PersonalDetailIndexEdge>>,
  aggregate: AggregatePersonalDetailIndex,
};

export type PersonalDetailIndexCreateInput = {
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
};

export type PersonalDetailIndexCreateOneInput = {
  create?: Maybe<PersonalDetailIndexCreateInput>,
  connect?: Maybe<PersonalDetailIndexWhereUniqueInput>,
};

/** An edge in a connection. */
export type PersonalDetailIndexEdge = {
   __typename?: 'PersonalDetailIndexEdge',
  /** The item at the end of the edge. */
  node: PersonalDetailIndex,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum PersonalDetailIndexOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC'
}

export type PersonalDetailIndexPreviousValues = {
   __typename?: 'PersonalDetailIndexPreviousValues',
  id: Scalars['ID'],
  name: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
};

export type PersonalDetailIndexSubscriptionPayload = {
   __typename?: 'PersonalDetailIndexSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<PersonalDetailIndex>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<PersonalDetailIndexPreviousValues>,
};

export type PersonalDetailIndexSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PersonalDetailIndexSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PersonalDetailIndexSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PersonalDetailIndexSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<PersonalDetailIndexWhereInput>,
};

export type PersonalDetailIndexUpdateDataInput = {
  name?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type PersonalDetailIndexUpdateInput = {
  name?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type PersonalDetailIndexUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type PersonalDetailIndexUpdateOneRequiredInput = {
  create?: Maybe<PersonalDetailIndexCreateInput>,
  connect?: Maybe<PersonalDetailIndexWhereUniqueInput>,
  update?: Maybe<PersonalDetailIndexUpdateDataInput>,
  upsert?: Maybe<PersonalDetailIndexUpsertNestedInput>,
};

export type PersonalDetailIndexUpsertNestedInput = {
  update: PersonalDetailIndexUpdateDataInput,
  create: PersonalDetailIndexCreateInput,
};

export type PersonalDetailIndexWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PersonalDetailIndexWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PersonalDetailIndexWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PersonalDetailIndexWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  lastName_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  lastName_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  lastName_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  lastName_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  lastName_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  lastName_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  lastName_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  lastName_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  lastName_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  lastName_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  lastName_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  lastName_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  lastName_not_ends_with?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  email_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  email_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  email_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  email_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  email_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  email_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  email_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  email_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  email_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  email_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  email_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  email_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  email_not_ends_with?: Maybe<Scalars['String']>,
};

export type PersonalDetailIndexWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
  email?: Maybe<Scalars['String']>,
};

export enum PersonalDetailOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  CellphoneAsc = 'cellphone_ASC',
  CellphoneDesc = 'cellphone_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PersonalDetailPreviousValues = {
   __typename?: 'PersonalDetailPreviousValues',
  id: Scalars['ID'],
  name: Scalars['String'],
  lastName: Scalars['String'],
  cellphone: Scalars['String'],
  email: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type PersonalDetailSubscriptionPayload = {
   __typename?: 'PersonalDetailSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<PersonalDetail>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<PersonalDetailPreviousValues>,
};

export type PersonalDetailSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PersonalDetailSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PersonalDetailSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PersonalDetailSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<PersonalDetailWhereInput>,
};

export type PersonalDetailUpdateDataInput = {
  name?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  cellphone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  address?: Maybe<AddressUpdateOneInput>,
  index?: Maybe<PersonalDetailIndexUpdateOneRequiredInput>,
  user?: Maybe<UserUpdateOneInput>,
  invite?: Maybe<InviteUpdateManyInput>,
};

export type PersonalDetailUpdateInput = {
  name?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  cellphone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  address?: Maybe<AddressUpdateOneInput>,
  index?: Maybe<PersonalDetailIndexUpdateOneRequiredInput>,
  user?: Maybe<UserUpdateOneInput>,
  invite?: Maybe<InviteUpdateManyInput>,
};

export type PersonalDetailUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  cellphone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type PersonalDetailUpdateOneRequiredInput = {
  create?: Maybe<PersonalDetailCreateInput>,
  connect?: Maybe<PersonalDetailWhereUniqueInput>,
  update?: Maybe<PersonalDetailUpdateDataInput>,
  upsert?: Maybe<PersonalDetailUpsertNestedInput>,
};

export type PersonalDetailUpsertNestedInput = {
  update: PersonalDetailUpdateDataInput,
  create: PersonalDetailCreateInput,
};

export type PersonalDetailWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PersonalDetailWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PersonalDetailWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PersonalDetailWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  lastName_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  lastName_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  lastName_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  lastName_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  lastName_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  lastName_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  lastName_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  lastName_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  lastName_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  lastName_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  lastName_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  lastName_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  lastName_not_ends_with?: Maybe<Scalars['String']>,
  cellphone?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  cellphone_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  cellphone_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  cellphone_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  cellphone_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  cellphone_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  cellphone_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  cellphone_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  cellphone_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  cellphone_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  cellphone_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  cellphone_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  cellphone_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  cellphone_not_ends_with?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  email_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  email_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  email_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  email_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  email_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  email_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  email_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  email_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  email_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  email_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  email_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  email_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  email_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  address?: Maybe<AddressWhereInput>,
  index?: Maybe<PersonalDetailIndexWhereInput>,
  user?: Maybe<UserWhereInput>,
  invite_every?: Maybe<InviteWhereInput>,
  invite_some?: Maybe<InviteWhereInput>,
  invite_none?: Maybe<InviteWhereInput>,
};

export type PersonalDetailWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
  cellphone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type Query = {
   __typename?: 'Query',
  payments: Array<Maybe<Payment>>,
  bankAccounts: Array<Maybe<BankAccount>>,
  messages: Array<Maybe<Message>>,
  constitutions: Array<Maybe<Constitution>>,
  events: Array<Maybe<Event>>,
  addresses: Array<Maybe<Address>>,
  users: Array<Maybe<User>>,
  personalDetailIndexes: Array<Maybe<PersonalDetailIndex>>,
  contributions: Array<Maybe<Contribution>>,
  stokvels: Array<Maybe<Stokvel>>,
  invites: Array<Maybe<Invite>>,
  members: Array<Maybe<Member>>,
  personalDetails: Array<Maybe<PersonalDetail>>,
  payment?: Maybe<Payment>,
  bankAccount?: Maybe<BankAccount>,
  message?: Maybe<Message>,
  constitution?: Maybe<Constitution>,
  event?: Maybe<Event>,
  address?: Maybe<Address>,
  user?: Maybe<User>,
  personalDetailIndex?: Maybe<PersonalDetailIndex>,
  contribution?: Maybe<Contribution>,
  stokvel?: Maybe<Stokvel>,
  invite?: Maybe<Invite>,
  member?: Maybe<Member>,
  personalDetail?: Maybe<PersonalDetail>,
  paymentsConnection: PaymentConnection,
  bankAccountsConnection: BankAccountConnection,
  messagesConnection: MessageConnection,
  constitutionsConnection: ConstitutionConnection,
  eventsConnection: EventConnection,
  addressesConnection: AddressConnection,
  usersConnection: UserConnection,
  personalDetailIndexesConnection: PersonalDetailIndexConnection,
  contributionsConnection: ContributionConnection,
  stokvelsConnection: StokvelConnection,
  invitesConnection: InviteConnection,
  membersConnection: MemberConnection,
  personalDetailsConnection: PersonalDetailConnection,
  /** Fetches an object given its ID */
  node?: Maybe<Node>,
};


export type QueryPaymentsArgs = {
  where?: Maybe<PaymentWhereInput>,
  orderBy?: Maybe<PaymentOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryBankAccountsArgs = {
  where?: Maybe<BankAccountWhereInput>,
  orderBy?: Maybe<BankAccountOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryMessagesArgs = {
  where?: Maybe<MessageWhereInput>,
  orderBy?: Maybe<MessageOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryConstitutionsArgs = {
  where?: Maybe<ConstitutionWhereInput>,
  orderBy?: Maybe<ConstitutionOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryEventsArgs = {
  where?: Maybe<EventWhereInput>,
  orderBy?: Maybe<EventOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryAddressesArgs = {
  where?: Maybe<AddressWhereInput>,
  orderBy?: Maybe<AddressOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>,
  orderBy?: Maybe<UserOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryPersonalDetailIndexesArgs = {
  where?: Maybe<PersonalDetailIndexWhereInput>,
  orderBy?: Maybe<PersonalDetailIndexOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryContributionsArgs = {
  where?: Maybe<ContributionWhereInput>,
  orderBy?: Maybe<ContributionOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryStokvelsArgs = {
  where?: Maybe<StokvelWhereInput>,
  orderBy?: Maybe<StokvelOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryInvitesArgs = {
  where?: Maybe<InviteWhereInput>,
  orderBy?: Maybe<InviteOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryMembersArgs = {
  where?: Maybe<MemberWhereInput>,
  orderBy?: Maybe<MemberOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryPersonalDetailsArgs = {
  where?: Maybe<PersonalDetailWhereInput>,
  orderBy?: Maybe<PersonalDetailOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryPaymentArgs = {
  where: PaymentWhereUniqueInput
};


export type QueryBankAccountArgs = {
  where: BankAccountWhereUniqueInput
};


export type QueryMessageArgs = {
  where: MessageWhereUniqueInput
};


export type QueryConstitutionArgs = {
  where: ConstitutionWhereUniqueInput
};


export type QueryEventArgs = {
  where: EventWhereUniqueInput
};


export type QueryAddressArgs = {
  where: AddressWhereUniqueInput
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput
};


export type QueryPersonalDetailIndexArgs = {
  where: PersonalDetailIndexWhereUniqueInput
};


export type QueryContributionArgs = {
  where: ContributionWhereUniqueInput
};


export type QueryStokvelArgs = {
  where: StokvelWhereUniqueInput
};


export type QueryInviteArgs = {
  where: InviteWhereUniqueInput
};


export type QueryMemberArgs = {
  where: MemberWhereUniqueInput
};


export type QueryPersonalDetailArgs = {
  where: PersonalDetailWhereUniqueInput
};


export type QueryPaymentsConnectionArgs = {
  where?: Maybe<PaymentWhereInput>,
  orderBy?: Maybe<PaymentOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryBankAccountsConnectionArgs = {
  where?: Maybe<BankAccountWhereInput>,
  orderBy?: Maybe<BankAccountOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryMessagesConnectionArgs = {
  where?: Maybe<MessageWhereInput>,
  orderBy?: Maybe<MessageOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryConstitutionsConnectionArgs = {
  where?: Maybe<ConstitutionWhereInput>,
  orderBy?: Maybe<ConstitutionOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryEventsConnectionArgs = {
  where?: Maybe<EventWhereInput>,
  orderBy?: Maybe<EventOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryAddressesConnectionArgs = {
  where?: Maybe<AddressWhereInput>,
  orderBy?: Maybe<AddressOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryUsersConnectionArgs = {
  where?: Maybe<UserWhereInput>,
  orderBy?: Maybe<UserOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryPersonalDetailIndexesConnectionArgs = {
  where?: Maybe<PersonalDetailIndexWhereInput>,
  orderBy?: Maybe<PersonalDetailIndexOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryContributionsConnectionArgs = {
  where?: Maybe<ContributionWhereInput>,
  orderBy?: Maybe<ContributionOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryStokvelsConnectionArgs = {
  where?: Maybe<StokvelWhereInput>,
  orderBy?: Maybe<StokvelOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryInvitesConnectionArgs = {
  where?: Maybe<InviteWhereInput>,
  orderBy?: Maybe<InviteOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryMembersConnectionArgs = {
  where?: Maybe<MemberWhereInput>,
  orderBy?: Maybe<MemberOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryPersonalDetailsConnectionArgs = {
  where?: Maybe<PersonalDetailWhereInput>,
  orderBy?: Maybe<PersonalDetailOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryNodeArgs = {
  id: Scalars['ID']
};

export type Stokvel = Node & {
   __typename?: 'Stokvel',
  id: Scalars['ID'],
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  constitution?: Maybe<Constitution>,
  administrator?: Maybe<User>,
  treasurer?: Maybe<User>,
  chairman?: Maybe<User>,
  secretary?: Maybe<User>,
  members?: Maybe<Array<Member>>,
  messages?: Maybe<Array<Message>>,
  name: Scalars['String'],
  planId: Scalars['String'],
  bankAccount?: Maybe<Array<BankAccount>>,
  contribution?: Maybe<Array<Contribution>>,
  event?: Maybe<Array<Event>>,
  payment?: Maybe<Array<Payment>>,
  startDate: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};


export type StokvelMembersArgs = {
  where?: Maybe<MemberWhereInput>,
  orderBy?: Maybe<MemberOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type StokvelMessagesArgs = {
  where?: Maybe<MessageWhereInput>,
  orderBy?: Maybe<MessageOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type StokvelBankAccountArgs = {
  where?: Maybe<BankAccountWhereInput>,
  orderBy?: Maybe<BankAccountOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type StokvelContributionArgs = {
  where?: Maybe<ContributionWhereInput>,
  orderBy?: Maybe<ContributionOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type StokvelEventArgs = {
  where?: Maybe<EventWhereInput>,
  orderBy?: Maybe<EventOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type StokvelPaymentArgs = {
  where?: Maybe<PaymentWhereInput>,
  orderBy?: Maybe<PaymentOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

/** A connection to a list of items. */
export type StokvelConnection = {
   __typename?: 'StokvelConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<StokvelEdge>>,
  aggregate: AggregateStokvel,
};

export type StokvelCreateInput = {
  id?: Maybe<Scalars['ID']>,
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  planId: Scalars['String'],
  startDate: Scalars['DateTime'],
  constitution?: Maybe<ConstitutionCreateOneWithoutStokvelInput>,
  administrator?: Maybe<UserCreateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserCreateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserCreateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserCreateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberCreateManyWithoutStokvelInput>,
  messages?: Maybe<MessageCreateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountCreateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionCreateManyWithoutStokvelInput>,
  event?: Maybe<EventCreateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentCreateManyWithoutStokvelInput>,
};

export type StokvelCreateManyWithoutAdministratorInput = {
  create?: Maybe<Array<StokvelCreateWithoutAdministratorInput>>,
  connect?: Maybe<Array<StokvelWhereUniqueInput>>,
};

export type StokvelCreateManyWithoutChairmanInput = {
  create?: Maybe<Array<StokvelCreateWithoutChairmanInput>>,
  connect?: Maybe<Array<StokvelWhereUniqueInput>>,
};

export type StokvelCreateManyWithoutSecretaryInput = {
  create?: Maybe<Array<StokvelCreateWithoutSecretaryInput>>,
  connect?: Maybe<Array<StokvelWhereUniqueInput>>,
};

export type StokvelCreateManyWithoutTreasurerInput = {
  create?: Maybe<Array<StokvelCreateWithoutTreasurerInput>>,
  connect?: Maybe<Array<StokvelWhereUniqueInput>>,
};

export type StokvelCreateOneInput = {
  create?: Maybe<StokvelCreateInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
};

export type StokvelCreateOneWithoutBankAccountInput = {
  create?: Maybe<StokvelCreateWithoutBankAccountInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
};

export type StokvelCreateOneWithoutConstitutionInput = {
  create?: Maybe<StokvelCreateWithoutConstitutionInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
};

export type StokvelCreateOneWithoutContributionInput = {
  create?: Maybe<StokvelCreateWithoutContributionInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
};

export type StokvelCreateOneWithoutEventInput = {
  create?: Maybe<StokvelCreateWithoutEventInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
};

export type StokvelCreateOneWithoutMembersInput = {
  create?: Maybe<StokvelCreateWithoutMembersInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
};

export type StokvelCreateOneWithoutMessagesInput = {
  create?: Maybe<StokvelCreateWithoutMessagesInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
};

export type StokvelCreateOneWithoutPaymentInput = {
  create?: Maybe<StokvelCreateWithoutPaymentInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
};

export type StokvelCreateWithoutAdministratorInput = {
  id?: Maybe<Scalars['ID']>,
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  planId: Scalars['String'],
  startDate: Scalars['DateTime'],
  constitution?: Maybe<ConstitutionCreateOneWithoutStokvelInput>,
  treasurer?: Maybe<UserCreateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserCreateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserCreateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberCreateManyWithoutStokvelInput>,
  messages?: Maybe<MessageCreateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountCreateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionCreateManyWithoutStokvelInput>,
  event?: Maybe<EventCreateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentCreateManyWithoutStokvelInput>,
};

export type StokvelCreateWithoutBankAccountInput = {
  id?: Maybe<Scalars['ID']>,
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  planId: Scalars['String'],
  startDate: Scalars['DateTime'],
  constitution?: Maybe<ConstitutionCreateOneWithoutStokvelInput>,
  administrator?: Maybe<UserCreateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserCreateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserCreateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserCreateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberCreateManyWithoutStokvelInput>,
  messages?: Maybe<MessageCreateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionCreateManyWithoutStokvelInput>,
  event?: Maybe<EventCreateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentCreateManyWithoutStokvelInput>,
};

export type StokvelCreateWithoutChairmanInput = {
  id?: Maybe<Scalars['ID']>,
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  planId: Scalars['String'],
  startDate: Scalars['DateTime'],
  constitution?: Maybe<ConstitutionCreateOneWithoutStokvelInput>,
  administrator?: Maybe<UserCreateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserCreateOneWithoutTreasurerOfInput>,
  secretary?: Maybe<UserCreateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberCreateManyWithoutStokvelInput>,
  messages?: Maybe<MessageCreateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountCreateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionCreateManyWithoutStokvelInput>,
  event?: Maybe<EventCreateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentCreateManyWithoutStokvelInput>,
};

export type StokvelCreateWithoutConstitutionInput = {
  id?: Maybe<Scalars['ID']>,
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  planId: Scalars['String'],
  startDate: Scalars['DateTime'],
  administrator?: Maybe<UserCreateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserCreateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserCreateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserCreateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberCreateManyWithoutStokvelInput>,
  messages?: Maybe<MessageCreateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountCreateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionCreateManyWithoutStokvelInput>,
  event?: Maybe<EventCreateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentCreateManyWithoutStokvelInput>,
};

export type StokvelCreateWithoutContributionInput = {
  id?: Maybe<Scalars['ID']>,
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  planId: Scalars['String'],
  startDate: Scalars['DateTime'],
  constitution?: Maybe<ConstitutionCreateOneWithoutStokvelInput>,
  administrator?: Maybe<UserCreateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserCreateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserCreateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserCreateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberCreateManyWithoutStokvelInput>,
  messages?: Maybe<MessageCreateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountCreateManyWithoutStokvelInput>,
  event?: Maybe<EventCreateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentCreateManyWithoutStokvelInput>,
};

export type StokvelCreateWithoutEventInput = {
  id?: Maybe<Scalars['ID']>,
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  planId: Scalars['String'],
  startDate: Scalars['DateTime'],
  constitution?: Maybe<ConstitutionCreateOneWithoutStokvelInput>,
  administrator?: Maybe<UserCreateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserCreateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserCreateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserCreateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberCreateManyWithoutStokvelInput>,
  messages?: Maybe<MessageCreateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountCreateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionCreateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentCreateManyWithoutStokvelInput>,
};

export type StokvelCreateWithoutMembersInput = {
  id?: Maybe<Scalars['ID']>,
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  planId: Scalars['String'],
  startDate: Scalars['DateTime'],
  constitution?: Maybe<ConstitutionCreateOneWithoutStokvelInput>,
  administrator?: Maybe<UserCreateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserCreateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserCreateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserCreateOneWithoutSecretaryOfInput>,
  messages?: Maybe<MessageCreateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountCreateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionCreateManyWithoutStokvelInput>,
  event?: Maybe<EventCreateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentCreateManyWithoutStokvelInput>,
};

export type StokvelCreateWithoutMessagesInput = {
  id?: Maybe<Scalars['ID']>,
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  planId: Scalars['String'],
  startDate: Scalars['DateTime'],
  constitution?: Maybe<ConstitutionCreateOneWithoutStokvelInput>,
  administrator?: Maybe<UserCreateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserCreateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserCreateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserCreateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberCreateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountCreateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionCreateManyWithoutStokvelInput>,
  event?: Maybe<EventCreateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentCreateManyWithoutStokvelInput>,
};

export type StokvelCreateWithoutPaymentInput = {
  id?: Maybe<Scalars['ID']>,
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  planId: Scalars['String'],
  startDate: Scalars['DateTime'],
  constitution?: Maybe<ConstitutionCreateOneWithoutStokvelInput>,
  administrator?: Maybe<UserCreateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserCreateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserCreateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserCreateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberCreateManyWithoutStokvelInput>,
  messages?: Maybe<MessageCreateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountCreateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionCreateManyWithoutStokvelInput>,
  event?: Maybe<EventCreateManyWithoutStokvelInput>,
};

export type StokvelCreateWithoutSecretaryInput = {
  id?: Maybe<Scalars['ID']>,
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  planId: Scalars['String'],
  startDate: Scalars['DateTime'],
  constitution?: Maybe<ConstitutionCreateOneWithoutStokvelInput>,
  administrator?: Maybe<UserCreateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserCreateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserCreateOneWithoutChairmanOfInput>,
  members?: Maybe<MemberCreateManyWithoutStokvelInput>,
  messages?: Maybe<MessageCreateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountCreateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionCreateManyWithoutStokvelInput>,
  event?: Maybe<EventCreateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentCreateManyWithoutStokvelInput>,
};

export type StokvelCreateWithoutTreasurerInput = {
  id?: Maybe<Scalars['ID']>,
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  planId: Scalars['String'],
  startDate: Scalars['DateTime'],
  constitution?: Maybe<ConstitutionCreateOneWithoutStokvelInput>,
  administrator?: Maybe<UserCreateOneWithoutAdministratorOfInput>,
  chairman?: Maybe<UserCreateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserCreateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberCreateManyWithoutStokvelInput>,
  messages?: Maybe<MessageCreateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountCreateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionCreateManyWithoutStokvelInput>,
  event?: Maybe<EventCreateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentCreateManyWithoutStokvelInput>,
};

/** An edge in a connection. */
export type StokvelEdge = {
   __typename?: 'StokvelEdge',
  /** The item at the end of the edge. */
  node: Stokvel,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum StokvelOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PictureAsc = 'picture_ASC',
  PictureDesc = 'picture_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  LongDescriptionAsc = 'longDescription_ASC',
  LongDescriptionDesc = 'longDescription_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PlanIdAsc = 'planId_ASC',
  PlanIdDesc = 'planId_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type StokvelPreviousValues = {
   __typename?: 'StokvelPreviousValues',
  id: Scalars['ID'],
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  planId: Scalars['String'],
  startDate: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type StokvelScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<StokvelScalarWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<StokvelScalarWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<StokvelScalarWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  picture?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  picture_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  picture_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  picture_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  picture_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  picture_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  picture_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  picture_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  picture_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  picture_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  picture_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  picture_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  picture_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  picture_not_ends_with?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  shortDescription_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  shortDescription_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  shortDescription_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  shortDescription_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  shortDescription_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  shortDescription_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  shortDescription_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  shortDescription_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  shortDescription_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  shortDescription_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  shortDescription_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  shortDescription_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  shortDescription_not_ends_with?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  longDescription_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  longDescription_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  longDescription_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  longDescription_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  longDescription_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  longDescription_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  longDescription_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  longDescription_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  longDescription_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  longDescription_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  longDescription_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  longDescription_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  longDescription_not_ends_with?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  planId_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  planId_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  planId_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  planId_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  planId_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  planId_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  planId_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  planId_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  planId_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  planId_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  planId_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  planId_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  planId_not_ends_with?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  startDate_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  startDate_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  startDate_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  startDate_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  startDate_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  startDate_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type StokvelSubscriptionPayload = {
   __typename?: 'StokvelSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<Stokvel>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<StokvelPreviousValues>,
};

export type StokvelSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<StokvelSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<StokvelSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<StokvelSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<StokvelWhereInput>,
};

export type StokvelUpdateDataInput = {
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  constitution?: Maybe<ConstitutionUpdateOneWithoutStokvelInput>,
  administrator?: Maybe<UserUpdateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserUpdateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserUpdateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserUpdateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberUpdateManyWithoutStokvelInput>,
  messages?: Maybe<MessageUpdateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountUpdateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionUpdateManyWithoutStokvelInput>,
  event?: Maybe<EventUpdateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentUpdateManyWithoutStokvelInput>,
};

export type StokvelUpdateInput = {
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  constitution?: Maybe<ConstitutionUpdateOneWithoutStokvelInput>,
  administrator?: Maybe<UserUpdateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserUpdateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserUpdateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserUpdateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberUpdateManyWithoutStokvelInput>,
  messages?: Maybe<MessageUpdateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountUpdateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionUpdateManyWithoutStokvelInput>,
  event?: Maybe<EventUpdateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentUpdateManyWithoutStokvelInput>,
};

export type StokvelUpdateManyDataInput = {
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
};

export type StokvelUpdateManyMutationInput = {
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
};

export type StokvelUpdateManyWithoutAdministratorInput = {
  create?: Maybe<Array<StokvelCreateWithoutAdministratorInput>>,
  connect?: Maybe<Array<StokvelWhereUniqueInput>>,
  set?: Maybe<Array<StokvelWhereUniqueInput>>,
  disconnect?: Maybe<Array<StokvelWhereUniqueInput>>,
  delete?: Maybe<Array<StokvelWhereUniqueInput>>,
  update?: Maybe<Array<StokvelUpdateWithWhereUniqueWithoutAdministratorInput>>,
  updateMany?: Maybe<Array<StokvelUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<StokvelScalarWhereInput>>,
  upsert?: Maybe<Array<StokvelUpsertWithWhereUniqueWithoutAdministratorInput>>,
};

export type StokvelUpdateManyWithoutChairmanInput = {
  create?: Maybe<Array<StokvelCreateWithoutChairmanInput>>,
  connect?: Maybe<Array<StokvelWhereUniqueInput>>,
  set?: Maybe<Array<StokvelWhereUniqueInput>>,
  disconnect?: Maybe<Array<StokvelWhereUniqueInput>>,
  delete?: Maybe<Array<StokvelWhereUniqueInput>>,
  update?: Maybe<Array<StokvelUpdateWithWhereUniqueWithoutChairmanInput>>,
  updateMany?: Maybe<Array<StokvelUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<StokvelScalarWhereInput>>,
  upsert?: Maybe<Array<StokvelUpsertWithWhereUniqueWithoutChairmanInput>>,
};

export type StokvelUpdateManyWithoutSecretaryInput = {
  create?: Maybe<Array<StokvelCreateWithoutSecretaryInput>>,
  connect?: Maybe<Array<StokvelWhereUniqueInput>>,
  set?: Maybe<Array<StokvelWhereUniqueInput>>,
  disconnect?: Maybe<Array<StokvelWhereUniqueInput>>,
  delete?: Maybe<Array<StokvelWhereUniqueInput>>,
  update?: Maybe<Array<StokvelUpdateWithWhereUniqueWithoutSecretaryInput>>,
  updateMany?: Maybe<Array<StokvelUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<StokvelScalarWhereInput>>,
  upsert?: Maybe<Array<StokvelUpsertWithWhereUniqueWithoutSecretaryInput>>,
};

export type StokvelUpdateManyWithoutTreasurerInput = {
  create?: Maybe<Array<StokvelCreateWithoutTreasurerInput>>,
  connect?: Maybe<Array<StokvelWhereUniqueInput>>,
  set?: Maybe<Array<StokvelWhereUniqueInput>>,
  disconnect?: Maybe<Array<StokvelWhereUniqueInput>>,
  delete?: Maybe<Array<StokvelWhereUniqueInput>>,
  update?: Maybe<Array<StokvelUpdateWithWhereUniqueWithoutTreasurerInput>>,
  updateMany?: Maybe<Array<StokvelUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<StokvelScalarWhereInput>>,
  upsert?: Maybe<Array<StokvelUpsertWithWhereUniqueWithoutTreasurerInput>>,
};

export type StokvelUpdateManyWithWhereNestedInput = {
  where: StokvelScalarWhereInput,
  data: StokvelUpdateManyDataInput,
};

export type StokvelUpdateOneRequiredInput = {
  create?: Maybe<StokvelCreateInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
  update?: Maybe<StokvelUpdateDataInput>,
  upsert?: Maybe<StokvelUpsertNestedInput>,
};

export type StokvelUpdateOneRequiredWithoutContributionInput = {
  create?: Maybe<StokvelCreateWithoutContributionInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
  update?: Maybe<StokvelUpdateWithoutContributionDataInput>,
  upsert?: Maybe<StokvelUpsertWithoutContributionInput>,
};

export type StokvelUpdateOneRequiredWithoutPaymentInput = {
  create?: Maybe<StokvelCreateWithoutPaymentInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
  update?: Maybe<StokvelUpdateWithoutPaymentDataInput>,
  upsert?: Maybe<StokvelUpsertWithoutPaymentInput>,
};

export type StokvelUpdateOneWithoutBankAccountInput = {
  create?: Maybe<StokvelCreateWithoutBankAccountInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<StokvelUpdateWithoutBankAccountDataInput>,
  upsert?: Maybe<StokvelUpsertWithoutBankAccountInput>,
};

export type StokvelUpdateOneWithoutConstitutionInput = {
  create?: Maybe<StokvelCreateWithoutConstitutionInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<StokvelUpdateWithoutConstitutionDataInput>,
  upsert?: Maybe<StokvelUpsertWithoutConstitutionInput>,
};

export type StokvelUpdateOneWithoutEventInput = {
  create?: Maybe<StokvelCreateWithoutEventInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<StokvelUpdateWithoutEventDataInput>,
  upsert?: Maybe<StokvelUpsertWithoutEventInput>,
};

export type StokvelUpdateOneWithoutMembersInput = {
  create?: Maybe<StokvelCreateWithoutMembersInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<StokvelUpdateWithoutMembersDataInput>,
  upsert?: Maybe<StokvelUpsertWithoutMembersInput>,
};

export type StokvelUpdateOneWithoutMessagesInput = {
  create?: Maybe<StokvelCreateWithoutMessagesInput>,
  connect?: Maybe<StokvelWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<StokvelUpdateWithoutMessagesDataInput>,
  upsert?: Maybe<StokvelUpsertWithoutMessagesInput>,
};

export type StokvelUpdateWithoutAdministratorDataInput = {
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  constitution?: Maybe<ConstitutionUpdateOneWithoutStokvelInput>,
  treasurer?: Maybe<UserUpdateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserUpdateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserUpdateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberUpdateManyWithoutStokvelInput>,
  messages?: Maybe<MessageUpdateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountUpdateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionUpdateManyWithoutStokvelInput>,
  event?: Maybe<EventUpdateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentUpdateManyWithoutStokvelInput>,
};

export type StokvelUpdateWithoutBankAccountDataInput = {
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  constitution?: Maybe<ConstitutionUpdateOneWithoutStokvelInput>,
  administrator?: Maybe<UserUpdateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserUpdateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserUpdateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserUpdateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberUpdateManyWithoutStokvelInput>,
  messages?: Maybe<MessageUpdateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionUpdateManyWithoutStokvelInput>,
  event?: Maybe<EventUpdateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentUpdateManyWithoutStokvelInput>,
};

export type StokvelUpdateWithoutChairmanDataInput = {
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  constitution?: Maybe<ConstitutionUpdateOneWithoutStokvelInput>,
  administrator?: Maybe<UserUpdateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserUpdateOneWithoutTreasurerOfInput>,
  secretary?: Maybe<UserUpdateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberUpdateManyWithoutStokvelInput>,
  messages?: Maybe<MessageUpdateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountUpdateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionUpdateManyWithoutStokvelInput>,
  event?: Maybe<EventUpdateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentUpdateManyWithoutStokvelInput>,
};

export type StokvelUpdateWithoutConstitutionDataInput = {
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  administrator?: Maybe<UserUpdateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserUpdateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserUpdateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserUpdateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberUpdateManyWithoutStokvelInput>,
  messages?: Maybe<MessageUpdateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountUpdateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionUpdateManyWithoutStokvelInput>,
  event?: Maybe<EventUpdateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentUpdateManyWithoutStokvelInput>,
};

export type StokvelUpdateWithoutContributionDataInput = {
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  constitution?: Maybe<ConstitutionUpdateOneWithoutStokvelInput>,
  administrator?: Maybe<UserUpdateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserUpdateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserUpdateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserUpdateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberUpdateManyWithoutStokvelInput>,
  messages?: Maybe<MessageUpdateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountUpdateManyWithoutStokvelInput>,
  event?: Maybe<EventUpdateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentUpdateManyWithoutStokvelInput>,
};

export type StokvelUpdateWithoutEventDataInput = {
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  constitution?: Maybe<ConstitutionUpdateOneWithoutStokvelInput>,
  administrator?: Maybe<UserUpdateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserUpdateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserUpdateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserUpdateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberUpdateManyWithoutStokvelInput>,
  messages?: Maybe<MessageUpdateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountUpdateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionUpdateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentUpdateManyWithoutStokvelInput>,
};

export type StokvelUpdateWithoutMembersDataInput = {
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  constitution?: Maybe<ConstitutionUpdateOneWithoutStokvelInput>,
  administrator?: Maybe<UserUpdateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserUpdateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserUpdateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserUpdateOneWithoutSecretaryOfInput>,
  messages?: Maybe<MessageUpdateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountUpdateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionUpdateManyWithoutStokvelInput>,
  event?: Maybe<EventUpdateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentUpdateManyWithoutStokvelInput>,
};

export type StokvelUpdateWithoutMessagesDataInput = {
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  constitution?: Maybe<ConstitutionUpdateOneWithoutStokvelInput>,
  administrator?: Maybe<UserUpdateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserUpdateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserUpdateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserUpdateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberUpdateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountUpdateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionUpdateManyWithoutStokvelInput>,
  event?: Maybe<EventUpdateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentUpdateManyWithoutStokvelInput>,
};

export type StokvelUpdateWithoutPaymentDataInput = {
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  constitution?: Maybe<ConstitutionUpdateOneWithoutStokvelInput>,
  administrator?: Maybe<UserUpdateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserUpdateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserUpdateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserUpdateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberUpdateManyWithoutStokvelInput>,
  messages?: Maybe<MessageUpdateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountUpdateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionUpdateManyWithoutStokvelInput>,
  event?: Maybe<EventUpdateManyWithoutStokvelInput>,
};

export type StokvelUpdateWithoutSecretaryDataInput = {
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  constitution?: Maybe<ConstitutionUpdateOneWithoutStokvelInput>,
  administrator?: Maybe<UserUpdateOneWithoutAdministratorOfInput>,
  treasurer?: Maybe<UserUpdateOneWithoutTreasurerOfInput>,
  chairman?: Maybe<UserUpdateOneWithoutChairmanOfInput>,
  members?: Maybe<MemberUpdateManyWithoutStokvelInput>,
  messages?: Maybe<MessageUpdateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountUpdateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionUpdateManyWithoutStokvelInput>,
  event?: Maybe<EventUpdateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentUpdateManyWithoutStokvelInput>,
};

export type StokvelUpdateWithoutTreasurerDataInput = {
  picture?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  constitution?: Maybe<ConstitutionUpdateOneWithoutStokvelInput>,
  administrator?: Maybe<UserUpdateOneWithoutAdministratorOfInput>,
  chairman?: Maybe<UserUpdateOneWithoutChairmanOfInput>,
  secretary?: Maybe<UserUpdateOneWithoutSecretaryOfInput>,
  members?: Maybe<MemberUpdateManyWithoutStokvelInput>,
  messages?: Maybe<MessageUpdateManyWithoutStokvelInput>,
  bankAccount?: Maybe<BankAccountUpdateManyWithoutStokvelInput>,
  contribution?: Maybe<ContributionUpdateManyWithoutStokvelInput>,
  event?: Maybe<EventUpdateManyWithoutStokvelInput>,
  payment?: Maybe<PaymentUpdateManyWithoutStokvelInput>,
};

export type StokvelUpdateWithWhereUniqueWithoutAdministratorInput = {
  where: StokvelWhereUniqueInput,
  data: StokvelUpdateWithoutAdministratorDataInput,
};

export type StokvelUpdateWithWhereUniqueWithoutChairmanInput = {
  where: StokvelWhereUniqueInput,
  data: StokvelUpdateWithoutChairmanDataInput,
};

export type StokvelUpdateWithWhereUniqueWithoutSecretaryInput = {
  where: StokvelWhereUniqueInput,
  data: StokvelUpdateWithoutSecretaryDataInput,
};

export type StokvelUpdateWithWhereUniqueWithoutTreasurerInput = {
  where: StokvelWhereUniqueInput,
  data: StokvelUpdateWithoutTreasurerDataInput,
};

export type StokvelUpsertNestedInput = {
  update: StokvelUpdateDataInput,
  create: StokvelCreateInput,
};

export type StokvelUpsertWithoutBankAccountInput = {
  update: StokvelUpdateWithoutBankAccountDataInput,
  create: StokvelCreateWithoutBankAccountInput,
};

export type StokvelUpsertWithoutConstitutionInput = {
  update: StokvelUpdateWithoutConstitutionDataInput,
  create: StokvelCreateWithoutConstitutionInput,
};

export type StokvelUpsertWithoutContributionInput = {
  update: StokvelUpdateWithoutContributionDataInput,
  create: StokvelCreateWithoutContributionInput,
};

export type StokvelUpsertWithoutEventInput = {
  update: StokvelUpdateWithoutEventDataInput,
  create: StokvelCreateWithoutEventInput,
};

export type StokvelUpsertWithoutMembersInput = {
  update: StokvelUpdateWithoutMembersDataInput,
  create: StokvelCreateWithoutMembersInput,
};

export type StokvelUpsertWithoutMessagesInput = {
  update: StokvelUpdateWithoutMessagesDataInput,
  create: StokvelCreateWithoutMessagesInput,
};

export type StokvelUpsertWithoutPaymentInput = {
  update: StokvelUpdateWithoutPaymentDataInput,
  create: StokvelCreateWithoutPaymentInput,
};

export type StokvelUpsertWithWhereUniqueWithoutAdministratorInput = {
  where: StokvelWhereUniqueInput,
  update: StokvelUpdateWithoutAdministratorDataInput,
  create: StokvelCreateWithoutAdministratorInput,
};

export type StokvelUpsertWithWhereUniqueWithoutChairmanInput = {
  where: StokvelWhereUniqueInput,
  update: StokvelUpdateWithoutChairmanDataInput,
  create: StokvelCreateWithoutChairmanInput,
};

export type StokvelUpsertWithWhereUniqueWithoutSecretaryInput = {
  where: StokvelWhereUniqueInput,
  update: StokvelUpdateWithoutSecretaryDataInput,
  create: StokvelCreateWithoutSecretaryInput,
};

export type StokvelUpsertWithWhereUniqueWithoutTreasurerInput = {
  where: StokvelWhereUniqueInput,
  update: StokvelUpdateWithoutTreasurerDataInput,
  create: StokvelCreateWithoutTreasurerInput,
};

export type StokvelWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<StokvelWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<StokvelWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<StokvelWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  picture?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  picture_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  picture_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  picture_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  picture_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  picture_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  picture_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  picture_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  picture_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  picture_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  picture_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  picture_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  picture_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  picture_not_ends_with?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  shortDescription_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  shortDescription_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  shortDescription_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  shortDescription_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  shortDescription_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  shortDescription_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  shortDescription_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  shortDescription_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  shortDescription_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  shortDescription_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  shortDescription_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  shortDescription_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  shortDescription_not_ends_with?: Maybe<Scalars['String']>,
  longDescription?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  longDescription_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  longDescription_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  longDescription_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  longDescription_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  longDescription_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  longDescription_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  longDescription_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  longDescription_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  longDescription_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  longDescription_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  longDescription_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  longDescription_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  longDescription_not_ends_with?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  planId?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  planId_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  planId_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  planId_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  planId_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  planId_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  planId_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  planId_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  planId_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  planId_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  planId_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  planId_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  planId_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  planId_not_ends_with?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  startDate_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  startDate_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  startDate_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  startDate_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  startDate_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  startDate_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  constitution?: Maybe<ConstitutionWhereInput>,
  administrator?: Maybe<UserWhereInput>,
  treasurer?: Maybe<UserWhereInput>,
  chairman?: Maybe<UserWhereInput>,
  secretary?: Maybe<UserWhereInput>,
  members_every?: Maybe<MemberWhereInput>,
  members_some?: Maybe<MemberWhereInput>,
  members_none?: Maybe<MemberWhereInput>,
  messages_every?: Maybe<MessageWhereInput>,
  messages_some?: Maybe<MessageWhereInput>,
  messages_none?: Maybe<MessageWhereInput>,
  bankAccount_every?: Maybe<BankAccountWhereInput>,
  bankAccount_some?: Maybe<BankAccountWhereInput>,
  bankAccount_none?: Maybe<BankAccountWhereInput>,
  contribution_every?: Maybe<ContributionWhereInput>,
  contribution_some?: Maybe<ContributionWhereInput>,
  contribution_none?: Maybe<ContributionWhereInput>,
  event_every?: Maybe<EventWhereInput>,
  event_some?: Maybe<EventWhereInput>,
  event_none?: Maybe<EventWhereInput>,
  payment_every?: Maybe<PaymentWhereInput>,
  payment_some?: Maybe<PaymentWhereInput>,
  payment_none?: Maybe<PaymentWhereInput>,
};

export type StokvelWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type Subscription = {
   __typename?: 'Subscription',
  payment?: Maybe<PaymentSubscriptionPayload>,
  bankAccount?: Maybe<BankAccountSubscriptionPayload>,
  message?: Maybe<MessageSubscriptionPayload>,
  constitution?: Maybe<ConstitutionSubscriptionPayload>,
  event?: Maybe<EventSubscriptionPayload>,
  address?: Maybe<AddressSubscriptionPayload>,
  user?: Maybe<UserSubscriptionPayload>,
  personalDetailIndex?: Maybe<PersonalDetailIndexSubscriptionPayload>,
  contribution?: Maybe<ContributionSubscriptionPayload>,
  stokvel?: Maybe<StokvelSubscriptionPayload>,
  invite?: Maybe<InviteSubscriptionPayload>,
  member?: Maybe<MemberSubscriptionPayload>,
  personalDetail?: Maybe<PersonalDetailSubscriptionPayload>,
};


export type SubscriptionPaymentArgs = {
  where?: Maybe<PaymentSubscriptionWhereInput>
};


export type SubscriptionBankAccountArgs = {
  where?: Maybe<BankAccountSubscriptionWhereInput>
};


export type SubscriptionMessageArgs = {
  where?: Maybe<MessageSubscriptionWhereInput>
};


export type SubscriptionConstitutionArgs = {
  where?: Maybe<ConstitutionSubscriptionWhereInput>
};


export type SubscriptionEventArgs = {
  where?: Maybe<EventSubscriptionWhereInput>
};


export type SubscriptionAddressArgs = {
  where?: Maybe<AddressSubscriptionWhereInput>
};


export type SubscriptionUserArgs = {
  where?: Maybe<UserSubscriptionWhereInput>
};


export type SubscriptionPersonalDetailIndexArgs = {
  where?: Maybe<PersonalDetailIndexSubscriptionWhereInput>
};


export type SubscriptionContributionArgs = {
  where?: Maybe<ContributionSubscriptionWhereInput>
};


export type SubscriptionStokvelArgs = {
  where?: Maybe<StokvelSubscriptionWhereInput>
};


export type SubscriptionInviteArgs = {
  where?: Maybe<InviteSubscriptionWhereInput>
};


export type SubscriptionMemberArgs = {
  where?: Maybe<MemberSubscriptionWhereInput>
};


export type SubscriptionPersonalDetailArgs = {
  where?: Maybe<PersonalDetailSubscriptionWhereInput>
};

export type User = Node & {
   __typename?: 'User',
  id: Scalars['ID'],
  detail: PersonalDetail,
  password: Scalars['String'],
  picture: Scalars['String'],
  memberOf?: Maybe<Array<Member>>,
  administratorOf?: Maybe<Array<Stokvel>>,
  treasurerOf?: Maybe<Array<Stokvel>>,
  chairmanOf?: Maybe<Array<Stokvel>>,
  secretaryOf?: Maybe<Array<Stokvel>>,
  messagesSent?: Maybe<Array<Message>>,
  messagesReceived?: Maybe<Array<Message>>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};


export type UserMemberOfArgs = {
  where?: Maybe<MemberWhereInput>,
  orderBy?: Maybe<MemberOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type UserAdministratorOfArgs = {
  where?: Maybe<StokvelWhereInput>,
  orderBy?: Maybe<StokvelOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type UserTreasurerOfArgs = {
  where?: Maybe<StokvelWhereInput>,
  orderBy?: Maybe<StokvelOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type UserChairmanOfArgs = {
  where?: Maybe<StokvelWhereInput>,
  orderBy?: Maybe<StokvelOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type UserSecretaryOfArgs = {
  where?: Maybe<StokvelWhereInput>,
  orderBy?: Maybe<StokvelOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type UserMessagesSentArgs = {
  where?: Maybe<MessageWhereInput>,
  orderBy?: Maybe<MessageOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type UserMessagesReceivedArgs = {
  where?: Maybe<MessageWhereInput>,
  orderBy?: Maybe<MessageOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

/** A connection to a list of items. */
export type UserConnection = {
   __typename?: 'UserConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<UserEdge>>,
  aggregate: AggregateUser,
};

export type UserCreateInput = {
  id?: Maybe<Scalars['ID']>,
  password: Scalars['String'],
  picture: Scalars['String'],
  detail: PersonalDetailCreateOneInput,
  memberOf?: Maybe<MemberCreateManyWithoutUserInput>,
  administratorOf?: Maybe<StokvelCreateManyWithoutAdministratorInput>,
  treasurerOf?: Maybe<StokvelCreateManyWithoutTreasurerInput>,
  chairmanOf?: Maybe<StokvelCreateManyWithoutChairmanInput>,
  secretaryOf?: Maybe<StokvelCreateManyWithoutSecretaryInput>,
  messagesSent?: Maybe<MessageCreateManyWithoutFromInput>,
  messagesReceived?: Maybe<MessageCreateManyWithoutToInput>,
};

export type UserCreateOneInput = {
  create?: Maybe<UserCreateInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateOneWithoutAdministratorOfInput = {
  create?: Maybe<UserCreateWithoutAdministratorOfInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateOneWithoutChairmanOfInput = {
  create?: Maybe<UserCreateWithoutChairmanOfInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateOneWithoutMemberOfInput = {
  create?: Maybe<UserCreateWithoutMemberOfInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateOneWithoutMessagesReceivedInput = {
  create?: Maybe<UserCreateWithoutMessagesReceivedInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateOneWithoutMessagesSentInput = {
  create?: Maybe<UserCreateWithoutMessagesSentInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateOneWithoutSecretaryOfInput = {
  create?: Maybe<UserCreateWithoutSecretaryOfInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateOneWithoutTreasurerOfInput = {
  create?: Maybe<UserCreateWithoutTreasurerOfInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateWithoutAdministratorOfInput = {
  id?: Maybe<Scalars['ID']>,
  password: Scalars['String'],
  picture: Scalars['String'],
  detail: PersonalDetailCreateOneInput,
  memberOf?: Maybe<MemberCreateManyWithoutUserInput>,
  treasurerOf?: Maybe<StokvelCreateManyWithoutTreasurerInput>,
  chairmanOf?: Maybe<StokvelCreateManyWithoutChairmanInput>,
  secretaryOf?: Maybe<StokvelCreateManyWithoutSecretaryInput>,
  messagesSent?: Maybe<MessageCreateManyWithoutFromInput>,
  messagesReceived?: Maybe<MessageCreateManyWithoutToInput>,
};

export type UserCreateWithoutChairmanOfInput = {
  id?: Maybe<Scalars['ID']>,
  password: Scalars['String'],
  picture: Scalars['String'],
  detail: PersonalDetailCreateOneInput,
  memberOf?: Maybe<MemberCreateManyWithoutUserInput>,
  administratorOf?: Maybe<StokvelCreateManyWithoutAdministratorInput>,
  treasurerOf?: Maybe<StokvelCreateManyWithoutTreasurerInput>,
  secretaryOf?: Maybe<StokvelCreateManyWithoutSecretaryInput>,
  messagesSent?: Maybe<MessageCreateManyWithoutFromInput>,
  messagesReceived?: Maybe<MessageCreateManyWithoutToInput>,
};

export type UserCreateWithoutMemberOfInput = {
  id?: Maybe<Scalars['ID']>,
  password: Scalars['String'],
  picture: Scalars['String'],
  detail: PersonalDetailCreateOneInput,
  administratorOf?: Maybe<StokvelCreateManyWithoutAdministratorInput>,
  treasurerOf?: Maybe<StokvelCreateManyWithoutTreasurerInput>,
  chairmanOf?: Maybe<StokvelCreateManyWithoutChairmanInput>,
  secretaryOf?: Maybe<StokvelCreateManyWithoutSecretaryInput>,
  messagesSent?: Maybe<MessageCreateManyWithoutFromInput>,
  messagesReceived?: Maybe<MessageCreateManyWithoutToInput>,
};

export type UserCreateWithoutMessagesReceivedInput = {
  id?: Maybe<Scalars['ID']>,
  password: Scalars['String'],
  picture: Scalars['String'],
  detail: PersonalDetailCreateOneInput,
  memberOf?: Maybe<MemberCreateManyWithoutUserInput>,
  administratorOf?: Maybe<StokvelCreateManyWithoutAdministratorInput>,
  treasurerOf?: Maybe<StokvelCreateManyWithoutTreasurerInput>,
  chairmanOf?: Maybe<StokvelCreateManyWithoutChairmanInput>,
  secretaryOf?: Maybe<StokvelCreateManyWithoutSecretaryInput>,
  messagesSent?: Maybe<MessageCreateManyWithoutFromInput>,
};

export type UserCreateWithoutMessagesSentInput = {
  id?: Maybe<Scalars['ID']>,
  password: Scalars['String'],
  picture: Scalars['String'],
  detail: PersonalDetailCreateOneInput,
  memberOf?: Maybe<MemberCreateManyWithoutUserInput>,
  administratorOf?: Maybe<StokvelCreateManyWithoutAdministratorInput>,
  treasurerOf?: Maybe<StokvelCreateManyWithoutTreasurerInput>,
  chairmanOf?: Maybe<StokvelCreateManyWithoutChairmanInput>,
  secretaryOf?: Maybe<StokvelCreateManyWithoutSecretaryInput>,
  messagesReceived?: Maybe<MessageCreateManyWithoutToInput>,
};

export type UserCreateWithoutSecretaryOfInput = {
  id?: Maybe<Scalars['ID']>,
  password: Scalars['String'],
  picture: Scalars['String'],
  detail: PersonalDetailCreateOneInput,
  memberOf?: Maybe<MemberCreateManyWithoutUserInput>,
  administratorOf?: Maybe<StokvelCreateManyWithoutAdministratorInput>,
  treasurerOf?: Maybe<StokvelCreateManyWithoutTreasurerInput>,
  chairmanOf?: Maybe<StokvelCreateManyWithoutChairmanInput>,
  messagesSent?: Maybe<MessageCreateManyWithoutFromInput>,
  messagesReceived?: Maybe<MessageCreateManyWithoutToInput>,
};

export type UserCreateWithoutTreasurerOfInput = {
  id?: Maybe<Scalars['ID']>,
  password: Scalars['String'],
  picture: Scalars['String'],
  detail: PersonalDetailCreateOneInput,
  memberOf?: Maybe<MemberCreateManyWithoutUserInput>,
  administratorOf?: Maybe<StokvelCreateManyWithoutAdministratorInput>,
  chairmanOf?: Maybe<StokvelCreateManyWithoutChairmanInput>,
  secretaryOf?: Maybe<StokvelCreateManyWithoutSecretaryInput>,
  messagesSent?: Maybe<MessageCreateManyWithoutFromInput>,
  messagesReceived?: Maybe<MessageCreateManyWithoutToInput>,
};

/** An edge in a connection. */
export type UserEdge = {
   __typename?: 'UserEdge',
  /** The item at the end of the edge. */
  node: User,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum UserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PasswordAsc = 'password_ASC',
  PasswordDesc = 'password_DESC',
  PictureAsc = 'picture_ASC',
  PictureDesc = 'picture_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type UserPreviousValues = {
   __typename?: 'UserPreviousValues',
  id: Scalars['ID'],
  password: Scalars['String'],
  picture: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};

export type UserSubscriptionPayload = {
   __typename?: 'UserSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<User>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<UserPreviousValues>,
};

export type UserSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<UserSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<UserSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<UserSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<UserWhereInput>,
};

export type UserUpdateDataInput = {
  password?: Maybe<Scalars['String']>,
  picture?: Maybe<Scalars['String']>,
  detail?: Maybe<PersonalDetailUpdateOneRequiredInput>,
  memberOf?: Maybe<MemberUpdateManyWithoutUserInput>,
  administratorOf?: Maybe<StokvelUpdateManyWithoutAdministratorInput>,
  treasurerOf?: Maybe<StokvelUpdateManyWithoutTreasurerInput>,
  chairmanOf?: Maybe<StokvelUpdateManyWithoutChairmanInput>,
  secretaryOf?: Maybe<StokvelUpdateManyWithoutSecretaryInput>,
  messagesSent?: Maybe<MessageUpdateManyWithoutFromInput>,
  messagesReceived?: Maybe<MessageUpdateManyWithoutToInput>,
};

export type UserUpdateInput = {
  password?: Maybe<Scalars['String']>,
  picture?: Maybe<Scalars['String']>,
  detail?: Maybe<PersonalDetailUpdateOneRequiredInput>,
  memberOf?: Maybe<MemberUpdateManyWithoutUserInput>,
  administratorOf?: Maybe<StokvelUpdateManyWithoutAdministratorInput>,
  treasurerOf?: Maybe<StokvelUpdateManyWithoutTreasurerInput>,
  chairmanOf?: Maybe<StokvelUpdateManyWithoutChairmanInput>,
  secretaryOf?: Maybe<StokvelUpdateManyWithoutSecretaryInput>,
  messagesSent?: Maybe<MessageUpdateManyWithoutFromInput>,
  messagesReceived?: Maybe<MessageUpdateManyWithoutToInput>,
};

export type UserUpdateManyMutationInput = {
  password?: Maybe<Scalars['String']>,
  picture?: Maybe<Scalars['String']>,
};

export type UserUpdateOneInput = {
  create?: Maybe<UserCreateInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<UserUpdateDataInput>,
  upsert?: Maybe<UserUpsertNestedInput>,
};

export type UserUpdateOneWithoutAdministratorOfInput = {
  create?: Maybe<UserCreateWithoutAdministratorOfInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<UserUpdateWithoutAdministratorOfDataInput>,
  upsert?: Maybe<UserUpsertWithoutAdministratorOfInput>,
};

export type UserUpdateOneWithoutChairmanOfInput = {
  create?: Maybe<UserCreateWithoutChairmanOfInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<UserUpdateWithoutChairmanOfDataInput>,
  upsert?: Maybe<UserUpsertWithoutChairmanOfInput>,
};

export type UserUpdateOneWithoutMemberOfInput = {
  create?: Maybe<UserCreateWithoutMemberOfInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<UserUpdateWithoutMemberOfDataInput>,
  upsert?: Maybe<UserUpsertWithoutMemberOfInput>,
};

export type UserUpdateOneWithoutMessagesReceivedInput = {
  create?: Maybe<UserCreateWithoutMessagesReceivedInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<UserUpdateWithoutMessagesReceivedDataInput>,
  upsert?: Maybe<UserUpsertWithoutMessagesReceivedInput>,
};

export type UserUpdateOneWithoutMessagesSentInput = {
  create?: Maybe<UserCreateWithoutMessagesSentInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<UserUpdateWithoutMessagesSentDataInput>,
  upsert?: Maybe<UserUpsertWithoutMessagesSentInput>,
};

export type UserUpdateOneWithoutSecretaryOfInput = {
  create?: Maybe<UserCreateWithoutSecretaryOfInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<UserUpdateWithoutSecretaryOfDataInput>,
  upsert?: Maybe<UserUpsertWithoutSecretaryOfInput>,
};

export type UserUpdateOneWithoutTreasurerOfInput = {
  create?: Maybe<UserCreateWithoutTreasurerOfInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<UserUpdateWithoutTreasurerOfDataInput>,
  upsert?: Maybe<UserUpsertWithoutTreasurerOfInput>,
};

export type UserUpdateWithoutAdministratorOfDataInput = {
  password?: Maybe<Scalars['String']>,
  picture?: Maybe<Scalars['String']>,
  detail?: Maybe<PersonalDetailUpdateOneRequiredInput>,
  memberOf?: Maybe<MemberUpdateManyWithoutUserInput>,
  treasurerOf?: Maybe<StokvelUpdateManyWithoutTreasurerInput>,
  chairmanOf?: Maybe<StokvelUpdateManyWithoutChairmanInput>,
  secretaryOf?: Maybe<StokvelUpdateManyWithoutSecretaryInput>,
  messagesSent?: Maybe<MessageUpdateManyWithoutFromInput>,
  messagesReceived?: Maybe<MessageUpdateManyWithoutToInput>,
};

export type UserUpdateWithoutChairmanOfDataInput = {
  password?: Maybe<Scalars['String']>,
  picture?: Maybe<Scalars['String']>,
  detail?: Maybe<PersonalDetailUpdateOneRequiredInput>,
  memberOf?: Maybe<MemberUpdateManyWithoutUserInput>,
  administratorOf?: Maybe<StokvelUpdateManyWithoutAdministratorInput>,
  treasurerOf?: Maybe<StokvelUpdateManyWithoutTreasurerInput>,
  secretaryOf?: Maybe<StokvelUpdateManyWithoutSecretaryInput>,
  messagesSent?: Maybe<MessageUpdateManyWithoutFromInput>,
  messagesReceived?: Maybe<MessageUpdateManyWithoutToInput>,
};

export type UserUpdateWithoutMemberOfDataInput = {
  password?: Maybe<Scalars['String']>,
  picture?: Maybe<Scalars['String']>,
  detail?: Maybe<PersonalDetailUpdateOneRequiredInput>,
  administratorOf?: Maybe<StokvelUpdateManyWithoutAdministratorInput>,
  treasurerOf?: Maybe<StokvelUpdateManyWithoutTreasurerInput>,
  chairmanOf?: Maybe<StokvelUpdateManyWithoutChairmanInput>,
  secretaryOf?: Maybe<StokvelUpdateManyWithoutSecretaryInput>,
  messagesSent?: Maybe<MessageUpdateManyWithoutFromInput>,
  messagesReceived?: Maybe<MessageUpdateManyWithoutToInput>,
};

export type UserUpdateWithoutMessagesReceivedDataInput = {
  password?: Maybe<Scalars['String']>,
  picture?: Maybe<Scalars['String']>,
  detail?: Maybe<PersonalDetailUpdateOneRequiredInput>,
  memberOf?: Maybe<MemberUpdateManyWithoutUserInput>,
  administratorOf?: Maybe<StokvelUpdateManyWithoutAdministratorInput>,
  treasurerOf?: Maybe<StokvelUpdateManyWithoutTreasurerInput>,
  chairmanOf?: Maybe<StokvelUpdateManyWithoutChairmanInput>,
  secretaryOf?: Maybe<StokvelUpdateManyWithoutSecretaryInput>,
  messagesSent?: Maybe<MessageUpdateManyWithoutFromInput>,
};

export type UserUpdateWithoutMessagesSentDataInput = {
  password?: Maybe<Scalars['String']>,
  picture?: Maybe<Scalars['String']>,
  detail?: Maybe<PersonalDetailUpdateOneRequiredInput>,
  memberOf?: Maybe<MemberUpdateManyWithoutUserInput>,
  administratorOf?: Maybe<StokvelUpdateManyWithoutAdministratorInput>,
  treasurerOf?: Maybe<StokvelUpdateManyWithoutTreasurerInput>,
  chairmanOf?: Maybe<StokvelUpdateManyWithoutChairmanInput>,
  secretaryOf?: Maybe<StokvelUpdateManyWithoutSecretaryInput>,
  messagesReceived?: Maybe<MessageUpdateManyWithoutToInput>,
};

export type UserUpdateWithoutSecretaryOfDataInput = {
  password?: Maybe<Scalars['String']>,
  picture?: Maybe<Scalars['String']>,
  detail?: Maybe<PersonalDetailUpdateOneRequiredInput>,
  memberOf?: Maybe<MemberUpdateManyWithoutUserInput>,
  administratorOf?: Maybe<StokvelUpdateManyWithoutAdministratorInput>,
  treasurerOf?: Maybe<StokvelUpdateManyWithoutTreasurerInput>,
  chairmanOf?: Maybe<StokvelUpdateManyWithoutChairmanInput>,
  messagesSent?: Maybe<MessageUpdateManyWithoutFromInput>,
  messagesReceived?: Maybe<MessageUpdateManyWithoutToInput>,
};

export type UserUpdateWithoutTreasurerOfDataInput = {
  password?: Maybe<Scalars['String']>,
  picture?: Maybe<Scalars['String']>,
  detail?: Maybe<PersonalDetailUpdateOneRequiredInput>,
  memberOf?: Maybe<MemberUpdateManyWithoutUserInput>,
  administratorOf?: Maybe<StokvelUpdateManyWithoutAdministratorInput>,
  chairmanOf?: Maybe<StokvelUpdateManyWithoutChairmanInput>,
  secretaryOf?: Maybe<StokvelUpdateManyWithoutSecretaryInput>,
  messagesSent?: Maybe<MessageUpdateManyWithoutFromInput>,
  messagesReceived?: Maybe<MessageUpdateManyWithoutToInput>,
};

export type UserUpsertNestedInput = {
  update: UserUpdateDataInput,
  create: UserCreateInput,
};

export type UserUpsertWithoutAdministratorOfInput = {
  update: UserUpdateWithoutAdministratorOfDataInput,
  create: UserCreateWithoutAdministratorOfInput,
};

export type UserUpsertWithoutChairmanOfInput = {
  update: UserUpdateWithoutChairmanOfDataInput,
  create: UserCreateWithoutChairmanOfInput,
};

export type UserUpsertWithoutMemberOfInput = {
  update: UserUpdateWithoutMemberOfDataInput,
  create: UserCreateWithoutMemberOfInput,
};

export type UserUpsertWithoutMessagesReceivedInput = {
  update: UserUpdateWithoutMessagesReceivedDataInput,
  create: UserCreateWithoutMessagesReceivedInput,
};

export type UserUpsertWithoutMessagesSentInput = {
  update: UserUpdateWithoutMessagesSentDataInput,
  create: UserCreateWithoutMessagesSentInput,
};

export type UserUpsertWithoutSecretaryOfInput = {
  update: UserUpdateWithoutSecretaryOfDataInput,
  create: UserCreateWithoutSecretaryOfInput,
};

export type UserUpsertWithoutTreasurerOfInput = {
  update: UserUpdateWithoutTreasurerOfDataInput,
  create: UserCreateWithoutTreasurerOfInput,
};

export type UserWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<UserWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<UserWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<UserWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  password?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  password_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  password_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  password_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  password_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  password_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  password_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  password_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  password_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  password_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  password_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  password_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  password_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  password_not_ends_with?: Maybe<Scalars['String']>,
  picture?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  picture_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  picture_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  picture_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  picture_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  picture_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  picture_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  picture_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  picture_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  picture_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  picture_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  picture_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  picture_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  picture_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  detail?: Maybe<PersonalDetailWhereInput>,
  memberOf_every?: Maybe<MemberWhereInput>,
  memberOf_some?: Maybe<MemberWhereInput>,
  memberOf_none?: Maybe<MemberWhereInput>,
  administratorOf_every?: Maybe<StokvelWhereInput>,
  administratorOf_some?: Maybe<StokvelWhereInput>,
  administratorOf_none?: Maybe<StokvelWhereInput>,
  treasurerOf_every?: Maybe<StokvelWhereInput>,
  treasurerOf_some?: Maybe<StokvelWhereInput>,
  treasurerOf_none?: Maybe<StokvelWhereInput>,
  chairmanOf_every?: Maybe<StokvelWhereInput>,
  chairmanOf_some?: Maybe<StokvelWhereInput>,
  chairmanOf_none?: Maybe<StokvelWhereInput>,
  secretaryOf_every?: Maybe<StokvelWhereInput>,
  secretaryOf_some?: Maybe<StokvelWhereInput>,
  secretaryOf_none?: Maybe<StokvelWhereInput>,
  messagesSent_every?: Maybe<MessageWhereInput>,
  messagesSent_some?: Maybe<MessageWhereInput>,
  messagesSent_none?: Maybe<MessageWhereInput>,
  messagesReceived_every?: Maybe<MessageWhereInput>,
  messagesReceived_some?: Maybe<MessageWhereInput>,
  messagesReceived_none?: Maybe<MessageWhereInput>,
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type CreateBankAccountMutationVariables = {
  BankAccount: BankAccountCreateInput
};


export type CreateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { createBankAccount: (
    { __typename?: 'BankAccount' }
    & Pick<BankAccount, 'id'>
  ) }
);

export type GetBankAccountDetailQueryVariables = {
  bankAccountId: Scalars['ID']
};


export type GetBankAccountDetailQuery = (
  { __typename?: 'Query' }
  & { bankAccount: Maybe<(
    { __typename?: 'BankAccount' }
    & Pick<BankAccount, 'id'>
  )> }
);

export type GetBankAccountItemQueryVariables = {
  bankAccountId: Scalars['ID']
};


export type GetBankAccountItemQuery = (
  { __typename?: 'Query' }
  & { bankAccount: Maybe<(
    { __typename?: 'BankAccount' }
    & Pick<BankAccount, 'id'>
  )> }
);

export type GetBankAccountListQueryVariables = {
  where?: Maybe<BankAccountWhereInput>,
  orderBy?: Maybe<BankAccountOrderByInput>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>
};


export type GetBankAccountListQuery = (
  { __typename?: 'Query' }
  & { bankAccounts: Array<Maybe<(
    { __typename?: 'BankAccount' }
    & Pick<BankAccount, 'id' | 'name' | 'accountNo' | 'branchCode' | 'status' | 'createdAt'>
  )>>, count: (
    { __typename?: 'BankAccountConnection' }
    & { aggregate: (
      { __typename?: 'AggregateBankAccount' }
      & Pick<AggregateBankAccount, 'count'>
    ) }
  ) }
);

export type SearchBankAccountListQueryVariables = {
  searchText?: Maybe<Scalars['String']>
};


export type SearchBankAccountListQuery = (
  { __typename?: 'Query' }
  & { bankAccounts: Array<Maybe<(
    { __typename?: 'BankAccount' }
    & Pick<BankAccount, 'name' | 'accountNo' | 'branchCode' | 'status' | 'createdAt'>
  )>> }
);

export type CreateConstitutionMutationVariables = {
  constitution: ConstitutionCreateInput
};


export type CreateConstitutionMutation = (
  { __typename?: 'Mutation' }
  & { createConstitution: (
    { __typename?: 'Constitution' }
    & Pick<Constitution, 'id' | 'startingAge' | 'isPublic' | 'maximumNumberOfMembers' | 'proofOfRegistration' | 'effectiveDate' | 'venue' | 'stokvelType' | 'secondaryStokvelType' | 'meetingFrequency' | 'feeForNotAttending' | 'feeForNotWearingUniform' | 'joiningFee' | 'contribtutionAmount' | 'contributionFrequency' | 'endDateOnCommitee' | 'createdAt' | 'mainMemberPayoutAmount' | 'spousePayoutAmount' | 'dependentsPayoutAmount' | 'childrenPayoutAmount'>
    & { stokvel: Maybe<(
      { __typename?: 'Stokvel' }
      & Pick<Stokvel, 'id' | 'name'>
      & { treasurer: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name'>
        ) }
      )>, chairman: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name'>
        ) }
      )>, administrator: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name'>
        ) }
      )>, secretary: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name'>
        ) }
      )> }
    )> }
  ) }
);

export type GetConstitutionDetailQueryVariables = {
  constitutionId: Scalars['ID']
};


export type GetConstitutionDetailQuery = (
  { __typename?: 'Query' }
  & { constitution: Maybe<(
    { __typename?: 'Constitution' }
    & Pick<Constitution, 'id' | 'effectiveDate'>
  )> }
);

export type GetMaxNumMembersQueryVariables = {
  stokvelId: Scalars['ID']
};


export type GetMaxNumMembersQuery = (
  { __typename?: 'Query' }
  & { constitutions: Array<Maybe<(
    { __typename?: 'Constitution' }
    & Pick<Constitution, 'id' | 'effectiveDate' | 'maximumNumberOfMembers'>
    & { stokvel: Maybe<(
      { __typename?: 'Stokvel' }
      & Pick<Stokvel, 'id'>
    )> }
  )>> }
);

export type GetConstitutionItemQueryVariables = {
  stokvelId: Scalars['ID']
};


export type GetConstitutionItemQuery = (
  { __typename?: 'Query' }
  & { constitutions: Array<Maybe<(
    { __typename?: 'Constitution' }
    & Pick<Constitution, 'id' | 'startingAge' | 'isPublic' | 'secondaryStokvelType' | 'stokvelType' | 'maximumNumberOfMembers' | 'proofOfRegistration' | 'effectiveDate' | 'venue' | 'meetingFrequency' | 'feeForNotAttending' | 'feeForNotWearingUniform' | 'joiningFee' | 'contribtutionAmount' | 'contributionFrequency' | 'endDateOnCommitee' | 'createdAt' | 'mainMemberPayoutAmount' | 'spousePayoutAmount' | 'dependentsPayoutAmount' | 'childrenPayoutAmount'>
    & { stokvel: Maybe<(
      { __typename?: 'Stokvel' }
      & Pick<Stokvel, 'id' | 'name'>
      & { treasurer: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name'>
        ) }
      )>, chairman: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name'>
        ) }
      )>, administrator: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name'>
        ) }
      )>, secretary: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name'>
        ) }
      )> }
    )> }
  )>> }
);

export type GetConstitutionListQueryVariables = {
  where?: Maybe<ConstitutionWhereInput>,
  orderBy?: Maybe<ConstitutionOrderByInput>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type GetConstitutionListQuery = (
  { __typename?: 'Query' }
  & { constitutions: Array<Maybe<(
    { __typename?: 'Constitution' }
    & Pick<Constitution, 'id'>
  )>>, count: (
    { __typename?: 'ConstitutionConnection' }
    & { aggregate: (
      { __typename?: 'AggregateConstitution' }
      & Pick<AggregateConstitution, 'count'>
    ) }
  ) }
);

export type RemoveConstitutionMutationVariables = {
  conID: Scalars['ID']
};


export type RemoveConstitutionMutation = (
  { __typename?: 'Mutation' }
  & { deleteManyConstitutions: (
    { __typename?: 'BatchPayload' }
    & Pick<BatchPayload, 'count'>
  ) }
);

export type SearchConstitutionListQueryVariables = {
  searchText?: Maybe<Scalars['String']>
};


export type SearchConstitutionListQuery = (
  { __typename?: 'Query' }
  & { constitutions: Array<Maybe<(
    { __typename?: 'Constitution' }
    & Pick<Constitution, 'id'>
  )>> }
);

export type UpdateConstitutionMutationVariables = {
  constitution: ConstitutionUpdateInput,
  constitutionId: Scalars['ID']
};


export type UpdateConstitutionMutation = (
  { __typename?: 'Mutation' }
  & { updateConstitution: Maybe<(
    { __typename?: 'Constitution' }
    & Pick<Constitution, 'id' | 'startingAge' | 'secondaryStokvelType' | 'stokvelType' | 'isPublic' | 'maximumNumberOfMembers' | 'proofOfRegistration' | 'effectiveDate' | 'venue' | 'meetingFrequency' | 'feeForNotAttending' | 'feeForNotWearingUniform' | 'joiningFee' | 'contribtutionAmount' | 'contributionFrequency' | 'endDateOnCommitee' | 'createdAt' | 'mainMemberPayoutAmount' | 'spousePayoutAmount' | 'dependentsPayoutAmount' | 'childrenPayoutAmount'>
    & { stokvel: Maybe<(
      { __typename?: 'Stokvel' }
      & Pick<Stokvel, 'id' | 'name'>
      & { treasurer: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name'>
        ) }
      )>, chairman: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name'>
        ) }
      )>, administrator: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name'>
        ) }
      )>, secretary: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name'>
        ) }
      )> }
    )> }
  )> }
);

export type CreateContributionMutationVariables = {
  contribution: ContributionCreateInput
};


export type CreateContributionMutation = (
  { __typename?: 'Mutation' }
  & { createContribution: (
    { __typename?: 'Contribution' }
    & Pick<Contribution, 'id'>
  ) }
);

export type GetContributionDetailQueryVariables = {
  contributionId: Scalars['ID']
};


export type GetContributionDetailQuery = (
  { __typename?: 'Query' }
  & { contribution: Maybe<(
    { __typename?: 'Contribution' }
    & Pick<Contribution, 'id'>
  )> }
);

export type GetContributionItemQueryVariables = {
  contributionId: Scalars['ID']
};


export type GetContributionItemQuery = (
  { __typename?: 'Query' }
  & { contribution: Maybe<(
    { __typename?: 'Contribution' }
    & Pick<Contribution, 'id'>
  )> }
);

export type GetContributionListQueryVariables = {
  where?: Maybe<ContributionWhereInput>,
  orderBy?: Maybe<ContributionOrderByInput>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>
};


export type GetContributionListQuery = (
  { __typename?: 'Query' }
  & { contributions: Array<Maybe<(
    { __typename?: 'Contribution' }
    & Pick<Contribution, 'id' | 'amount' | 'transactionDate'>
    & { member: (
      { __typename?: 'Member' }
      & Pick<Member, 'joinedDate'>
      & { invite: Maybe<(
        { __typename?: 'Invite' }
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
        ) }
      )>, user: Maybe<(
        { __typename?: 'User' }
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
        ) }
      )> }
    ) }
  )>>, count: (
    { __typename?: 'ContributionConnection' }
    & { aggregate: (
      { __typename?: 'AggregateContribution' }
      & Pick<AggregateContribution, 'count'>
    ) }
  ) }
);

export type SearchContributionListQueryVariables = {
  searchText?: Maybe<Scalars['String']>
};


export type SearchContributionListQuery = (
  { __typename?: 'Query' }
  & { contributions: Array<Maybe<(
    { __typename?: 'Contribution' }
    & Pick<Contribution, 'id' | 'amount' | 'transactionDate'>
    & { member: (
      { __typename?: 'Member' }
      & Pick<Member, 'joinedDate'>
      & { invite: Maybe<(
        { __typename?: 'Invite' }
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
        ) }
      )> }
    ) }
  )>> }
);

export type CreateEventMutationVariables = {
  event: EventCreateInput
};


export type CreateEventMutation = (
  { __typename?: 'Mutation' }
  & { createEvent: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'description' | 'startDate'>
    & { stokvel: Maybe<(
      { __typename?: 'Stokvel' }
      & Pick<Stokvel, 'id'>
    )> }
  ) }
);

export type RemoveEventMutationVariables = {
  id: Scalars['ID']
};


export type RemoveEventMutation = (
  { __typename?: 'Mutation' }
  & { deleteEvent: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'description' | 'startDate'>
  )> }
);

export type GetEventDetailQueryVariables = {
  eventId: Scalars['ID']
};


export type GetEventDetailQuery = (
  { __typename?: 'Query' }
  & { event: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'name'>
  )> }
);

export type SearchEventListByStokvelsQueryVariables = {
  searchText: StokvelWhereInput
};


export type SearchEventListByStokvelsQuery = (
  { __typename?: 'Query' }
  & { events: Array<Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'description' | 'startDate'>
    & { stokvel: Maybe<(
      { __typename?: 'Stokvel' }
      & Pick<Stokvel, 'id'>
    )> }
  )>> }
);

export type GetEventItemQueryVariables = {
  eventId: Scalars['ID']
};


export type GetEventItemQuery = (
  { __typename?: 'Query' }
  & { event: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'name'>
  )> }
);

export type GetEventListQueryVariables = {
  where?: Maybe<EventWhereInput>,
  orderBy?: Maybe<EventOrderByInput>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type GetEventListQuery = (
  { __typename?: 'Query' }
  & { events: Array<Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  )>>, count: (
    { __typename?: 'EventConnection' }
    & { aggregate: (
      { __typename?: 'AggregateEvent' }
      & Pick<AggregateEvent, 'count'>
    ) }
  ) }
);

export type SearchEventListQueryVariables = {
  searchText?: Maybe<Scalars['String']>
};


export type SearchEventListQuery = (
  { __typename?: 'Query' }
  & { events: Array<Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  )>> }
);

export type UpdateEventMutationVariables = {
  eventId: Scalars['ID'],
  event: EventUpdateInput
};


export type UpdateEventMutation = (
  { __typename?: 'Mutation' }
  & { updateEvent: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'description' | 'startDate'>
    & { stokvel: Maybe<(
      { __typename?: 'Stokvel' }
      & Pick<Stokvel, 'id'>
    )> }
  )> }
);

export type CreateInviteMutationVariables = {
  invite: InviteCreateInput
};


export type CreateInviteMutation = (
  { __typename?: 'Mutation' }
  & { createInvite: (
    { __typename?: 'Invite' }
    & Pick<Invite, 'id' | 'invitedDate' | 'isSent'>
    & { detail: (
      { __typename?: 'PersonalDetail' }
      & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
      & { index: (
        { __typename?: 'PersonalDetailIndex' }
        & Pick<PersonalDetailIndex, 'id' | 'name' | 'email'>
      ) }
    ), stokvel: (
      { __typename?: 'Stokvel' }
      & Pick<Stokvel, 'id'>
    ) }
  ) }
);

export type GetInviteDetailQueryVariables = {
  inviteId: Scalars['ID']
};


export type GetInviteDetailQuery = (
  { __typename?: 'Query' }
  & { invite: Maybe<(
    { __typename?: 'Invite' }
    & { detail: (
      { __typename?: 'PersonalDetail' }
      & Pick<PersonalDetail, 'id' | 'name'>
    ) }
  )> }
);

export type GetAllInvitesQueryVariables = {};


export type GetAllInvitesQuery = (
  { __typename?: 'Query' }
  & { invites: Array<Maybe<(
    { __typename?: 'Invite' }
    & Pick<Invite, 'id'>
    & { detail: (
      { __typename?: 'PersonalDetail' }
      & Pick<PersonalDetail, 'id' | 'name' | 'cellphone'>
    ) }
  )>> }
);

export type GetInviteItemQueryVariables = {
  inviteId: Scalars['ID']
};


export type GetInviteItemQuery = (
  { __typename?: 'Query' }
  & { invite: Maybe<(
    { __typename?: 'Invite' }
    & Pick<Invite, 'alreadyActive'>
    & { detail: (
      { __typename?: 'PersonalDetail' }
      & Pick<PersonalDetail, 'id' | 'name'>
    ), stokvel: (
      { __typename?: 'Stokvel' }
      & Pick<Stokvel, 'id' | 'name'>
    ) }
  )> }
);

export type GetInviteListQueryVariables = {
  where?: Maybe<InviteWhereInput>,
  orderBy?: Maybe<InviteOrderByInput>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type GetInviteListQuery = (
  { __typename?: 'Query' }
  & { invites: Array<Maybe<(
    { __typename?: 'Invite' }
    & Pick<Invite, 'id' | 'invitedDate' | 'isSent' | 'alreadyActive'>
    & { detail: (
      { __typename?: 'PersonalDetail' }
      & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
    ), stokvel: (
      { __typename?: 'Stokvel' }
      & Pick<Stokvel, 'id' | 'name' | 'startDate'>
      & { administrator: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, treasurer: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, chairman: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, secretary: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, members: Maybe<Array<(
        { __typename?: 'Member' }
        & Pick<Member, 'id' | 'name'>
      )>> }
    ) }
  )>>, count: (
    { __typename?: 'InviteConnection' }
    & { aggregate: (
      { __typename?: 'AggregateInvite' }
      & Pick<AggregateInvite, 'count'>
    ) }
  ) }
);

export type SearchInviteListQueryVariables = {
  where?: Maybe<InviteWhereInput>,
  orderBy?: Maybe<InviteOrderByInput>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type SearchInviteListQuery = (
  { __typename?: 'Query' }
  & { invites: Array<Maybe<(
    { __typename?: 'Invite' }
    & Pick<Invite, 'id' | 'invitedDate' | 'isSent'>
    & { detail: (
      { __typename?: 'PersonalDetail' }
      & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
      & { index: (
        { __typename?: 'PersonalDetailIndex' }
        & Pick<PersonalDetailIndex, 'id' | 'name' | 'lastName' | 'email'>
      ) }
    ) }
  )>> }
);

export type UpdateInviteMutationVariables = {
  data: InviteUpdateInput,
  where: InviteWhereUniqueInput
};


export type UpdateInviteMutation = (
  { __typename?: 'Mutation' }
  & { updateInvite: Maybe<(
    { __typename?: 'Invite' }
    & Pick<Invite, 'id'>
  )> }
);

export type CreateMemberLinkAdministartorMemberMutationVariables = {
  startDate: Scalars['DateTime'],
  administratorId?: Maybe<Scalars['ID']>,
  newStokvelId?: Maybe<Scalars['ID']>
};


export type CreateMemberLinkAdministartorMemberMutation = (
  { __typename?: 'Mutation' }
  & { createMember: (
    { __typename?: 'Member' }
    & Pick<Member, 'id'>
  ) }
);

export type CreateMemberMutationVariables = {
  member: MemberCreateInput
};


export type CreateMemberMutation = (
  { __typename?: 'Mutation' }
  & { createMember: (
    { __typename?: 'Member' }
    & Pick<Member, 'id'>
  ) }
);

export type GetMemberDetailQueryVariables = {
  memberId: Scalars['ID']
};


export type GetMemberDetailQuery = (
  { __typename?: 'Query' }
  & { member: Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'id'>
    & { stokvel: Maybe<(
      { __typename?: 'Stokvel' }
      & Pick<Stokvel, 'id'>
    )> }
  )> }
);

export type GetAllMembersQueryVariables = {
  where?: Maybe<MemberWhereInput>,
  orderBy?: Maybe<MemberOrderByInput>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type GetAllMembersQuery = (
  { __typename?: 'Query' }
  & { members: Array<Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'joinedDate'>
    & { invite: Maybe<(
      { __typename?: 'Invite' }
      & { detail: (
        { __typename?: 'PersonalDetail' }
        & Pick<PersonalDetail, 'name' | 'lastName' | 'email' | 'cellphone'>
      ) }
    )>, stokvel: Maybe<(
      { __typename?: 'Stokvel' }
      & Pick<Stokvel, 'id'>
    )> }
  )>>, count: (
    { __typename?: 'MemberConnection' }
    & { aggregate: (
      { __typename?: 'AggregateMember' }
      & Pick<AggregateMember, 'count'>
    ) }
  ) }
);

export type GetMemberItemQueryVariables = {
  memberId: Scalars['ID']
};


export type GetMemberItemQuery = (
  { __typename?: 'Query' }
  & { member: Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'name' | 'joinedDate'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { detail: (
        { __typename?: 'PersonalDetail' }
        & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
      ) }
    )>, stokvel: Maybe<(
      { __typename?: 'Stokvel' }
      & Pick<Stokvel, 'id' | 'planId'>
    )> }
  )> }
);

export type GetMemberListQueryVariables = {
  where?: Maybe<MemberWhereInput>,
  orderBy?: Maybe<MemberOrderByInput>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>
};


export type GetMemberListQuery = (
  { __typename?: 'Query' }
  & { members: Array<Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'joinedDate'>
    & { invite: Maybe<(
      { __typename?: 'Invite' }
      & Pick<Invite, 'id'>
      & { detail: (
        { __typename?: 'PersonalDetail' }
        & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'cellphone' | 'email'>
        & { address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'line1' | 'line2' | 'line3' | 'city' | 'code'>
        )> }
      ) }
    )>, user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { detail: (
        { __typename?: 'PersonalDetail' }
        & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'cellphone' | 'email'>
        & { address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'line1' | 'line2' | 'line3' | 'city' | 'code'>
        )> }
      ) }
    )>, stokvel: Maybe<(
      { __typename?: 'Stokvel' }
      & Pick<Stokvel, 'id' | 'planId' | 'name'>
    )> }
  )>>, count: (
    { __typename?: 'MemberConnection' }
    & { aggregate: (
      { __typename?: 'AggregateMember' }
      & Pick<AggregateMember, 'count'>
    ) }
  ) }
);

export type SearchMemberListQueryVariables = {
  where?: Maybe<MemberWhereInput>,
  orderBy?: Maybe<MemberOrderByInput>,
  first?: Maybe<Scalars['Int']>
};


export type SearchMemberListQuery = (
  { __typename?: 'Query' }
  & { members: Array<Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'joinedDate'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { detail: (
        { __typename?: 'PersonalDetail' }
        & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
      ) }
    )>, invite: Maybe<(
      { __typename?: 'Invite' }
      & { detail: (
        { __typename?: 'PersonalDetail' }
        & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
      ) }
    )> }
  )>> }
);

export type UpdateMemberMutationVariables = {
  data: MemberUpdateInput,
  where: MemberWhereUniqueInput
};


export type UpdateMemberMutation = (
  { __typename?: 'Mutation' }
  & { updateMember: Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'joinedDate'>
    & { invite: Maybe<(
      { __typename?: 'Invite' }
      & { detail: (
        { __typename?: 'PersonalDetail' }
        & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
      ) }
    )>, stokvel: Maybe<(
      { __typename?: 'Stokvel' }
      & Pick<Stokvel, 'id'>
    )> }
  )> }
);

export type CreateMessageMutationVariables = {
  message: MessageCreateInput
};


export type CreateMessageMutation = (
  { __typename?: 'Mutation' }
  & { createMessage: (
    { __typename?: 'Message' }
    & Pick<Message, 'id'>
  ) }
);

export type GetMessageDetailQueryVariables = {
  messageId: Scalars['ID']
};


export type GetMessageDetailQuery = (
  { __typename?: 'Query' }
  & { message: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id'>
  )> }
);

export type GetMessageItemQueryVariables = {
  messageId: Scalars['ID']
};


export type GetMessageItemQuery = (
  { __typename?: 'Query' }
  & { message: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id'>
  )> }
);

export type GetMessageListQueryVariables = {
  where?: Maybe<MessageWhereInput>,
  orderBy?: Maybe<MessageOrderByInput>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type GetMessageListQuery = (
  { __typename?: 'Query' }
  & { messages: Array<Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id'>
  )>>, count: (
    { __typename?: 'MessageConnection' }
    & { aggregate: (
      { __typename?: 'AggregateMessage' }
      & Pick<AggregateMessage, 'count'>
    ) }
  ) }
);

export type SearchMessageListQueryVariables = {
  searchText?: Maybe<Scalars['String']>
};


export type SearchMessageListQuery = (
  { __typename?: 'Query' }
  & { messages: Array<Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id'>
  )>> }
);

export type CreatePaymentMutationVariables = {
  payment: PaymentCreateInput
};


export type CreatePaymentMutation = (
  { __typename?: 'Mutation' }
  & { createPayment: (
    { __typename?: 'Payment' }
    & Pick<Payment, 'id'>
  ) }
);

export type GetPaymentListQueryVariables = {
  where?: Maybe<PaymentWhereInput>,
  orderBy?: Maybe<PaymentOrderByInput>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>
};


export type GetPaymentListQuery = (
  { __typename?: 'Query' }
  & { payments: Array<Maybe<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'id' | 'amount' | 'proofOfPayment' | 'transactionDate'>
    & { member: (
      { __typename?: 'Member' }
      & Pick<Member, 'joinedDate'>
      & { invite: Maybe<(
        { __typename?: 'Invite' }
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
        ) }
      )>, user: Maybe<(
        { __typename?: 'User' }
        & { detail: (
          { __typename?: 'PersonalDetail' }
          & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
        ) }
      )> }
    ) }
  )>>, count: (
    { __typename?: 'PaymentConnection' }
    & { aggregate: (
      { __typename?: 'AggregatePayment' }
      & Pick<AggregatePayment, 'count'>
    ) }
  ) }
);

export type CreatePersonalDetailMutationVariables = {
  payment: PersonalDetailCreateInput
};


export type CreatePersonalDetailMutation = (
  { __typename?: 'Mutation' }
  & { createPersonalDetail: (
    { __typename?: 'PersonalDetail' }
    & Pick<PersonalDetail, 'id'>
  ) }
);

export type GetPersonalDetailsQueryVariables = {
  where?: Maybe<PersonalDetailWhereInput>,
  orderBy?: Maybe<PersonalDetailOrderByInput>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>
};


export type GetPersonalDetailsQuery = (
  { __typename?: 'Query' }
  & { personalDetails: Array<Maybe<(
    { __typename?: 'PersonalDetail' }
    & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
    & { index: (
      { __typename?: 'PersonalDetailIndex' }
      & Pick<PersonalDetailIndex, 'id' | 'name' | 'lastName' | 'email'>
    ), user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { memberOf: Maybe<Array<(
        { __typename?: 'Member' }
        & Pick<Member, 'id'>
      )>> }
    )>, invite: Maybe<Array<(
      { __typename?: 'Invite' }
      & Pick<Invite, 'id'>
      & { stokvel: (
        { __typename?: 'Stokvel' }
        & Pick<Stokvel, 'id'>
      ) }
    )>> }
  )>>, count: (
    { __typename?: 'PersonalDetailConnection' }
    & { aggregate: (
      { __typename?: 'AggregatePersonalDetail' }
      & Pick<AggregatePersonalDetail, 'count'>
    ) }
  ) }
);

export type UpdatePersonalDetailMutationVariables = {
  data: PersonalDetailUpdateInput,
  where: PersonalDetailWhereUniqueInput
};


export type UpdatePersonalDetailMutation = (
  { __typename?: 'Mutation' }
  & { updatePersonalDetail: Maybe<(
    { __typename?: 'PersonalDetail' }
    & Pick<PersonalDetail, 'id'>
    & { invite: Maybe<Array<(
      { __typename?: 'Invite' }
      & Pick<Invite, 'id'>
    )>> }
  )> }
);

export type CreateStokvelMutationVariables = {
  name: Scalars['String'],
  planId: Scalars['String'],
  startDate: Scalars['DateTime'],
  administratorId?: Maybe<Scalars['ID']>
};


export type CreateStokvelMutation = (
  { __typename?: 'Mutation' }
  & { createStokvel: (
    { __typename?: 'Stokvel' }
    & Pick<Stokvel, 'id'>
  ) }
);

export type GetStokvelDetailQueryVariables = {
  stokvelId: Scalars['ID']
};


export type GetStokvelDetailQuery = (
  { __typename?: 'Query' }
  & { stokvel: Maybe<(
    { __typename?: 'Stokvel' }
    & Pick<Stokvel, 'name' | 'id' | 'startDate'>
    & { administrator: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, treasurer: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, chairman: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, secretary: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, constitution: Maybe<(
      { __typename?: 'Constitution' }
      & Pick<Constitution, 'id' | 'maximumNumberOfMembers'>
    )> }
  )> }
);

export type GetStokvelItemQueryVariables = {
  stokvelId: Scalars['ID']
};


export type GetStokvelItemQuery = (
  { __typename?: 'Query' }
  & { stokvel: Maybe<(
    { __typename?: 'Stokvel' }
    & Pick<Stokvel, 'name' | 'startDate'>
  )> }
);

export type GetStokvelListQueryVariables = {
  where?: Maybe<StokvelWhereInput>,
  orderBy?: Maybe<StokvelOrderByInput>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type GetStokvelListQuery = (
  { __typename?: 'Query' }
  & { stokvels: Array<Maybe<(
    { __typename?: 'Stokvel' }
    & Pick<Stokvel, 'id' | 'planId' | 'name'>
  )>>, members: Array<Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'name'>
  )>>, count: (
    { __typename?: 'StokvelConnection' }
    & { aggregate: (
      { __typename?: 'AggregateStokvel' }
      & Pick<AggregateStokvel, 'count'>
    ) }
  ) }
);

export type GetStokvelRolesQueryVariables = {
  stokvelId: Scalars['ID']
};


export type GetStokvelRolesQuery = (
  { __typename?: 'Query' }
  & { stokvel: Maybe<(
    { __typename?: 'Stokvel' }
    & Pick<Stokvel, 'id' | 'name' | 'planId' | 'startDate'>
    & { administrator: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'picture'>
      & { detail: (
        { __typename?: 'PersonalDetail' }
        & Pick<PersonalDetail, 'id' | 'name'>
      ) }
    )>, treasurer: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'picture'>
      & { detail: (
        { __typename?: 'PersonalDetail' }
        & Pick<PersonalDetail, 'id' | 'name'>
      ) }
    )>, chairman: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'picture'>
      & { detail: (
        { __typename?: 'PersonalDetail' }
        & Pick<PersonalDetail, 'id' | 'name'>
      ) }
    )>, secretary: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'picture'>
      & { detail: (
        { __typename?: 'PersonalDetail' }
        & Pick<PersonalDetail, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type SearchStokvelListQueryVariables = {
  searchText?: Maybe<Scalars['String']>
};


export type SearchStokvelListQuery = (
  { __typename?: 'Query' }
  & { stokvels: Array<Maybe<(
    { __typename?: 'Stokvel' }
    & Pick<Stokvel, 'id'>
  )>> }
);

export type UpdateStokvelMutationVariables = {
  data: StokvelUpdateInput,
  where: StokvelWhereUniqueInput
};


export type UpdateStokvelMutation = (
  { __typename?: 'Mutation' }
  & { updateStokvel: Maybe<(
    { __typename?: 'Stokvel' }
    & Pick<Stokvel, 'id'>
  )> }
);

export type CreateUserMutationVariables = {
  user: UserCreateInput
};


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'picture'>
    & { detail: (
      { __typename?: 'PersonalDetail' }
      & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
    ) }
  ) }
);

export type GetUserDetailQueryVariables = {
  userId: Scalars['ID']
};


export type GetUserDetailQuery = (
  { __typename?: 'Query' }
  & { user: Maybe<(
    { __typename?: 'User' }
    & { detail: (
      { __typename?: 'PersonalDetail' }
      & Pick<PersonalDetail, 'id' | 'name'>
    ) }
  )> }
);

export type GetAllUserQueryVariables = {};


export type GetAllUserQuery = (
  { __typename?: 'Query' }
  & { users: Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'password' | 'picture'>
    & { detail: (
      { __typename?: 'PersonalDetail' }
      & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
    ) }
  )>> }
);

export type GetAllUserDetailPersonalDetailsQueryVariables = {};


export type GetAllUserDetailPersonalDetailsQuery = (
  { __typename?: 'Query' }
  & { personalDetails: Array<Maybe<(
    { __typename?: 'PersonalDetail' }
    & Pick<PersonalDetail, 'id' | 'email' | 'cellphone'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { detail: (
        { __typename?: 'PersonalDetail' }
        & Pick<PersonalDetail, 'id'>
      ) }
    )> }
  )>> }
);

export type GetUserItemQueryVariables = {
  userId: Scalars['ID']
};


export type GetUserItemQuery = (
  { __typename?: 'Query' }
  & { user: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'picture'>
    & { detail: (
      { __typename?: 'PersonalDetail' }
      & Pick<PersonalDetail, 'id' | 'name'>
    ) }
  )> }
);

export type GetUserListQueryVariables = {
  where?: Maybe<UserWhereInput>,
  orderBy?: Maybe<UserOrderByInput>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type GetUserListQuery = (
  { __typename?: 'Query' }
  & { users: Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'picture'>
    & { detail: (
      { __typename?: 'PersonalDetail' }
      & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
    ) }
  )>>, count: (
    { __typename?: 'UserConnection' }
    & { aggregate: (
      { __typename?: 'AggregateUser' }
      & Pick<AggregateUser, 'count'>
    ) }
  ) }
);

export type SearchUserListQueryVariables = {
  where?: Maybe<UserWhereInput>,
  orderBy?: Maybe<UserOrderByInput>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type SearchUserListQuery = (
  { __typename?: 'Query' }
  & { users: Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { detail: (
      { __typename?: 'PersonalDetail' }
      & Pick<PersonalDetail, 'id' | 'name' | 'lastName' | 'email' | 'cellphone'>
    ) }
  )>> }
);

export type UpdateUserMutationVariables = {
  data: UserUpdateInput,
  where: UserWhereUniqueInput
};


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);


export const CreateBankAccountDocument = gql`
    mutation createBankAccount($BankAccount: BankAccountCreateInput!) {
  createBankAccount(data: $BankAccount) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBankAccountGQL extends Apollo.Mutation<CreateBankAccountMutation, CreateBankAccountMutationVariables> {
    document = CreateBankAccountDocument;
    
  }
export const GetBankAccountDetailDocument = gql`
    query getBankAccountDetail($bankAccountId: ID!) {
  bankAccount(where: {id: $bankAccountId}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBankAccountDetailGQL extends Apollo.Query<GetBankAccountDetailQuery, GetBankAccountDetailQueryVariables> {
    document = GetBankAccountDetailDocument;
    
  }
export const GetBankAccountItemDocument = gql`
    query getBankAccountItem($bankAccountId: ID!) {
  bankAccount(where: {id: $bankAccountId}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBankAccountItemGQL extends Apollo.Query<GetBankAccountItemQuery, GetBankAccountItemQueryVariables> {
    document = GetBankAccountItemDocument;
    
  }
export const GetBankAccountListDocument = gql`
    query getBankAccountList($where: BankAccountWhereInput, $orderBy: BankAccountOrderByInput, $first: Int, $last: Int, $after: String) {
  bankAccounts: bankAccounts(where: $where, orderBy: $orderBy, first: $first, last: $last, after: $after) {
    id
    name
    accountNo
    branchCode
    status
    createdAt
  }
  count: bankAccountsConnection(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBankAccountListGQL extends Apollo.Query<GetBankAccountListQuery, GetBankAccountListQueryVariables> {
    document = GetBankAccountListDocument;
    
  }
export const SearchBankAccountListDocument = gql`
    query searchBankAccountList($searchText: String) {
  bankAccounts(where: {accountNo_starts_with: $searchText}) {
    name
    accountNo
    branchCode
    status
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchBankAccountListGQL extends Apollo.Query<SearchBankAccountListQuery, SearchBankAccountListQueryVariables> {
    document = SearchBankAccountListDocument;
    
  }
export const CreateConstitutionDocument = gql`
    mutation createConstitution($constitution: ConstitutionCreateInput!) {
  createConstitution(data: $constitution) {
    id
    startingAge
    isPublic
    maximumNumberOfMembers
    proofOfRegistration
    effectiveDate
    venue
    stokvelType
    secondaryStokvelType
    meetingFrequency
    feeForNotAttending
    feeForNotWearingUniform
    joiningFee
    contribtutionAmount
    contributionFrequency
    endDateOnCommitee
    createdAt
    mainMemberPayoutAmount
    spousePayoutAmount
    dependentsPayoutAmount
    childrenPayoutAmount
    stokvel {
      id
      name
      treasurer {
        id
        detail {
          id
          name
        }
      }
      chairman {
        id
        detail {
          id
          name
        }
      }
      administrator {
        id
        detail {
          id
          name
        }
      }
      secretary {
        id
        detail {
          id
          name
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateConstitutionGQL extends Apollo.Mutation<CreateConstitutionMutation, CreateConstitutionMutationVariables> {
    document = CreateConstitutionDocument;
    
  }
export const GetConstitutionDetailDocument = gql`
    query getConstitutionDetail($constitutionId: ID!) {
  constitution(where: {id: $constitutionId}) {
    id
    effectiveDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetConstitutionDetailGQL extends Apollo.Query<GetConstitutionDetailQuery, GetConstitutionDetailQueryVariables> {
    document = GetConstitutionDetailDocument;
    
  }
export const GetMaxNumMembersDocument = gql`
    query getMaxNumMembers($stokvelId: ID!) {
  constitutions(where: {stokvel: {id: $stokvelId}}) {
    id
    effectiveDate
    maximumNumberOfMembers
    stokvel {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaxNumMembersGQL extends Apollo.Query<GetMaxNumMembersQuery, GetMaxNumMembersQueryVariables> {
    document = GetMaxNumMembersDocument;
    
  }
export const GetConstitutionItemDocument = gql`
    query getConstitutionItem($stokvelId: ID!) {
  constitutions(where: {stokvel: {id: $stokvelId}}) {
    id
    startingAge
    isPublic
    secondaryStokvelType
    stokvelType
    maximumNumberOfMembers
    proofOfRegistration
    effectiveDate
    venue
    meetingFrequency
    feeForNotAttending
    feeForNotWearingUniform
    joiningFee
    contribtutionAmount
    contributionFrequency
    endDateOnCommitee
    createdAt
    mainMemberPayoutAmount
    spousePayoutAmount
    dependentsPayoutAmount
    childrenPayoutAmount
    stokvel {
      id
      name
      treasurer {
        id
        detail {
          id
          name
        }
      }
      chairman {
        id
        detail {
          id
          name
        }
      }
      administrator {
        id
        detail {
          id
          name
        }
      }
      secretary {
        id
        detail {
          id
          name
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetConstitutionItemGQL extends Apollo.Query<GetConstitutionItemQuery, GetConstitutionItemQueryVariables> {
    document = GetConstitutionItemDocument;
    
  }
export const GetConstitutionListDocument = gql`
    query getConstitutionList($where: ConstitutionWhereInput, $orderBy: ConstitutionOrderByInput, $first: Int, $last: Int) {
  constitutions: constitutions(where: $where, orderBy: $orderBy, first: $first, last: $last) {
    id
  }
  count: constitutionsConnection(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetConstitutionListGQL extends Apollo.Query<GetConstitutionListQuery, GetConstitutionListQueryVariables> {
    document = GetConstitutionListDocument;
    
  }
export const RemoveConstitutionDocument = gql`
    mutation removeConstitution($conID: ID!) {
  deleteManyConstitutions(where: {id: $conID}) {
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveConstitutionGQL extends Apollo.Mutation<RemoveConstitutionMutation, RemoveConstitutionMutationVariables> {
    document = RemoveConstitutionDocument;
    
  }
export const SearchConstitutionListDocument = gql`
    query searchConstitutionList($searchText: String) {
  constitutions(where: {}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchConstitutionListGQL extends Apollo.Query<SearchConstitutionListQuery, SearchConstitutionListQueryVariables> {
    document = SearchConstitutionListDocument;
    
  }
export const UpdateConstitutionDocument = gql`
    mutation updateConstitution($constitution: ConstitutionUpdateInput!, $constitutionId: ID!) {
  updateConstitution(data: $constitution, where: {id: $constitutionId}) {
    id
    startingAge
    secondaryStokvelType
    stokvelType
    isPublic
    maximumNumberOfMembers
    proofOfRegistration
    effectiveDate
    venue
    meetingFrequency
    feeForNotAttending
    feeForNotWearingUniform
    joiningFee
    contribtutionAmount
    contributionFrequency
    endDateOnCommitee
    createdAt
    mainMemberPayoutAmount
    spousePayoutAmount
    dependentsPayoutAmount
    childrenPayoutAmount
    stokvel {
      id
      name
      treasurer {
        id
        detail {
          id
          name
        }
      }
      chairman {
        id
        detail {
          id
          name
        }
      }
      administrator {
        id
        detail {
          id
          name
        }
      }
      secretary {
        id
        detail {
          id
          name
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateConstitutionGQL extends Apollo.Mutation<UpdateConstitutionMutation, UpdateConstitutionMutationVariables> {
    document = UpdateConstitutionDocument;
    
  }
export const CreateContributionDocument = gql`
    mutation createContribution($contribution: ContributionCreateInput!) {
  createContribution(data: $contribution) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateContributionGQL extends Apollo.Mutation<CreateContributionMutation, CreateContributionMutationVariables> {
    document = CreateContributionDocument;
    
  }
export const GetContributionDetailDocument = gql`
    query getContributionDetail($contributionId: ID!) {
  contribution(where: {id: $contributionId}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContributionDetailGQL extends Apollo.Query<GetContributionDetailQuery, GetContributionDetailQueryVariables> {
    document = GetContributionDetailDocument;
    
  }
export const GetContributionItemDocument = gql`
    query getContributionItem($contributionId: ID!) {
  contribution(where: {id: $contributionId}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContributionItemGQL extends Apollo.Query<GetContributionItemQuery, GetContributionItemQueryVariables> {
    document = GetContributionItemDocument;
    
  }
export const GetContributionListDocument = gql`
    query getContributionList($where: ContributionWhereInput, $orderBy: ContributionOrderByInput, $first: Int, $last: Int, $after: String) {
  contributions: contributions(where: $where, orderBy: $orderBy, first: $first, last: $last, after: $after) {
    id
    amount
    member {
      invite {
        detail {
          id
          name
          lastName
          email
          cellphone
        }
      }
      user {
        detail {
          id
          name
          lastName
          email
          cellphone
        }
      }
      joinedDate
    }
    transactionDate
  }
  count: contributionsConnection(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContributionListGQL extends Apollo.Query<GetContributionListQuery, GetContributionListQueryVariables> {
    document = GetContributionListDocument;
    
  }
export const SearchContributionListDocument = gql`
    query searchContributionList($searchText: String) {
  contributions(where: {member: {invite: {detail: {cellphone_starts_with: $searchText}}}}) {
    id
    amount
    member {
      invite {
        detail {
          id
          name
          lastName
          email
          cellphone
        }
      }
      joinedDate
    }
    transactionDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchContributionListGQL extends Apollo.Query<SearchContributionListQuery, SearchContributionListQueryVariables> {
    document = SearchContributionListDocument;
    
  }
export const CreateEventDocument = gql`
    mutation createEvent($event: EventCreateInput!) {
  createEvent(data: $event) {
    id
    name
    description
    startDate
    stokvel {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEventGQL extends Apollo.Mutation<CreateEventMutation, CreateEventMutationVariables> {
    document = CreateEventDocument;
    
  }
export const RemoveEventDocument = gql`
    mutation removeEvent($id: ID!) {
  deleteEvent(where: {id: $id}) {
    id
    name
    description
    startDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveEventGQL extends Apollo.Mutation<RemoveEventMutation, RemoveEventMutationVariables> {
    document = RemoveEventDocument;
    
  }
export const GetEventDetailDocument = gql`
    query getEventDetail($eventId: ID!) {
  event(where: {id: $eventId}) {
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEventDetailGQL extends Apollo.Query<GetEventDetailQuery, GetEventDetailQueryVariables> {
    document = GetEventDetailDocument;
    
  }
export const SearchEventListByStokvelsDocument = gql`
    query searchEventListByStokvels($searchText: StokvelWhereInput!) {
  events(where: {stokvel: $searchText}) {
    id
    name
    description
    startDate
    stokvel {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchEventListByStokvelsGQL extends Apollo.Query<SearchEventListByStokvelsQuery, SearchEventListByStokvelsQueryVariables> {
    document = SearchEventListByStokvelsDocument;
    
  }
export const GetEventItemDocument = gql`
    query getEventItem($eventId: ID!) {
  event(where: {id: $eventId}) {
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEventItemGQL extends Apollo.Query<GetEventItemQuery, GetEventItemQueryVariables> {
    document = GetEventItemDocument;
    
  }
export const GetEventListDocument = gql`
    query getEventList($where: EventWhereInput, $orderBy: EventOrderByInput, $first: Int, $last: Int) {
  events: events(where: $where, orderBy: $orderBy, first: $first, last: $last) {
    id
  }
  count: eventsConnection(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEventListGQL extends Apollo.Query<GetEventListQuery, GetEventListQueryVariables> {
    document = GetEventListDocument;
    
  }
export const SearchEventListDocument = gql`
    query searchEventList($searchText: String) {
  events(where: {name_contains: $searchText}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchEventListGQL extends Apollo.Query<SearchEventListQuery, SearchEventListQueryVariables> {
    document = SearchEventListDocument;
    
  }
export const UpdateEventDocument = gql`
    mutation updateEvent($eventId: ID!, $event: EventUpdateInput!) {
  updateEvent(data: $event, where: {id: $eventId}) {
    id
    name
    description
    startDate
    stokvel {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEventGQL extends Apollo.Mutation<UpdateEventMutation, UpdateEventMutationVariables> {
    document = UpdateEventDocument;
    
  }
export const CreateInviteDocument = gql`
    mutation createInvite($invite: InviteCreateInput!) {
  createInvite(data: $invite) {
    id
    detail {
      id
      name
      lastName
      email
      cellphone
      index {
        id
        name
        email
      }
    }
    invitedDate
    isSent
    stokvel {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateInviteGQL extends Apollo.Mutation<CreateInviteMutation, CreateInviteMutationVariables> {
    document = CreateInviteDocument;
    
  }
export const GetInviteDetailDocument = gql`
    query getInviteDetail($inviteId: ID!) {
  invite(where: {id: $inviteId}) {
    detail {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInviteDetailGQL extends Apollo.Query<GetInviteDetailQuery, GetInviteDetailQueryVariables> {
    document = GetInviteDetailDocument;
    
  }
export const GetAllInvitesDocument = gql`
    query getAllInvites {
  invites {
    id
    detail {
      id
      name
      cellphone
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllInvitesGQL extends Apollo.Query<GetAllInvitesQuery, GetAllInvitesQueryVariables> {
    document = GetAllInvitesDocument;
    
  }
export const GetInviteItemDocument = gql`
    query getInviteItem($inviteId: ID!) {
  invite(where: {id: $inviteId}) {
    detail {
      id
      name
    }
    alreadyActive
    stokvel {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInviteItemGQL extends Apollo.Query<GetInviteItemQuery, GetInviteItemQueryVariables> {
    document = GetInviteItemDocument;
    
  }
export const GetInviteListDocument = gql`
    query getInviteList($where: InviteWhereInput, $orderBy: InviteOrderByInput, $first: Int, $last: Int) {
  invites: invites(where: $where, orderBy: $orderBy, first: $first, last: $last) {
    id
    detail {
      id
      name
      lastName
      email
      cellphone
    }
    invitedDate
    isSent
    alreadyActive
    stokvel {
      id
      name
      startDate
      administrator {
        id
      }
      treasurer {
        id
      }
      chairman {
        id
      }
      secretary {
        id
      }
      members {
        id
        name
      }
    }
  }
  count: invitesConnection(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInviteListGQL extends Apollo.Query<GetInviteListQuery, GetInviteListQueryVariables> {
    document = GetInviteListDocument;
    
  }
export const SearchInviteListDocument = gql`
    query searchInviteList($where: InviteWhereInput, $orderBy: InviteOrderByInput, $first: Int, $last: Int) {
  invites(where: $where, orderBy: $orderBy, first: $first, last: $last) {
    id
    detail {
      id
      name
      lastName
      email
      cellphone
      index {
        id
        name
        lastName
        email
      }
    }
    invitedDate
    isSent
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchInviteListGQL extends Apollo.Query<SearchInviteListQuery, SearchInviteListQueryVariables> {
    document = SearchInviteListDocument;
    
  }
export const UpdateInviteDocument = gql`
    mutation updateInvite($data: InviteUpdateInput!, $where: InviteWhereUniqueInput!) {
  updateInvite(data: $data, where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateInviteGQL extends Apollo.Mutation<UpdateInviteMutation, UpdateInviteMutationVariables> {
    document = UpdateInviteDocument;
    
  }
export const CreateMemberLinkAdministartorMemberDocument = gql`
    mutation createMemberLinkAdministartorMember($startDate: DateTime!, $administratorId: ID, $newStokvelId: ID) {
  createMember(data: {joinedDate: $startDate, user: {connect: {id: $administratorId}}, stokvel: {connect: {id: $newStokvelId}}}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMemberLinkAdministartorMemberGQL extends Apollo.Mutation<CreateMemberLinkAdministartorMemberMutation, CreateMemberLinkAdministartorMemberMutationVariables> {
    document = CreateMemberLinkAdministartorMemberDocument;
    
  }
export const CreateMemberDocument = gql`
    mutation createMember($member: MemberCreateInput!) {
  createMember(data: $member) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMemberGQL extends Apollo.Mutation<CreateMemberMutation, CreateMemberMutationVariables> {
    document = CreateMemberDocument;
    
  }
export const GetMemberDetailDocument = gql`
    query getMemberDetail($memberId: ID!) {
  member(where: {id: $memberId}) {
    id
    stokvel {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMemberDetailGQL extends Apollo.Query<GetMemberDetailQuery, GetMemberDetailQueryVariables> {
    document = GetMemberDetailDocument;
    
  }
export const GetAllMembersDocument = gql`
    query getAllMembers($where: MemberWhereInput, $orderBy: MemberOrderByInput, $first: Int, $last: Int) {
  members: members(where: $where, orderBy: $orderBy, first: $first, last: $last) {
    id
    invite {
      detail {
        name
        lastName
        email
        cellphone
      }
    }
    stokvel {
      id
    }
    joinedDate
  }
  count: membersConnection(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllMembersGQL extends Apollo.Query<GetAllMembersQuery, GetAllMembersQueryVariables> {
    document = GetAllMembersDocument;
    
  }
export const GetMemberItemDocument = gql`
    query getMemberItem($memberId: ID!) {
  member(where: {id: $memberId}) {
    id
    name
    user {
      id
      detail {
        id
        name
        lastName
        email
        cellphone
      }
    }
    stokvel {
      id
      planId
    }
    joinedDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMemberItemGQL extends Apollo.Query<GetMemberItemQuery, GetMemberItemQueryVariables> {
    document = GetMemberItemDocument;
    
  }
export const GetMemberListDocument = gql`
    query getMemberList($where: MemberWhereInput, $orderBy: MemberOrderByInput, $first: Int, $last: Int, $after: String) {
  members: members(where: $where, orderBy: $orderBy, first: $first, last: $last, after: $after) {
    id
    invite {
      id
      detail {
        id
        name
        lastName
        cellphone
        email
        address {
          line1
          line2
          line3
          city
          code
        }
      }
    }
    user {
      id
      detail {
        id
        name
        lastName
        cellphone
        email
        address {
          line1
          line2
          line3
          city
          code
        }
      }
    }
    stokvel {
      id
      planId
      name
    }
    joinedDate
  }
  count: membersConnection(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMemberListGQL extends Apollo.Query<GetMemberListQuery, GetMemberListQueryVariables> {
    document = GetMemberListDocument;
    
  }
export const SearchMemberListDocument = gql`
    query searchMemberList($where: MemberWhereInput, $orderBy: MemberOrderByInput, $first: Int) {
  members(where: $where, orderBy: $orderBy, first: $first) {
    id
    user {
      id
      detail {
        id
        name
        lastName
        email
        cellphone
      }
    }
    invite {
      detail {
        id
        name
        lastName
        email
        cellphone
      }
    }
    joinedDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchMemberListGQL extends Apollo.Query<SearchMemberListQuery, SearchMemberListQueryVariables> {
    document = SearchMemberListDocument;
    
  }
export const UpdateMemberDocument = gql`
    mutation updateMember($data: MemberUpdateInput!, $where: MemberWhereUniqueInput!) {
  updateMember(data: $data, where: $where) {
    id
    invite {
      detail {
        id
        name
        lastName
        email
        cellphone
      }
    }
    stokvel {
      id
    }
    joinedDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMemberGQL extends Apollo.Mutation<UpdateMemberMutation, UpdateMemberMutationVariables> {
    document = UpdateMemberDocument;
    
  }
export const CreateMessageDocument = gql`
    mutation createMessage($message: MessageCreateInput!) {
  createMessage(data: $message) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMessageGQL extends Apollo.Mutation<CreateMessageMutation, CreateMessageMutationVariables> {
    document = CreateMessageDocument;
    
  }
export const GetMessageDetailDocument = gql`
    query getMessageDetail($messageId: ID!) {
  message(where: {id: $messageId}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMessageDetailGQL extends Apollo.Query<GetMessageDetailQuery, GetMessageDetailQueryVariables> {
    document = GetMessageDetailDocument;
    
  }
export const GetMessageItemDocument = gql`
    query getMessageItem($messageId: ID!) {
  message(where: {id: $messageId}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMessageItemGQL extends Apollo.Query<GetMessageItemQuery, GetMessageItemQueryVariables> {
    document = GetMessageItemDocument;
    
  }
export const GetMessageListDocument = gql`
    query getMessageList($where: MessageWhereInput, $orderBy: MessageOrderByInput, $first: Int, $last: Int) {
  messages: messages(where: $where, orderBy: $orderBy, first: $first, last: $last) {
    id
  }
  count: messagesConnection(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMessageListGQL extends Apollo.Query<GetMessageListQuery, GetMessageListQueryVariables> {
    document = GetMessageListDocument;
    
  }
export const SearchMessageListDocument = gql`
    query searchMessageList($searchText: String) {
  messages(where: {}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchMessageListGQL extends Apollo.Query<SearchMessageListQuery, SearchMessageListQueryVariables> {
    document = SearchMessageListDocument;
    
  }
export const CreatePaymentDocument = gql`
    mutation createPayment($payment: PaymentCreateInput!) {
  createPayment(data: $payment) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePaymentGQL extends Apollo.Mutation<CreatePaymentMutation, CreatePaymentMutationVariables> {
    document = CreatePaymentDocument;
    
  }
export const GetPaymentListDocument = gql`
    query getPaymentList($where: PaymentWhereInput, $orderBy: PaymentOrderByInput, $first: Int, $last: Int, $after: String) {
  payments: payments(where: $where, orderBy: $orderBy, first: $first, last: $last, after: $after) {
    id
    amount
    proofOfPayment
    member {
      invite {
        detail {
          id
          name
          lastName
          email
          cellphone
        }
      }
      user {
        detail {
          id
          name
          lastName
          email
          cellphone
        }
      }
      joinedDate
    }
    transactionDate
  }
  count: paymentsConnection(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaymentListGQL extends Apollo.Query<GetPaymentListQuery, GetPaymentListQueryVariables> {
    document = GetPaymentListDocument;
    
  }
export const CreatePersonalDetailDocument = gql`
    mutation createPersonalDetail($payment: PersonalDetailCreateInput!) {
  createPersonalDetail(data: $payment) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePersonalDetailGQL extends Apollo.Mutation<CreatePersonalDetailMutation, CreatePersonalDetailMutationVariables> {
    document = CreatePersonalDetailDocument;
    
  }
export const GetPersonalDetailsDocument = gql`
    query getPersonalDetails($where: PersonalDetailWhereInput, $orderBy: PersonalDetailOrderByInput, $first: Int, $last: Int, $after: String) {
  personalDetails: personalDetails(where: $where, orderBy: $orderBy, first: $first, last: $last, after: $after) {
    id
    name
    lastName
    email
    cellphone
    index {
      id
      name
      lastName
      email
    }
    user {
      id
      memberOf {
        id
      }
    }
    invite {
      id
      stokvel {
        id
      }
    }
  }
  count: personalDetailsConnection(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPersonalDetailsGQL extends Apollo.Query<GetPersonalDetailsQuery, GetPersonalDetailsQueryVariables> {
    document = GetPersonalDetailsDocument;
    
  }
export const UpdatePersonalDetailDocument = gql`
    mutation updatePersonalDetail($data: PersonalDetailUpdateInput!, $where: PersonalDetailWhereUniqueInput!) {
  updatePersonalDetail(data: $data, where: $where) {
    id
    invite {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePersonalDetailGQL extends Apollo.Mutation<UpdatePersonalDetailMutation, UpdatePersonalDetailMutationVariables> {
    document = UpdatePersonalDetailDocument;
    
  }
export const CreateStokvelDocument = gql`
    mutation createStokvel($name: String!, $planId: String!, $startDate: DateTime!, $administratorId: ID) {
  createStokvel(data: {name: $name, planId: $planId, startDate: $startDate, administrator: {connect: {id: $administratorId}}, treasurer: {connect: {id: $administratorId}}, secretary: {connect: {id: $administratorId}}, chairman: {connect: {id: $administratorId}}}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStokvelGQL extends Apollo.Mutation<CreateStokvelMutation, CreateStokvelMutationVariables> {
    document = CreateStokvelDocument;
    
  }
export const GetStokvelDetailDocument = gql`
    query getStokvelDetail($stokvelId: ID!) {
  stokvel(where: {id: $stokvelId}) {
    name
    id
    startDate
    administrator {
      id
    }
    treasurer {
      id
    }
    chairman {
      id
    }
    secretary {
      id
    }
    constitution {
      id
      maximumNumberOfMembers
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetStokvelDetailGQL extends Apollo.Query<GetStokvelDetailQuery, GetStokvelDetailQueryVariables> {
    document = GetStokvelDetailDocument;
    
  }
export const GetStokvelItemDocument = gql`
    query getStokvelItem($stokvelId: ID!) {
  stokvel(where: {id: $stokvelId}) {
    name
    startDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetStokvelItemGQL extends Apollo.Query<GetStokvelItemQuery, GetStokvelItemQueryVariables> {
    document = GetStokvelItemDocument;
    
  }
export const GetStokvelListDocument = gql`
    query getStokvelList($where: StokvelWhereInput, $orderBy: StokvelOrderByInput, $first: Int, $last: Int) {
  stokvels: stokvels(where: $where, orderBy: $orderBy, first: $first, last: $last) {
    id
    planId
    name
  }
  members {
    id
    name
  }
  count: stokvelsConnection(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetStokvelListGQL extends Apollo.Query<GetStokvelListQuery, GetStokvelListQueryVariables> {
    document = GetStokvelListDocument;
    
  }
export const GetStokvelRolesDocument = gql`
    query GetStokvelRoles($stokvelId: ID!) {
  stokvel(where: {id: $stokvelId}) {
    id
    administrator {
      id
      detail {
        id
        name
      }
      picture
    }
    treasurer {
      id
      detail {
        id
        name
      }
      picture
    }
    chairman {
      id
      detail {
        id
        name
      }
      picture
    }
    secretary {
      id
      detail {
        id
        name
      }
      picture
    }
    name
    planId
    startDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetStokvelRolesGQL extends Apollo.Query<GetStokvelRolesQuery, GetStokvelRolesQueryVariables> {
    document = GetStokvelRolesDocument;
    
  }
export const SearchStokvelListDocument = gql`
    query searchStokvelList($searchText: String) {
  stokvels(where: {name_contains: $searchText}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchStokvelListGQL extends Apollo.Query<SearchStokvelListQuery, SearchStokvelListQueryVariables> {
    document = SearchStokvelListDocument;
    
  }
export const UpdateStokvelDocument = gql`
    mutation updateStokvel($data: StokvelUpdateInput!, $where: StokvelWhereUniqueInput!) {
  updateStokvel(data: $data, where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateStokvelGQL extends Apollo.Mutation<UpdateStokvelMutation, UpdateStokvelMutationVariables> {
    document = UpdateStokvelDocument;
    
  }
export const CreateUserDocument = gql`
    mutation createUser($user: UserCreateInput!) {
  createUser(data: $user) {
    id
    detail {
      id
      name
      lastName
      email
      cellphone
    }
    picture
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
  }
export const GetUserDetailDocument = gql`
    query getUserDetail($userId: ID!) {
  user(where: {id: $userId}) {
    detail {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserDetailGQL extends Apollo.Query<GetUserDetailQuery, GetUserDetailQueryVariables> {
    document = GetUserDetailDocument;
    
  }
export const GetAllUserDocument = gql`
    query getAllUser {
  users {
    id
    detail {
      id
      name
      lastName
      email
      cellphone
    }
    password
    picture
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllUserGQL extends Apollo.Query<GetAllUserQuery, GetAllUserQueryVariables> {
    document = GetAllUserDocument;
    
  }
export const GetAllUserDetailPersonalDetailsDocument = gql`
    query getAllUserDetailPersonalDetails {
  personalDetails {
    id
    email
    cellphone
    user {
      id
      detail {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllUserDetailPersonalDetailsGQL extends Apollo.Query<GetAllUserDetailPersonalDetailsQuery, GetAllUserDetailPersonalDetailsQueryVariables> {
    document = GetAllUserDetailPersonalDetailsDocument;
    
  }
export const GetUserItemDocument = gql`
    query getUserItem($userId: ID!) {
  user(where: {id: $userId}) {
    detail {
      id
      name
    }
    picture
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserItemGQL extends Apollo.Query<GetUserItemQuery, GetUserItemQueryVariables> {
    document = GetUserItemDocument;
    
  }
export const GetUserListDocument = gql`
    query getUserList($where: UserWhereInput, $orderBy: UserOrderByInput, $first: Int, $last: Int) {
  users: users(where: $where, orderBy: $orderBy, first: $first, last: $last) {
    id
    detail {
      id
      name
      lastName
      email
      cellphone
    }
    picture
  }
  count: usersConnection(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserListGQL extends Apollo.Query<GetUserListQuery, GetUserListQueryVariables> {
    document = GetUserListDocument;
    
  }
export const SearchUserListDocument = gql`
    query searchUserList($where: UserWhereInput, $orderBy: UserOrderByInput, $first: Int, $last: Int) {
  users(where: $where, orderBy: $orderBy, first: $first, last: $last) {
    id
    detail {
      id
      name
      lastName
      email
      cellphone
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchUserListGQL extends Apollo.Query<SearchUserListQuery, SearchUserListQueryVariables> {
    document = SearchUserListDocument;
    
  }
export const UpdateUserDocument = gql`
    mutation updateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
  updateUser(data: $data, where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
  }